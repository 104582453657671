import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApplicationFeature } from '@app/core/models/application-feature-enums';
import { UserFeaturesService } from '@app/core/services/user-features.service';
import { ASFPermissions, UserInfoService } from './user-info.service';
import { EhsRoles, LegacyRoleMapperService } from './legacy-role-mapper.service';

@Injectable({ providedIn: 'root' })
export class ASFAuthGuardService implements CanActivate {
	constructor(
		private userFeaturesService: UserFeaturesService,
		private userinfoService: UserInfoService,
		private router: Router,
		private legacyRoleMapperService: LegacyRoleMapperService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.userFeaturesService.getMyFeatures().subscribe((result) => {
				let hasFeature = false;
				let isAllowed = false;

				if (result && result.result) {
					const features = result.result.applicationFeatures;
					hasFeature = features.findIndex((item) => item === ApplicationFeature[ApplicationFeature.ASF]) >= 0;
				}

				const userinfo = this.userinfoService.getUserInfoFromStorage();
				const isClientAdmin =
					userinfo.ehsRole && EhsRoles.ClientAdmin === this.legacyRoleMapperService.MapToEhsRole(userinfo.ehsRole.roleName);
				const isClientPowerUser =
					userinfo.ehsRole && EhsRoles.ClientPowerUser === this.legacyRoleMapperService.MapToEhsRole(userinfo.ehsRole.roleName);

				this.userinfoService.hasPermission(ApplicationFeature.ASF, ASFPermissions.AccessASF).subscribe(
					(hasPermission) => {
						isAllowed = hasFeature && hasPermission && (isClientAdmin || isClientPowerUser);

						if (!isAllowed) {
							this.router.navigate(['/landing']);
						}

						resolve(isAllowed);
					},
					(error) => {}
				);
			});
		});
	}
}
