import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { CredentailsDto } from '@app/core/models/credentials-dto';
import { UserInfoDto } from '@app/core/models/user-info-dto';
import { LogService } from '@app/core/services/log.service';
import { BaseComponent } from '@app/shared/base-component';
import { BehaviorSubject } from 'rxjs';
import { SecurityService } from '../../core/services/security.service';
import { SupportService } from '../shared/services/support.service';

@Component({
	selector: 'app-support',
	templateUrl: './support.component.html',
	styleUrls: ['./support.component.css'],
})
export class SupportComponent extends BaseComponent implements OnInit {
	supportForm: UntypedFormGroup;
	userInfoDto: UserInfoDto;
	credentialsDto: CredentailsDto;
	public descriptionLength = new BehaviorSubject(0);
	saveTicketFailed = false;
	saveTicketSucceeded = false;
	saveInitiated: boolean = false;

	constructor(
		private titleService: Title,
		private securityService: SecurityService,
		private supportService: SupportService,
		protected readonly log: LogService
	) {
		super(log);
	}

	ngOnInit() {
		this.titleService.setTitle('Support');

		this.supportForm = new UntypedFormGroup({
			email: new UntypedFormControl(''),
			phone: new UntypedFormControl(''),
			subject: new UntypedFormControl('', Validators.required),
			description: new UntypedFormControl('', Validators.required),
			name: new UntypedFormControl(''),
			company: new UntypedFormControl(''),
			product__c: new UntypedFormControl(''),
		});
		this.updateValidatorAndValue(this.supportForm, emailPhoneValidator);
		this.supportForm.get('description').valueChanges.subscribe((v) => {
			this.descriptionLength.next(v.length);
		});
		this.getMyInfo();
	}

	getMyInfo() {
		this.securityService.getMyUserInfo().subscribe((dto: UserInfoDto) => {
			this.supportForm.setValue({
				email: this.isNullorUndefined(dto.primaryEmail),
				phone: this.isNullorUndefined(dto.primaryPhone),
				subject: '',
				description: '',
				name: this.isNullorUndefined(dto.employeeDisplayName),
				company: this.isNullorUndefined(dto.locationName),
				product__c: 'Vera Suite',
			});
		});
	}

	isNullorUndefined(value) {
		if (!!value) return value;
		else return '';
	}
	/**
	 * Update form group validators and refresh
	 * @param formGroup
	 * @param formValidator
	 */
	private updateValidatorAndValue(formGroup: UntypedFormGroup, formValidator: ValidatorFn) {
		formGroup.setValidators(formValidator);
		formGroup.updateValueAndValidity();
		formGroup.markAsDirty();
	}

	onSubmit() {
		if (this.supportForm.valid) {
			this.saveInitiated = true;
			this.supportService.postSupportTicket(this.supportForm.value).subscribe(
				(result) => {
					this.validationBinding(result);
					this.saveInitiated = false;
				},
				(error) => {
					this.validationBinding(false);
					this.saveInitiated = false;
				}
			);
		} else {
			Object.keys(this.supportForm.controls).forEach((field) => {
				const control = this.supportForm.get(field);
				control.markAsTouched({ onlySelf: true });
				control.markAsDirty();
			});
		}
	}

	validationBinding(isSuccess: boolean) {
		this.saveTicketSucceeded = isSuccess;
		this.saveTicketFailed = !isSuccess;
	}
}

export const emailPhoneValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
	const email = formGroup.get('email') ? formGroup.get('email').value : null;
	const phone = formGroup.get('phone') ? formGroup.get('phone').value : null;

	if (!email && !phone) {
		return {
			uiErrors: true,
			message: 'Either email or phone required',
		};
	}

	return null;
};
