import { Directive, KeyValueDiffers } from '@angular/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { KpaSelectionDirective } from './kpa-selection.directive';

@Directive({
	selector: '[kpaDropDownList]',
	exportAs: 'kpaDropDownList',
})
export class KpaDropDownListDirective extends KpaSelectionDirective {
	constructor(dropdown: DropDownListComponent, diff: KeyValueDiffers) {
		super(dropdown, diff);
	}
}
