import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectEvent, FileInfo, FileRestrictions } from '@progress/kendo-angular-upload';
import { FileBlobInfoDto } from '@app/incidents/shared/models/file-blob-info-dto';

class MediaDisplayItem {
	fileBlobInfoDto: FileBlobInfoDto;
	file: FileInfo;

	constructor(fileBlobInfoDto: FileBlobInfoDto, file: FileInfo) {
		this.file = file;
		this.fileBlobInfoDto = fileBlobInfoDto;
		if (file && file.rawFile && this.file.name !== file.rawFile.name) {
			// This can happen if file name contains special characters, in
			// which case file.name will be encoded, potentially with characters
			// that are illegal for URL paths, making retrieval, deletion impossible.
			// Need to keep original file name.
			this.file.name = file.rawFile.name;
		}
	}
}

@Component({
	selector: 'kpa-file-upload',
	templateUrl: './kpa-file-upload.component.html',
	styleUrls: ['./kpa-file-upload.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class KpaFileUploadComponent implements OnInit {
	@Input() mediaUtils: any;
	@Input() isMultiple: any;
	@Input() isError: any;

	@Output()
	onUploadEventEmit: EventEmitter<any> = new EventEmitter();

	@Output()
	onRemoveEventEmit: EventEmitter<any> = new EventEmitter();

	@Output()
	onDownloadEventEmit: EventEmitter<any> = new EventEmitter();

	@Output()
	onSelectEventEmit: EventEmitter<any> = new EventEmitter();

	unsupportedFiles: string[] = [];
	uploadRestrictions: FileRestrictions;
	public listItems: MediaDisplayItem[] = [];

	constructor() {}

	ngOnInit(): void {
		if (this.mediaUtils && this.mediaUtils.AllowedMimeTypes) {
			this.uploadRestrictions = {
				allowedExtensions: [...this.mediaUtils.AllowedMimeTypes],
			};
		}
	}

	public onSelectEvent(event: SelectEvent) {
		if (event.files && event.files.length) {
			this.unsupportedFiles = event.files
				.filter((fileInfo: FileInfo) => !this.mediaUtils.AllowedMimeTypes.includes(fileInfo.extension.toLowerCase()))
				.map((fileInfo: FileInfo) => fileInfo.name);

			if (this.unsupportedFiles.length >= 0) {
				setTimeout(() => {
					this.unsupportedFiles = [];
				}, 2500);
			}

			event.files
				.filter((fileInfo: FileInfo) => this.mediaUtils.AllowedMimeTypes.includes(fileInfo.extension.toLowerCase()))
				.forEach((fileInfo: FileInfo) => {
					this.listItems = [...this.listItems, new MediaDisplayItem(null, fileInfo)];
					this.onSelectEventEmit.emit(this.listItems);
				});
		}
	}

	public removeFile(i: number) {
		this.listItems.splice(i, 1);
	}

	removeMedia(index: number) {
		this.listItems[index].fileBlobInfoDto.markForRemoval = true;
	}

	keepMedia(index: number) {
		this.listItems[index].fileBlobInfoDto.markForRemoval = false;
	}

	public onUploadEvent(e) {
		this.onUploadEventEmit.emit();
	}

	public downloadMedia(name) {
		this.onDownloadEventEmit.emit(name);
	}

	public onRemoveEvent(e, upload) {
		this.onRemoveEventEmit.emit();
	}
}
