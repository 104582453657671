import { Directive, KeyValueDiffers, Optional } from '@angular/core';
import { ButtonDirective } from '@progress/kendo-angular-buttons';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { KpaMonitorChanges } from '../../shared/kpa-monitor-changes';

@Directive({
	selector: '[kpaShowHidePassword]',
	exportAs: 'kpaShowHidePassword',
})
export class KpaShowHidePasswordDirective extends KpaMonitorChanges<ButtonDirective> {
	private _clearText: boolean;
	private get clearText(): boolean {
		return this._clearText;
	}
	private set clearText(value: boolean) {
		this._clearText = value;
		this.forceSetBehaviors();
	}

	constructor(
		@Optional() private readonly button: ButtonDirective,
		@Optional() private readonly textbox: TextBoxComponent,
		diff: KeyValueDiffers
	) {
		super(diff, button, 'look', 'iconClass');
		if (!button || !(button instanceof ButtonDirective))
			throw 'The KpaShowHidePasswordDirective has a sibling dependency of the Kendo ButtonDirective. Please apply this attribute directive to your button element.';
		if (!button || !(button instanceof ButtonDirective))
			throw 'The KpaShowHidePasswordDirective has an ancestor dependency of the Kendo TextBoxComponent. Please use this directive in a prefix or suffix template for your textbox component.';

		this.button.element.onclick = () => (this.clearText = !this.clearText);
		(<HTMLInputElement>this.textbox.input.nativeElement).autocomplete = 'off';
	}

	setBehaviors(): void {
		this.button.look = 'clear';
		this.button.iconClass = this.clearText ? 'fal fa-eye-slash' : 'fal fa-eye';
		this.textbox.input.nativeElement.setAttribute('type', this.clearText ? 'text' : 'password');
	}
}
