<!-- <div>Selected range: {{ range | json }}</div> -->
<div class="div-kpa-daterange-filter">
	<kendo-daterange>
		<kendo-dateinput
			class="range-filter-start"
			kendoDateRangeStartInput
			[value]="start"
			(valueChange)="filterRange($event, end)"
			style="display: inline-block; width: 120px"></kendo-dateinput>
		&#8211;
		<kendo-dateinput
			class="range-filter-end"
			kendoDateRangeEndInput
			[value]="end"
			(valueChange)="filterRange(start, $event)"
			style="display: inline-block; width: 120px"></kendo-dateinput>
	</kendo-daterange>
</div>
