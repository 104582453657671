<kendo-dialog
	id="dynamic-dialog-prompt"
	(close)="onClose()"
	[minWidth]="250"
	[height]="dialogHeight"
	[width]="dialogWidth"
	[title]="titlePrompt">
	<div>
		<p id="dynamic-dialog-prompt-txt">{{ dialogPrompt }}</p>
	</div>

	<kendo-dialog-actions [layout]="actionsLayout">
		<button
			id="dynamic-dialog-prompt-btn-close"
			kendoButton
			kpaSecondaryButton
			(click)="onClose()">
			{{ closePrompt }}
		</button>

		<button
			id="dynamic-dialog-prompt-btn-confirm"
			kendoButton
			kpaPrimaryButton
			(click)="onConfirm()"
			themeColor="primary">
			{{ confirmPrompt }}
		</button>
	</kendo-dialog-actions>
</kendo-dialog>
