import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'kpa-success-card',
	templateUrl: './kpa-success-card.component.html',
	styleUrls: ['./kpa-success-card.component.css'],
})
export class KpaSuccessCardComponent implements OnInit {
	@Input()
	public mainTitle: string;

	constructor() {}

	ngOnInit() {}
}
