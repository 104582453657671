import { Directive, KeyValueDiffers } from '@angular/core';
import { DropDownListComponent, MultiSelectComponent, ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { KpaMonitorChanges } from '../../shared/kpa-monitor-changes';

export interface IKpaSelectionItemBinding<TValue> {
	text: string;
	value: TValue;
}

export type SelectionComponent = DropDownListComponent | MultiSelectComponent | ComboBoxComponent;

@Directive()
export abstract class KpaSelectionDirective extends KpaMonitorChanges<SelectionComponent> {
	protected constructor(
		private readonly dropdown: SelectionComponent,
		diff: KeyValueDiffers
	) {
		super(diff, dropdown, 'textField', 'valueField');

		if (
			!dropdown ||
			(!(dropdown instanceof DropDownListComponent) &&
				!(dropdown instanceof MultiSelectComponent) &&
				!(dropdown instanceof ComboBoxComponent))
		)
			throw 'The KpaDropDownDirective has a host dependency of the Kendo DropDownListComponent, MultiSelectComponent, or ComboBoxComponent. Please apply this attribute directive to an appropriate component type.';
	}

	setBehaviors(): void {
		this.dropdown.textField = 'text';
		this.dropdown.valueField = 'value';
	}
}
