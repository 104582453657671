<div
	*ngIf="!hasOnlyOneLocation"
	class="location-picker">
	<kendo-dropdownlist
		class="kendo-dropdown k-dropdown location-picker"
		[filterable]="true"
		[data]="filteredServiceTos"
		[(value)]="selectedServiceTo"
		textField="serviceToName"
		valueField="serviceToUniversalId"
		[defaultItem]="defaultItem"
		(selectionChange)="locationSelectionChange($event)"
		(filterChange)="filterServiceTos($event)"
		[disabled]="disabled">
	</kendo-dropdownlist>
</div>
