<!-- ### Groupded Button Code -->
<kendo-buttongroup>
	<button
		*ngFor="let button of buttonGroup"
		kendoButton
		[icon]="checkIconToDisplay(button.selectionValue)"
		[toggleable]="button.toggleableValue"
		[(selected)]="button.selectionValue">
		{{ button.buttonLabel }}
	</button>
</kendo-buttongroup>
<!-- ### Groupded Button Code -->
