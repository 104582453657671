import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from './core/core.module';
import { Location, PathLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';

import { RouterTestingModule } from '@angular/router/testing';
import { AppComponent } from './app.component';
import { OpenApiFormBuilder } from './shared/open-api-form-builder.service';

// Angular Flex Layout CDK @6.0.0-beta.18 (Angular 6 Version)
import { FlexLayoutModule } from '@angular/flex-layout';

// Kendo Imports
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { UploadModule } from '@progress/kendo-angular-upload';
import { DialogsModule } from '@progress/kendo-angular-dialog';

// Misc Imports
import { HttpClientModule } from '@angular/common/http';
import { PlatformLayoutComponent } from './shared/components/platform-layout/platform-layout.component';
import { WindowToken, windowProvider } from './shared/window.service';
import { HomeModule } from './home/home.module';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { ICON_SETTINGS } from '@progress/kendo-angular-icons';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';

@NgModule({
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		CoreModule,
		RouterTestingModule,
		InputsModule,
		GridModule,
		ButtonsModule,
		DropDownsModule,
		LayoutModule,
		DialogsModule,
		FlexLayoutModule,
		ExcelModule,
		HomeModule,
		UploadModule,
		HttpClientModule,
		SortableModule,
		ChartsModule,
		HighlightModule,
		AppRoutingModule, // leave at bottom
		// https://angular.io/guide/router#module-import-order-matters
	],
	declarations: [AppComponent, PlatformLayoutComponent],
	providers: [
		OpenApiFormBuilder,
		Location,
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		{ provide: WindowToken, useFactory: windowProvider },
		{ provide: ICON_SETTINGS, useValue: { type: 'font' } },
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: {
				coreLibraryLoader: () => import('highlight.js/lib/core'),
				lineNumbersLoader: () => import('highlightjs-line-numbers.js'), // Line numbers plugin
				languages: {
					typescript: () => import('highlight.js/lib/languages/typescript'),
					css: () => import('highlight.js/lib/languages/css'),
					html: () => import('highlight.js/lib/languages/xml'),
				},
			},
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
