import { Component, Input, OnInit } from '@angular/core';

/**
 * Available Shimmer shapes.
 */
export enum ShimmerShape {
	Rectangle = 'Rectangle',
	Ellipse = 'Ellipse',
	Line = 'Line',
}

/**
 * Reuseable shimmer shape component. Made for responsive layouts.
 * -- Due to using a background image gradient, you'll have to take extra care to ensure that the size is set properly as background images don't take up space by default.
 * -- Ellipse shape requires a fixed height + fixed width to make a circle. Alternatively, need to implement a sizing solution with a locked 1:1 aspect ratio.
 */
@Component({
	selector: 'kpa-shimmer-loader',
	templateUrl: './shimmer-loader.component.html',
	styleUrls: ['./shimmer-loader.component.css'],
})
export class ShimmerLoaderComponent implements OnInit {
	@Input() shape: ShimmerShape = ShimmerShape.Rectangle;
	shimmerClass = 'shimmer shimmer-rect shimmer-animation';

	constructor() {}

	ngOnInit(): void {
		this.mapShimmerClass(this.shape);
	}

	private mapShimmerClass(shape: ShimmerShape): void {
		if (shape === ShimmerShape.Rectangle) {
			this.shimmerClass = 'shimmer shimmer-rect shimmer-animation';
		} else if (shape === ShimmerShape.Ellipse) {
			this.shimmerClass = 'shimmer shimmer-ellipse shimmer-animation';
		} else if (shape === ShimmerShape.Line) {
			this.shimmerClass = 'shimmer shimmer-rect shimmer-line shimmer-animation';
		}
	}
}
