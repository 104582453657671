<div
	class="layout-wrapper"
	id="layout-wrapper">
	<div
		class="layout-main"
		id="layout-main">
		<kpa-common-nav-element
			id="kpa-common-nav-element-js"
			hosting-application="Platform"
			[attr.token]="accessToken$ | async"
			caching-enabled="true"
			[attr.session-id]="sid$ | async"
			assets-path="/assets/"
			[attr.originator-token]="originatorAccessToken$ | async"
			[attr.nav-base-api]="navBaseApi"
			[attr.log-verbosity]="logVerbosity"
			[attr.log-to-console]="logToConsole"
			[attr.originator]="originator$ | async"
			[attr.user]="user$ | async"
			[attr.app-insights-key]="appInsightsKey"
			do-navigation="false">
		</kpa-common-nav-element>

		<div
			class="kpa-nav-content-wrapper"
			id="kpa-content-wrapper">
			<div
				id="kpa-content-inner"
				kendoRippleContainer>
				<app-messages></app-messages>
				<router-outlet></router-outlet>
			</div>
			<div class="kpa-nav-content-footer">
				<kpa-common-footer-element
					id="kpa-common-footer-element-js"
					[attr.token]="accessToken$ | async"
					[attr.originator-token]="originatorAccessToken$ | async"
					caching-enabled="true"
					[attr.session-id]="sid$ | async"
					[attr.nav-base-api]="navBaseApi"
					[attr.log-verbosity]="logVerbosity"
					[attr.log-to-console]="logToConsole"
					[attr.app-insights-key]="appInsightsKey">
				</kpa-common-footer-element>
			</div>
		</div>
	</div>
	<div id="preAuth">
		<iframe
			*ngIf="preAuthenticateHrDrive && hrDrivePreAuthLanding"
			[src]="hrDrivePreAuthLanding"></iframe>
		<iframe
			*ngIf="preAuthenticateMyKpaOnline && myKpaOnlinePreAuthLanding"
			[src]="myKpaOnlinePreAuthLanding"></iframe>
	</div>
</div>
