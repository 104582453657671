export enum ApplicationFeature {
	MkoApplication = 1,
	HrDrive = 2,
	Employee = 3,
	Client = 4,
	ServiceTo = 5,
	Credentials = 6,
	Security = 7,
	ServiceToOrganization = 8,
	Reporting2 = 9,
	AuditsAndInspections = 10,
	Incidents = 11,
	Notifications = 12,
	FinanceAndInsurance = 13,
	Ehs = 14,
	EmployeeImports = 15,
	MFA = 16,
	Tasks = 18,
	TaskNotifications = 19,
	VeraTrack = 20,
	GLBA = 21,
	ASF = 22,
	CARS = 24,
	SecureMessaging = 25,
	FeedbackManagement = 26,
	EHSKPI = 27,
	MonthlyPhishing = 28,
}

export class ApplicationFeatureUtil {
	public static toString(appFeature: ApplicationFeature): string {
		return ApplicationFeature[appFeature];
	}

	public static mapToStrings(appFeatures: ApplicationFeature[]): string[] {
		return appFeatures.map(ApplicationFeatureUtil.toString);
	}
}
