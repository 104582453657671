import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceToLiteDto } from '@app/clients/shared/models/ServiceToLiteDto';
import { ServiceToDto } from '@app/clients/shared/models/serviceToDto';
import { ServiceToService } from '@app/clients/shared/services/service-to.service';
import { ApplicationFeature } from '@app/core/models/application-feature-enums';
import { SecurityService } from '@app/core/services/security.service';
import { DashboardService } from '@app/dashboard/shared/services/dashboard.service';
import { Observable, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
	selector: 'kpa-location-dropdown-filter',
	templateUrl: './kpa-location-dropdown-filter.component.html',
	styleUrls: ['./kpa-location-dropdown-filter.component.css'],
})
export class KpaLocationDropdownFilterComponent implements OnInit {
	@Input() serviceToUniversalId: string;
	@Output() selectedServiceToEvent = new EventEmitter<ServiceToLiteDto[]>();
	@Input() featureIdFilter: ApplicationFeature;
	@Input() disabled: boolean = false;
	@Input() addAllocationsItem: boolean = true;
	serviceTos: ServiceToLiteDto[] = [];
	filteredServiceTos: ServiceToLiteDto[] = [];
	defaultItem: ServiceToLiteDto;
	allLocationsItem: ServiceToLiteDto;
	selectedServiceTo: ServiceToLiteDto;
	hasOnlyOneLocation: boolean;
	isInternalPage: boolean;
	primaryServiceToId: string;

	constructor(
		private securityService: SecurityService,
		private serviceToService: ServiceToService,
		private router: Router,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.isInternalPage = (this.router.url || '').includes('/clients/');
		this.allLocationsItem = this.getAllLocationsItem();

		let serviceToObservable: Observable<ServiceToDto[] | ServiceToDto>;
		const myUserInfoObservable = this.securityService.getMyUserInfo().pipe(take(1));

		if (this.isInternalPage == true) {
			//serviceTo observable query if internal page
			serviceToObservable = this.serviceToService.getByUniversalId(this.serviceToUniversalId);
		} else {
			//serviceTo observable query if not internal page
			serviceToObservable = this.serviceToService.getServiceTo().pipe(take(1));
		}

		forkJoin({
			serviceTos: serviceToObservable,
			myUserInfo: myUserInfoObservable,
		}).subscribe((obvs) => {
			if (!Array.isArray(obvs.serviceTos)) {
				obvs.serviceTos = [obvs.serviceTos];
			}
			this.mapToServiceToLiteDto(obvs.serviceTos);
			this.hasOnlyOneLocation = this.serviceTos.length == 1;
			this.primaryServiceToId = obvs.myUserInfo?.primaryLocationServiceToId;
			this.setDefaultSelectedServiceTo();
		});
	}

	locationSelectionChange(selectedLocation: ServiceToLiteDto) {
		this.selectedServiceTo = selectedLocation;
		if (selectedLocation.serviceToUniversalId == '00000000-0000-0000-0000-000000000000') {
			this.selectedServiceToEvent.emit(this.filteredServiceTos);
		} else {
			this.selectedServiceToEvent.emit([selectedLocation]);
		}
	}

	filterServiceTos(filterValue: string = '') {
		let filteredResults: ServiceToLiteDto[] = this.serviceTos;
		if (filteredResults?.length > 0 && filterValue?.length > 0) {
			this.filteredServiceTos = filteredResults.filter((st) => st.serviceToName.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1);
		} else {
			this.filteredServiceTos = filteredResults;
		}
	}

	mapToServiceToLiteDto(serviceTos: ServiceToDto[]) {
		if (this.featureIdFilter) {
			serviceTos = serviceTos.filter((x) => x.locationAppFeatures.map((feature) => feature.featureId).includes(this.featureIdFilter));
		} else {
			serviceTos = serviceTos;
		}

		this.serviceTos = serviceTos.map((l) => {
			return {
				clientId: l.clientId,
				clientUniversalId: l.clientUniversalId,
				clientName: l.clientName,
				serviceToId: l.id,
				serviceToUniversalId: l.universalId,
				serviceToName: l.name,
			};
		});
		this.filteredServiceTos = this.serviceTos;
	}

	/**
	 * Sets the default selected serviceTo.
	 */
	setDefaultSelectedServiceTo() {
		const routeServiceToUniversalId = (this.route.snapshot.params || {})['serviceToUniversalId'];
		const routeServiceTo = this.serviceTos.find((serviceTo) => serviceTo.serviceToUniversalId == routeServiceToUniversalId);
		const primaryServiceTo = this.serviceTos.find((serviceTo) => serviceTo.serviceToId == this.primaryServiceToId);

		if (this.addAllocationsItem) {
			this.defaultItem = this.getAllLocationsItem();
		}

		if (this.hasOnlyOneLocation) {
			this.selectedServiceTo = this.serviceTos[0];
		} else if (routeServiceTo) {
			this.selectedServiceTo = routeServiceTo;
		} else if (primaryServiceTo) {
			this.selectedServiceTo = primaryServiceTo;
		} else if (this.addAllocationsItem) {
			this.selectedServiceTo = this.defaultItem;
		}

		this.locationSelectionChange(this.selectedServiceTo);
	}

	/**
	 * Returns a generic ServiceToAccountSearchDto that is used as all locations selected
	 * @returns ServiceToAccountSearchDto
	 */
	getAllLocationsItem(): ServiceToLiteDto {
		return {
			serviceToName: 'All Locations',
			clientName: '', //not used
			clientId: '00000000-0000-0000-0000-000000000000',
			clientUniversalId: '00000000-0000-0000-0000-000000000000',
			serviceToUniversalId: '00000000-0000-0000-0000-000000000000',
			serviceToId: '00000000-0000-0000-0000-000000000000',
		};
	}
}
