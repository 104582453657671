export enum EmailType {
	//  Email at a Company
	Company = 1,

	// Personal
	Personal = 2,

	// Alternate email individual may have for work
	Alternate = 3,
}
