<ng-container
	id="mediaImportComponent_showList_div"
	*ngIf="listItems.length > 0">
	<div
		style="list-style-type: none; width: 100%"
		fxLayout="column"
		fxFlex="0 0 100%"
		class="card-solid-import">
		<div
			*ngFor="let item of listItems; let i = index"
			fxLayout="row"
			[ngClass]="{ 'file-row-item': listItems.length === 1, 'file-row-items': listItems.length > 1 }">
			<div
				fxLayout="column"
				*ngIf="item.fileBlobInfoDto && !item.fileBlobInfoDto.markForRemoval"
				fxFlex="0 0 100%">
				<div
					class="card-solid-content-files"
					fxLayout="column"
					fxFlex="0 0 100%">
					<div fxLayout="row">
						<div
							fxLayout="column"
							fxFlex="0 0 90%">
							<a
								><button
									id="'btn_View' + 1"
									kendoButton
									class="standAloneReportBtnGridView"
									[primary]="true"
									[look]="'flat'"
									(click)="downloadMedia(item.fileBlobInfoDto.fileName)">
									{{ item.fileBlobInfoDto.fileName }}
								</button></a
							>
						</div>
						<div
							fxLayout="column"
							fxFlex="0 0 10%"
							class="outerdiv">
							<div class="icon-response-delete">
								<button
									id="'btnAdd_Remove' + i"
									kendoButton
									[look]="'flat'"
									type="button"
									[iconClass]="'fal fa-trash-alt'"
									class="icon-delete"
									(click)="removeMedia(i)"></button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				fxLayout="column"
				*ngIf="item.file"
				fxFlex="0 0 100%">
				<div
					class="card-solid-content-files"
					fxLayout="column"
					fxFlex="0 0 100%">
					<div fxLayout="row">
						<div
							fxLayout="column"
							fxFlex="0 0 90%">
							<div fxLayout="row">
								<div class="file-image">
									<img
										src="{{ '../../../assets/images/file-icon.png' }}"
										height="25"
										width="19" />
								</div>
								<div class="file-name">{{ item.file.name }}</div>
							</div>
						</div>
						<div
							fxLayout="column"
							fxFlex="0 0 10%"
							class="outerdiv">
							<div class="icon-response-delete">
								<button
									id="'btnAdd_Remove' + i"
									kendoButton
									[look]="'flat'"
									type="button"
									[iconClass]="'fal fa-trash-alt'"
									class="icon-delete"
									(click)="removeFile(i)"></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="listItems.length === 0">
	<div
		fxLayout="column"
		fxFlex="0 0 100%"
		class="zone-drop">
		<div
			[ngClass]="{ 'custom-dropzone': isError === false, 'custom-dropzone-error': isError === true }"
			class="custom-dropzone"
			[kendoUploadDropZone]="'myZone'">
			<div class="custom-dropzone-img">
				<img
					src="{{ '../../../assets/images/upload-asset.png' }}"
					height="40"
					width="53" />
			</div>
			<div>Drag an Excel or CSV file here, or</div>
		</div>
		<div [ngClass]="{ fileUpload: isError === false, fileUploadError: isError === true }">
			<kendo-upload
				class="no-border"
				id="mediaImportComponent_kendoUpload"
				#upload
				zoneId="myZone"
				[saveUrl]="uploadSaveUrl"
				[removeUrl]="uploadRemoveUrl"
				[autoUpload]="false"
				[multiple]="isMultiple"
				[restrictions]="uploadRestrictions"
				(select)="onSelectEvent($event)"
				(upload)="onUploadEvent($event)"
				(remove)="onRemoveEvent($event, upload)">
				<kendo-upload-messages
					dropFilesHere=""
					select="click to browse.">
				</kendo-upload-messages>
			</kendo-upload>
		</div>
	</div>
</ng-container>
