import { Directive, KeyValueDiffers, Component, Input } from '@angular/core';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { KpaSelectionDirective, IKpaSelectionItemBinding } from './kpa-selection.directive';

@Directive({
	selector: '[kpaMultiSelect]',
	exportAs: 'kpaMultiSelect',
})
export class KpaMultiSelectDirective extends KpaSelectionDirective {
	constructor(multiselect: MultiSelectComponent, diff: KeyValueDiffers) {
		super(multiselect, diff);
	}
}

@Component({
	template:
		'<input type="checkbox" kendoCheckBox [checked]="isChecked(kpaItem.value)" /><label class="k-checkbox-label">{{kpaItem.text}}</label>',
	selector: 'kpa-multiselect-item',
	exportAs: 'kpaMulitiSelectItem',
})
export class KpaMultiSelectItemComponent {
	constructor(private readonly multiselect: MultiSelectComponent) {}

	@Input()
	kpaItem: IKpaSelectionItemBinding<any>;

	isChecked(value: any): boolean {
		return (
			this.multiselect.valuePrimitive == this.multiselect.value.includes(value) ||
			(!this.multiselect.valuePrimitive && !!this.multiselect.value.find((item) => item.value === value))
		);
	}
}
