import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { LoadingIndicatorService } from '@app/core/services/loading-indicator.service';

@Component({
	selector: 'app-kpa-spinner',
	templateUrl: './kpa-spinner.component.html',
	styleUrls: ['./kpa-spinner.component.css'],
})
export class KpaSpinnerComponent implements OnInit {
	loading: boolean = false;
	constructor(private loadingIndicatorService: LoadingIndicatorService) {
		this.loadingIndicatorService.onLoadingChanged.subscribe((isLoading) => (this.loading = isLoading));
	}

	public opened = true;

	public close() {
		this.opened = false;
	}

	public open() {
		this.opened = true;
	}

	ngOnInit() {}
}
