/**
 * A general class to work with guid
 * @author borrowed from https://gist.github.com/emptyother/1fd97db034ef848f38eca3354fa9ee90
 * @example
 * var e = new Guid();
 * e;  // Guid { value: '00000000-0000-0000-0000-000000000000' }​​​​​
 * e.toString(); // 00000000-0000-0000-0000-000000000000​​​​​
 *
 * console.log(e); // ​​​​​Guid { value: '00000000-0000-0000-0000-000000000000' }​​​​​
 *
 * JSON.stringify(e); // ​​​​​"00000000-0000-0000-0000-000000000000"​​​​​
 *
 * e = Guid.newGuid(); // ​​​​​Guid { value: 'bb90ef83-1a7e-42b1-90ba-39cdebb6366c' }​​​​​
 * JSON.stringify(e); // ​​​​​"bb90ef83-1a7e-42b1-90ba-39cdebb6366c"​​​​​
 *
 * Guid.isValid(e.toString()); // true
 * Guid.empty; // ​​​​​00000000-0000-0000-0000-000000000000​​​​​
 * Guid.isValid(Guid.empty); // false
 */
export class Guid {
	public static EMPTY = '00000000-0000-0000-0000-000000000000';

	private value: string = this.empty;

	/**
	 * makes a new instance with a random guid
	 */
	public static newGuid(): Guid {
		return new Guid(
			'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
				const r = (Math.random() * 16) | 0;
				const v = c == 'x' ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			})
		);
	}

	/**
	 * return an empty guid as string
	 */
	public static get empty(): string {
		return Guid.EMPTY;
	}

	public static get EmptyGuid(): Guid {
		return new Guid(Guid.EMPTY);
	}

	/**
	 * checks if a string is a valid guid
	 * @param str string to check for valid guid
	 */
	public static isValid(str: string): boolean {
		// going with very generic guid so support aaaaaabb-BBBB-0000-0000-000000000001
		// and FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF
		const validRegex = /^[\dA-Fa-f]{8}-[\dA-Fa-f]{4}-[\dA-Fa-f]{4}-[\dA-Fa-f]{4}-[\dA-Fa-f]{12}$/i;
		return validRegex.test(str);
	}

	/**
	 * return an empty as a string
	 */
	public get empty(): string {
		return Guid.empty;
	}

	/**
	 * checks if Guid equal
	 * @param other
	 */
	public equals(other: Guid): boolean {
		// Comparing string `value` against provided `guid` will auto-call
		// toString on `guid` for comparison
		return this.value === other.toString();
	}

	/**
	 * Constructor to make a new instance, does a check if a string value is passed in, if valid sets the value.
	 * if not passed in or not valid, sets value to empty
	 * @param value
	 */
	constructor(value?: string) {
		if (value) {
			this.value = value;
		}
	}
	/**
	 * returns this instance as a guid string
	 */
	public toString() {
		return this.value;
	}

	/**
	 * returns this instance as a json string, looks off so might need to fix
	 */
	public toJSON(): string {
		return this.value;
	}
}
