import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
	private _operationId: string;
	public get operationId(): string {
		return this._operationId;
	}

	constructor(
		public readonly location: Location,
		private readonly route: ActivatedRoute
	) {}

	ngOnInit() {
		this._operationId = this.route.snapshot.paramMap.get('operationId');
	}
}
