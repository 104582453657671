<div class="row">
	<div class="left osha-content">
		<div
			*ngIf="showCompletedIcon"
			class="osha-icon osha-icon-green interactive"
			id=" osha_icon_green{{ index }}"></div>
		<div
			kendoTooltip
			filter="div"
			id="icon"
			[tooltipTemplate]="template">
			<div
				*ngIf="!showCompletedIcon"
				class="osha-icon osha-icon-orange interactive"
				id="osha_icon_orange{{ index }}"></div>
		</div>
	</div>

	<ng-template
		#template
		let-anchor
		showOn="none">
		<span>{{ toolTip }}</span>
	</ng-template>

	<div class="right">
		<div
			class="card-solid"
			fxLayout="column"
			[formGroup]="group"
			fxFlex="0 0 90%">
			<div
				class="card-solid-content"
				fxLayout="column">
				<div
					fxLayout="row"
					class="k-form-field">
					<div
						fxLayout="column"
						fxFlex="35">
						<div *ngIf="isFirstColumnText">
							<kendo-textbox
								[formControl]="mappingFieldFirstColumn"
								id="mappingFieldFirstColumnText{{ index }}"
								[readonly]="true"></kendo-textbox>
						</div>
						<div *ngIf="!isFirstColumnText">
							<kendo-dropdownlist
								kpaDropDownList
								[data]="firstFilteredData"
								[valuePrimitive]="true"
								id="mappingFieldFirstColumnList{{ index }}"
								[formControl]="mappingFieldFirstColumn"
								[textField]="'text'"
								[valueField]="'value'"
								[defaultItem]="emptyPlaceholder"
								[filterable]="true"
								(filterChange)="firstFilterChanged($event)"
								(valueChange)="onFirstFieldChanged($event)"
								(selectionChange)="selectionChange($event)">
							</kendo-dropdownlist>
						</div>
						<div *ngIf="isRequiredField">
							<p class="required-word">Required</p>
						</div>
					</div>
					<div
						fxLayout="column"
						fxFlex="10"
						class="column-align-middle">
						<div class="mapping-icon-size mapping-icon"></div>
					</div>
					<div
						fxLayout="column"
						fxFlex="35">
						<div *ngIf="isSecondColumnText">
							<kendo-textbox
								[formControl]="mappingFieldSecondColumn"
								id="mappingFieldSecondColumnText{{ index }}"
								[readonly]="true"></kendo-textbox>
						</div>
						<div *ngIf="!isSecondColumnText">
							<kendo-dropdownlist
								kpaDropDownList
								[data]="secondFilteredData"
								[valuePrimitive]="true"
								id="mappingFieldSecondColumnList{{ index }}"
								[formControl]="mappingFieldSecondColumn"
								[textField]="'text'"
								[valueField]="'value'"
								[defaultItem]="emptySecondPlaceholder"
								[filterable]="true"
								(filterChange)="secondFilterChanged($event)"
								(valueChange)="onSecondFieldChanged($event)"
								(selectionChange)="selectionChange($event)">
							</kendo-dropdownlist>
						</div>
					</div>

					<div
						fxLayout="column"
						fxFlex="20"
						class="column-align-middle"
						*ngIf="isMapping">
						<div
							*ngIf="isMapping"
							class="column-map-values"
							id="isMapping{{ index }}">
							<button
								*ngIf="isMappingDisabled"
								kendoButton
								kpaTextButton
								[disabled]="true">
								Map Values
							</button>
							<a
								*ngIf="!isMappingDisabled"
								(click)="additionalMapping(index)"
								>Map Values</a
							>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			fxLayout="column"
			fxFlex="0 0 9%"
			*ngIf="isDelete"
			class="delete-button">
			<div class="icon-response-delete">
				<button
					id="btnDeleteMapping{{ index }}"
					kendoButton
					[look]="'flat'"
					type="button"
					[iconClass]="'fal fa-trash-alt'"
					class="icon-delete"
					(click)="removeImport(index)"></button>
			</div>
		</div>
	</div>
</div>
