import { Component, OnInit, Input, Output } from '@angular/core';
import { KpaStatusCardState } from '../componentEnums';
import { EventEmitter } from '@angular/core';

@Component({
	selector: 'kpa-status-card',
	templateUrl: './kpa-status-card.component.html',
	styleUrls: ['./kpa-status-card.component.css'],
})
export class KpaStatusCardComponent implements OnInit {
	@Input()
	public mainTitle: string;

	@Input()
	public state: string;

	public stateClass: boolean;

	@Output()
	close: EventEmitter<any>;

	constructor() {
		this.mainTitle = '';
		this.state = KpaStatusCardState.Default;
		this.close = new EventEmitter<any>();
	}

	ngOnInit() {
		switch (this.state) {
			case KpaStatusCardState.Default: {
				this.stateClass = true;
				break;
			}
			case KpaStatusCardState.IconSuccess: {
				this.stateClass = true;
				break;
			}
			case KpaStatusCardState.IconWarning: {
				this.stateClass = false;
				break;
			}
			case KpaStatusCardState.IconAlert: {
				this.stateClass = true;
				break;
			}
		}
	}

	public hide() {
		this.close.emit(null);
	}
}
