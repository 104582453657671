<div class="kpa-date-range-filter-container">
	<button
		kendoButton
		kpaPrimaryButton
		(click)="onClearClick()"
		[disabled]="clearDisabled()"
		iconClass="fa-solid fa-xmark"></button>
	<kendo-daterange>
		<kendo-dateinput
			class="range-filter-start"
			kendoDateRangeStartInput
			[(value)]="range.start"
			placeholder="Start Date"></kendo-dateinput>
		<kendo-dateinput
			class="range-filter-end"
			kendoDateRangeEndInput
			[(value)]="range.end"
			placeholder="End Date"></kendo-dateinput>
	</kendo-daterange>
	<button
		kendoButton
		kpaPrimaryButton
		(click)="onApplyClick()"
		[disabled]="applyDisabled()"
		iconClass="fa-solid fa-magnifying-glass"
		tooltip="filter by date range"></button>
</div>
