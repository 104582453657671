import { Component, Input } from '@angular/core';

@Component({
	selector: 'kpa-primary-button',
	templateUrl: './primary-button.component.html',
})
export class KpaPrimaryButtonComponent {
	@Input() buttonLabel: string;

	public constructor() {}
}
