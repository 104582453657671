import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { EphemeralStorage } from './ephemeralStorage';
import { Observable, of, Subscription } from 'rxjs';

import { LOCAL_STORAGE } from './storage';
import { AuthService } from './auth.service';
import { User } from 'oidc-client';
import { LogService } from './log.service';

export interface RequestCacheEntry {
	response: HttpResponse<any>;
	expires: Date;
	expiresForCheck: number;
}

// #docregion request-cache
// export abstract class RequestCacheAbstract {
// 	abstract get(req: HttpRequest<any>): HttpResponse<any> | undefined;
// 	abstract put(req: HttpRequest<any>, response: HttpResponse<any>): void
// }
@Injectable({
	providedIn: 'root',
})
export class RequestCacheService extends EphemeralStorage {
	/*
	 * Creates a PermissionService instance.
	 * @param storage The persistence store for API results.
	 * @param environment The environment variables for calling APIs.
	 * @param client The HTTP client.
	 */
	constructor(
		private readonly authService: AuthService,
		private readonly log: LogService,
		@Inject(LOCAL_STORAGE) storage: Storage
	) {
		super('Kpa.Request', storage);
		this.createObservables();
	}

	private userChange$: Subscription;

	private createObservables(): void {
		this.userChange$ = this.authService.user.subscribe((user) => this.handleUserChangedCache(user));
	}
	private handleUserChangedCache(oidcUser: User): Observable<boolean> {
		this.log.logVerbose('requestCache: handling user change');
		const sessionId = (oidcUser && oidcUser.profile && oidcUser.profile.sid) || null;
		const isLoggedIn = oidcUser && !oidcUser.expired;

		if (!isLoggedIn || !sessionId) {
			super.dispose();
			return of<boolean>(false);
		}
		this.log.logVerbose('requestCache: initializing');
		super.initialize(sessionId);

		return of<boolean>(true);
	}
	/**
	 * @description attempt to retrieve from cache or return null
	 * @param req
	 */
	get(req: HttpRequest<any>): HttpResponse<any> | undefined {
		if (this.initialized === false) {
			return undefined;
		}

		const key = req.urlWithParams;
		const cached = this.getItemAs<RequestCacheEntry>(key);

		if (!cached) {
			return undefined;
		}

		// we have a value, see if expired
		const isExpired: boolean = cached.expiresForCheck < Date.now();
		if (isExpired) {
			this.removeItem(key);
			return undefined;
		}
		return cached.response;
	}

	/**
	 * @description put respones into cache with exiration set with milliseconds
	 * @param req
	 * @param response
	 * @param cacheForMilliseconds
	 */
	put(req: HttpRequest<any>, response: HttpResponse<any>, cacheForMilliseconds: number): void {
		if (this.initialized) {
			const key = req.urlWithParams;
			const expireGen = new Date();
			expireGen.setTime(Date.now() + cacheForMilliseconds);
			const entry: RequestCacheEntry = { response, expires: expireGen, expiresForCheck: expireGen.getTime() };
			this.setItem(key, entry);
		}
	}

	checkCache(key: string): RequestCacheEntry {
		return this.getItemAs<RequestCacheEntry>(key);
	}
}
