import { Injectable } from '@angular/core';

/**
 * An enumeration of EHS roles
 */
export enum EhsRoles {
	None = 0,
	UnableToMap,

	ClientAdmin,
	ClientPowerUser,
	ClientUser,

	KPAAccountAdmin,
	KPAAdmin,
	KPADeveloper,
	KPADistrictLead,
	KPADistrictManager,
	KPAPowerUser,
	KPASales,
	KPASystemAdmin,
	KPAUser,
	TrainingUser,
}

/**
 * An enumeration of HRM roles
 */
export enum HrmRoles {
	None = 0,
	UnableToMap,

	Employee,
	HrAdministrator,
	Manager,
	SystemAdministrator,
}

/**Interface to map legacy roles to enumerations by role name, as role id's aren't constant */
export interface ILegacyRoleMapper {
	MapToEhsRole(roleName: string): EhsRoles;
	MapToHrmRole(roleName: string): HrmRoles;
}

/**Class to map legacy roles to enumerations by role name, as role id's aren't constant */
@Injectable({
	providedIn: 'root',
})
export class LegacyRoleMapperService implements ILegacyRoleMapper {
	MapToEhsRole(roleName: string): EhsRoles {
		if (!roleName) {
			return EhsRoles.None;
		}

		switch (roleName.toLowerCase()) {
			case 'client admin':
				return EhsRoles.ClientAdmin;
			case 'client power user':
				return EhsRoles.ClientPowerUser;
			case 'client user':
				return EhsRoles.ClientUser;
			case 'kpa account admin':
				return EhsRoles.KPAAccountAdmin;
			case 'kpa district manager':
				return EhsRoles.KPADistrictManager;
			case 'kpa developer':
				return EhsRoles.KPADeveloper;
			case 'kpa district lead':
				return EhsRoles.KPADistrictLead;
			case 'kpa sales':
				return EhsRoles.KPASales;
			case 'kpa system admin':
				return EhsRoles.KPASystemAdmin;
			case 'kpa power user':
				return EhsRoles.KPAPowerUser;
			case 'kpa user':
				return EhsRoles.KPAUser;
			case 'training user':
				return EhsRoles.TrainingUser;
			case 'kpa admin':
				return EhsRoles.KPAAdmin;
			default:
				return EhsRoles.UnableToMap;
		}
	}

	MapToHrmRole(roleName: string): HrmRoles {
		if (!roleName) {
			return HrmRoles.None;
		}

		switch (roleName.toLowerCase()) {
			case 'employee':
				return HrmRoles.Employee;
			case 'hr administrator':
				return HrmRoles.HrAdministrator;
			case 'manager':
				return HrmRoles.Manager;
			case 'system administrator':
				return HrmRoles.SystemAdministrator;
			default:
				return HrmRoles.UnableToMap;
		}
	}

	constructor() {}
}
