import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserInfoService, SecurityPermissions } from './user-info.service';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { ApplicationFeature } from '../models/application-feature-enums';

@Injectable({
	providedIn: 'root',
})
export class ImpersonateGuardService implements CanActivate {
	constructor(private readonly userInfoService: UserInfoService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.userInfoService.hasPermission(ApplicationFeature.Security, SecurityPermissions.Impersonate).pipe(take(1));
	}
}
