import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionsLayout } from '@progress/kendo-angular-dialog';

@Component({
	selector: 'app-dynamic-dialog',
	templateUrl: './dynamic-dialog.component.html',
	styleUrls: ['./dynamic-dialog.component.css'],
})
export class DynamicDialogComponent implements OnInit {
	actionsLayout: ActionsLayout = 'end';
	@Output() confirm = new EventEmitter<void>();
	@Output() close = new EventEmitter<void>();

	@Input() dialogPrompt: string;
	@Input() closePrompt: string;
	@Input() confirmPrompt: string;
	@Input() titlePrompt: string;
	@Input() passedHeight: number;
	@Input() passedWidth: number;

	dialogHeight: number = 200;
	dialogWidth: number = 450;
	constructor() {}

	ngOnInit() {
		if (this.passedHeight != null) {
			this.dialogHeight = this.passedHeight;
		}

		if (this.passedWidth != null) {
			this.dialogWidth = this.dialogWidth;
		}
	}

	onConfirm() {
		this.confirm.emit();
	}

	onClose() {
		this.close.emit();
	}
}
