import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LogService } from '@app/core/services/log.service';
import { Environment } from '../../../../../environments/environment';
import { BaseApiClient } from '@app/core/services/base-api-client.service';
import { LoadingIndicatorService } from '@app/core/services/loading-indicator.service';
import { Guid } from '@app/shared/models/guid';
import { FormGroup } from '@angular/forms';
import { DepartmentDto } from '../models/departmentDto';
import { JobDescriptionDto } from '../models/JobDescriptionDto';

const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
export interface IDepartmentService {
	url: string;
	save(departmentName: string, universalId: string): Observable<any>;
	getJobDescription(serviceId: string): Observable<JobDescriptionDto[]>;
}

@Injectable()
export class DepartmentService extends BaseApiClient implements IDepartmentService {
	public baseUrl: string; // base url
	public url: string; // URL to web api

	constructor(
		private readonly http: HttpClient,
		private readonly log: LogService,
		private readonly loadingIndicatorService: LoadingIndicatorService,
		environment: Environment
	) {
		super();
		this.baseUrl = environment.services['clients'];
		this.url = this.baseUrl + 'departments/hrm';
	}

	public save(departmentName: string, serviceToUniversalId: string): Observable<any> {
		var _dept = new DepartmentDto();
		_dept.Active = true;
		_dept.DepartmentName = departmentName;
		_dept.SpanishName = null;

		let entity = _dept;
		let entityData = JSON.stringify(entity);

		const urls = `${this.url}/${serviceToUniversalId}/CreateDepartment`;

		this.loadingIndicatorService.startLoading();
		return this.http.post(urls, entity, httpOptions).pipe(
			tap((id) => {
				this.log.logInformation(`created department entity id=${id}`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}
	getJobDescription(serviceId: string): Observable<JobDescriptionDto[]> {
		const url = `${this.url}/${serviceId}/GetJobDescription`;
		this.log.logInformation('DistrictDto Get with id:' + serviceId);

		this.loadingIndicatorService.startLoading();
		return this.http.get<JobDescriptionDto[]>(url).pipe(
			tap((): void => {
				this.log.logInformation(`fetched JobDescription id=${serviceId}`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	getAllMkoDepartments(serviceToId: string): Observable<DepartmentDto[]> {
		const url = `${this.baseUrl}serviceTo/${serviceToId}/departments/mko`;
		this.log.logInformation('DepartmentDto Get with serviceToId:' + serviceToId);

		this.loadingIndicatorService.startLoading();
		return this.http.get<DepartmentDto[]>(url).pipe(
			tap((): void => {
				this.log.logInformation(`fetched Depatartments serviceToId=${serviceToId}`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}
}
