import { Component, OnInit, Input } from '@angular/core';
import { CardBadgeState } from '../componentEnums';

@Component({
	selector: 'kpa-card',
	templateUrl: './kpa-card.component.html',
	styleUrls: ['./kpa-card.component.css'],
})
export class KpaCardComponent implements OnInit {
	@Input()
	public badgeId: string;

	@Input()
	public mainTitle: string;

	@Input()
	public secondaryTitle: string;

	@Input()
	public badgeText: string;

	@Input()
	public showBadge: boolean;

	@Input()
	public showBadgeMain: boolean;

	@Input()
	public styles: any = {};

	@Input()
	public classes: string;

	@Input()
	public badgeStatus: CardBadgeState = CardBadgeState.Default;
	private badgeEnum = CardBadgeState;

	constructor() {}

	ngOnInit() {}
}
