import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Item } from '@app/shared/models/item';

@Component({
	selector: 'kpa-typeahead-ddl',
	templateUrl: './kpa-typeahead-ddl.component.html',
	styleUrls: ['./kpa-typeahead-ddl.component.css'],
})
export class KpaTypeaheadDdlComponent implements OnInit, OnChanges {
	readonly emptyPlaceholder: Item = { text: '', value: null };

	@Input() placeholder: string = '';
	@Input() id: string;
	@Input() selectLabel: string = 'Type to select...';
	@Input() data: Item[] = [];
	@Input() value: string;

	@Output() valueChange = new EventEmitter<any>();

	public filteredData: Item[] = this.data;

	constructor() {}

	ngOnInit(): void {
		this.emptyPlaceholder.text = this.placeholder;
	}

	ngOnChanges(changes: SimpleChanges) {
		for (const propName in changes) {
			if (propName === 'data') {
				this.filteredData = this.data;
			}
		}
	}

	selectionChanged(event) {
		this.valueChange.emit(event);
	}

	filterChanged(value) {
		const filteredVal = this.data;
		this.filteredData = filteredVal.filter((s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1);
	}
}
