import { InjectionToken } from '@angular/core';
import { Inject, Injectable } from '@angular/core';

export const WindowToken = new InjectionToken('Window');
export function windowProvider() {
	return window;
}

@Injectable({
	providedIn: 'root',
})
export class WindowService implements IWindowService {
	constructor(@Inject(WindowToken) private window: Window) {}

	/**
	 * Switches current windows href, so watch that history
	 * @param url
	 */
	jumpTo(url: string) {
		this.window.location.href = url;
	}

	openBlobInNewWindow(object: any) {
		let url = URL.createObjectURL(object);
		this.window.open(url);
	}

	/**
	 * Performs Browser default action for opening a window, in chrome its a new tab.
	 * @param url
	 */
	openUrlInNewWindow(url: string) {
		this.window.open(url);
	}
}

export interface IWindowService {
	/**
	 * Switches current windows href
	 * @param url
	 */
	jumpTo(url: string): void;
	openBlobInNewWindow(object: any): void;
	/**
	 * Performs Browser default action for opening a window, in chrome its a new tab.
	 * Usually used to run a report
	 * @param url
	 */
	openUrlInNewWindow(url: string): void;
}
