import { Injectable } from '@angular/core';
import { BaseApiClient } from '../../../core/services/base-api-client.service';
import { HttpClient } from '@angular/common/http';
import { LogService } from '../../../core/services/log.service';
import { Environment } from '@env/environment';
import { catchError, tap } from 'rxjs/operators';
import { CommonNavNodeDto } from '../../../core/models/common-nav-dto';
import { Observable } from 'rxjs';

export interface INavigationService {
	url: string;
	getTopNodes(): Observable<CommonNavNodeDto[]>;
}

@Injectable({
	providedIn: 'root',
})
export class NavigationService extends BaseApiClient implements INavigationService {
	public url: string; // URL to web api

	constructor(
		private readonly http: HttpClient,
		private readonly log: LogService,
		environment: Environment
	) {
		super();
		this.url = environment.services['navigation'];
	}

	getTopNodes(): Observable<CommonNavNodeDto[]> {
		const url = `${this.url}menuitems/top`;

		return this.http.get<CommonNavNodeDto[]>(url).pipe(
			tap((): void => {
				this.log.logInformation(`Got top nav nodes`);
			}),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}
}
