import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { User } from 'oidc-client';
import { UserInfoService } from '@app/core/services/user-info.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum SsoLayoutTheme {
	BlueGreen,
	Blue,
}

@Component({
	selector: 'app-sso-layout',
	templateUrl: './sso-layout.component.html',
	styleUrls: ['./sso-layout.component.css'],
})
export class SsoLayoutComponent implements OnInit {
	private _theme: SsoLayoutTheme;
	public get theme(): SsoLayoutTheme {
		return this._theme;
	}
	public set theme(value: SsoLayoutTheme) {
		this._theme = value;
	}

	blue = SsoLayoutTheme.Blue;

	year = new Date().getFullYear();

	originator$: Observable<string>;
	user$: Observable<string>;
	exitImpersonationUrl = location.origin + '/end-impersonation';

	constructor(
		private readonly authService: AuthService,
		private readonly userInfoService: UserInfoService
	) {}

	ngOnInit() {
		this.originator$ = this.authService.originator.pipe(map((originator) => this.getUserName(originator)));

		this.user$ = this.userInfoService.getDisplayName();
	}

	public getUserName(user: User, defaultValue: string = ''): string {
		// Default to a generic value
		let rtn = defaultValue;

		// If the User is valid, try to get the values
		if (user && user.profile) {
			// Get the values
			const displayName = user.profile.preferred_username || '';
			const email = user.profile.email || '';

			// Return Display Name or Email
			rtn = displayName || email || '~No User Name Found~';
		}

		// Return the result
		return rtn;
	}
}
