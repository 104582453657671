import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BaseComponent } from '../../shared/base-component';
import { LogService } from '../../core/services/log.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { SecurityService } from '../../core/services/security.service';
import { CredentailsDto } from '../../core/models/credentials-dto';
import { UserInfoDto } from '../../core/models/user-info-dto';
import { EmployeeDto, PreferredMethodOfCommunication } from '@app/employee/employee/shared/models/employeeDto';
import { EmployeeService } from '@app/employee/employee/shared/services/employee.service';
import { LoadingIndicatorService } from '@app/core/services/loading-indicator.service';
import { EmailType } from '@app/core/models/email-type';
import { ClientService } from '@app/clients/shared/services/client.service';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.css'],
})
export class ProfileComponent extends BaseComponent implements OnInit {
	@ViewChild('inpRadio_CellPhone_UCL') cellPhoneInput: any;
	@ViewChild('inpRadio_PersonalEmail_UCL') personalEmailInput: any;
	@ViewChild('inpRadio_WorkEmail_UCL') workEmailInput: any;

	message: string;
	productListData: Array<string> = ['Environmental Health & Safety'];
	productListSelected: string = this.productListData[0];
	profileForm: UntypedFormGroup;
	userInfoDto: UserInfoDto;
	credentialsDto: CredentailsDto;
	showPasswordUsernameErrorMessage: boolean = false;
	passwordUsernameErrorMessage: string;
	passwordUsernameErrorMessageTitle: string;
	showPasswordUsernameSuccessMessage: boolean = false;
	passwordUsernameSuccessMessage: string;
	registrationLabel: string;
	cellPhoneLabel = 'Cell Phone';
	personalEmailLabel = 'Personal Email';
	workEmailLabel = 'Work Email';
	employeeDto: EmployeeDto;
	showPreferredCommunicationMethodSection: boolean;
	showPhoneRadio: boolean;
	showWorkEmailRadio: boolean;
	showPersonalEmailRadio: boolean;
	showPreferredContactErrorMessage: boolean = false;
	preferredContactErrorMessage: string;
	showPreferredContactSuccessMessage: boolean = false;

	emailRegType = new UntypedFormGroup({
		regType: new UntypedFormControl(),
	});
	isSSOEnabled: boolean;

	public constructor(
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private titleService: Title,
		protected readonly log: LogService,
		private securityService: SecurityService,
		private readonly employeeService: EmployeeService,
		private readonly loadingService: LoadingIndicatorService,
		private readonly clientService: ClientService
	) {
		super(log);
	}

	ngOnInit() {
		this.titleService.setTitle('Profile');

		this.profileForm = new UntypedFormGroup({
			username: new UntypedFormControl(''),
			currentPassword: new UntypedFormControl(''),
			newPassword: new UntypedFormControl(''),
			confirmPassword: new UntypedFormControl(''),
		});

		this.profileForm.reset();
		this.getMyInfo();
	}

	getClientSettings() {
		this.clientService.getClientSettings(this.employeeDto.clientId).subscribe((response) => {
			console.log(response);
			if (response) {
				this.isSSOEnabled = response.ssoEnabled && this.employeeDto.ssoUsername !== undefined;
			}
		});
	}

	getMyInfo() {
		this.securityService.getMyUserInfo().subscribe((dto: UserInfoDto) => {
			this.userInfoDto = dto;

			this.securityService.getMyCredentials().subscribe((dto: CredentailsDto) => {
				this.credentialsDto = dto;
				this.getEmployeeInfo();
			});
		});
	}

	getEmployeeInfo() {
		this.employeeService.get(this.userInfoDto.employeeId).subscribe(
			(modelDto: EmployeeDto) => {
				this.employeeDto = modelDto;
				this.setPreferredMethodOfCommunicationSection();

				this.getClientSettings();
			},
			() => {
				this.log.logError('Employee Dto had error loading');
				return;
			}
		);
	}

	setPreferredMethodOfCommunicationSection() {
		if (
			(!this.employeeDto.phoneNumber && this.employeeDto.emails.length == 0) || // all 3 null
			(this.employeeDto.phoneNumber && this.employeeDto.emails.length == 0) || // only phone
			(!this.employeeDto.phoneNumber && this.employeeDto.emails.length == 1) || // only 1 email
			(this.employeeDto.phoneNumber && this.employeeDto.emails.length == 1 && this.employeeDto.emails[0].emailTypeId == 'Alternate') || // phone and alternate email only
			(!this.employeeDto.phoneNumber &&
				this.employeeDto.emails.length == 2 &&
				this.employeeDto.emails.filter(function (e) {
					return e.emailTypeId === 'Alternate';
				}).length > 0) // 2 emails only and one has alternate
		) {
			// hide the section
			this.showPreferredCommunicationMethodSection = false;
		} else {
			// more than 1 preferred method of communication present - show section
			this.showPreferredCommunicationMethodSection = true;
			//determine radio button option display property and set radio value
			this.determinePrefCommunicationRadioDisplayProperties();
			this.setPrefCommunicationRadioControl();
		}
	}

	determinePrefCommunicationRadioDisplayProperties() {
		if (this.employeeDto.phoneNumber) {
			this.showPhoneRadio = true;
		}
		if (this.employeeDto.emails) {
			for (let email of this.employeeDto.emails) {
				if (email.emailTypeId == 'Company') {
					this.showWorkEmailRadio = true;
				} else if (email.emailTypeId == 'Personal') {
					this.showPersonalEmailRadio = true;
				}
			}
		}
	}

	setPrefCommunicationRadioControl() {
		//set radio control
		if (this.employeeDto.preferredMethodOfCommunication == 'WorkEmail') {
			this.emailRegType.patchValue({ regType: PreferredMethodOfCommunication['WorkEmail'].toString() });
		} else if (this.employeeDto.preferredMethodOfCommunication == 'PersonalEmail') {
			this.emailRegType.patchValue({ regType: PreferredMethodOfCommunication['PersonalEmail'].toString() });
		} else if (this.employeeDto.preferredMethodOfCommunication == 'CellPhone') {
			this.emailRegType.patchValue({ regType: PreferredMethodOfCommunication['CellPhone'].toString() });
		}
	}

	onSave() {
		this.loadingService.startLoading();
		window.scrollTo(0, 0);
		this.showPasswordUsernameSuccessMessage = false;
		this.showPasswordUsernameErrorMessage = false;
		this.showPreferredContactSuccessMessage = false;
		this.showPreferredContactErrorMessage = false;

		var username = this.profileForm.get('username').value;
		var currentPassword = this.profileForm.get('currentPassword').value;
		var newPassword = this.profileForm.get('newPassword').value;
		var confirmPassword = this.profileForm.get('confirmPassword').value;

		var hasUsername = username !== undefined && username !== null && username !== '';
		var hasCurrentPassword = currentPassword !== undefined && currentPassword !== null && currentPassword !== '';
		var hasNewPassword = newPassword !== undefined && newPassword !== null && newPassword !== '';
		var hasConfirmPassword = confirmPassword !== undefined && confirmPassword !== null && confirmPassword !== '';

		// Manual Error Checks
		var updatePassword = false;
		var updateUsername = false;
		if (hasUsername && username === this.credentialsDto.userName) {
			this.passwordUsernameErrorMessage = 'New Username Cannot Be The Same As Your Previous Username.';
			this.passwordUsernameErrorMessageTitle = 'Username Error';
			this.showPasswordUsernameErrorMessage = true;
		} else if (!hasCurrentPassword && (hasNewPassword || hasConfirmPassword)) {
			this.passwordUsernameErrorMessage = 'Please Enter Your Current Password.';
			this.passwordUsernameErrorMessageTitle = 'Password Error';
			this.showPasswordUsernameErrorMessage = true;
		} else if (!hasNewPassword && (hasCurrentPassword || hasConfirmPassword)) {
			this.passwordUsernameErrorMessage = 'Please Enter Your New Password.';
			this.passwordUsernameErrorMessageTitle = 'Password Error';
			this.showPasswordUsernameErrorMessage = true;
		} else if (!hasConfirmPassword && (hasCurrentPassword || hasNewPassword)) {
			this.passwordUsernameErrorMessage = 'Please Confirm Your New Password.';
			this.passwordUsernameErrorMessageTitle = 'Password Error';
			this.showPasswordUsernameErrorMessage = true;
		} else if (hasNewPassword && hasConfirmPassword && newPassword !== confirmPassword) {
			this.passwordUsernameErrorMessage = 'Passwords Do Not Match.';
			this.passwordUsernameErrorMessageTitle = 'Password Error';
			this.showPasswordUsernameErrorMessage = true;
		} else if (hasCurrentPassword && (!hasNewPassword || !hasConfirmPassword)) {
			this.passwordUsernameErrorMessage = 'Please Enter New Passwords.';
			this.passwordUsernameErrorMessageTitle = 'Password Error';
			this.showPasswordUsernameErrorMessage = true;
		} else if (hasCurrentPassword && hasNewPassword && hasConfirmPassword && newPassword === confirmPassword) {
			updatePassword = true;
		}

		if (hasUsername) {
			updateUsername = true;
		}

		if (this.checkIfPreferredContactUpdateToBeCalled() && (updatePassword || updateUsername)) {
			// both changed

			if (!hasUsername && this.credentialsDto.userName) {
				username = this.credentialsDto.userName;
			}

			this.credentialsDto = {
				active: true,
				userId: ' ',
				firstName: this.credentialsDto.firstName,
				lastName: this.credentialsDto.lastName,
				email: this.credentialsDto.email,
				phoneNumber: this.credentialsDto.phoneNumber,
				userName: username,
				password: confirmPassword,
				existingPasswordToCheck: currentPassword,
				roles: [],
				modified: new Date(),
				clientId: this.userInfoDto.clientId,
				twoFactorType: this.credentialsDto.twoFactorType,
			};

			this.securityService.updateMyCredentials(this.credentialsDto).subscribe(
				(result: any) => {
					this.logResult(result, 'Update My Credentials Result: ');
					this.showPasswordUsernameSuccessMessage = true;
					this.updatePreferredContact();
				},
				(error: any) => {
					this.passwordUsernameErrorMessage =
						error.error && error.error.errors && error.error.errors.length > 0
							? error.error.errors[0].message
							: 'Unable to Update Credentials.';
					this.passwordUsernameErrorMessageTitle = 'Unable To Update Credentials.';
					this.showPasswordUsernameErrorMessage = true;
					this.updatePreferredContact();
				}
			);
		} else if (updatePassword || updateUsername) {
			// only credentials changed
			this.updateCredentials(hasUsername, username, confirmPassword, currentPassword);
		} else if (this.checkIfPreferredContactUpdateToBeCalled()) {
			// only preferred contact changed
			this.updatePreferredContact();
		} else {
			// nothing changed
			this.loadingService.stopLoading();
		}
	}

	updateCredentials(hasUsername, username, confirmPassword, currentPassword) {
		// Check For Updated Username
		if (!hasUsername && this.credentialsDto.userName) {
			username = this.credentialsDto.userName;
		}

		this.credentialsDto = {
			active: true,
			userId: ' ',
			firstName: this.credentialsDto.firstName,
			lastName: this.credentialsDto.lastName,
			email: this.credentialsDto.email,
			phoneNumber: this.credentialsDto.phoneNumber,
			userName: username,
			password: confirmPassword,
			existingPasswordToCheck: currentPassword,
			roles: [],
			modified: new Date(),
			clientId: this.userInfoDto.clientId,
			twoFactorType: this.credentialsDto.twoFactorType,
		};

		this.securityService.updateMyCredentials(this.credentialsDto).subscribe(
			(result: any) => {
				this.logResult(result, 'Update My Credentials Result: ');
				this.showPasswordUsernameSuccessMessage = true;
				this.loadingService.stopLoading();
				this.profileForm.reset();
				this.getMyInfo();
			},
			(error: any) => {
				this.passwordUsernameErrorMessage =
					error.error && error.error.errors && error.error.errors.length > 0
						? error.error.errors[0].message
						: 'Unable to Update Credentials.';
				this.passwordUsernameErrorMessageTitle = 'Unable To Update Credentials.';
				this.showPasswordUsernameErrorMessage = true;
				this.loadingService.stopLoading();
			}
		);
	}

	updatePreferredContact() {
		this.employeeService.getUserProfileData().subscribe(
			(userProfileDto) => {
				console.log('UserProfileDataDto');
				console.log(userProfileDto);
				userProfileDto.preferredMethodOfCommunication = PreferredMethodOfCommunication[this.emailRegType.value.regType];
				userProfileDto.emails = this.employeeDto.emails;
				if (
					userProfileDto.preferredMethodOfCommunication == PreferredMethodOfCommunication[3] ||
					userProfileDto.preferredMethodOfCommunication == PreferredMethodOfCommunication[2]
				) {
					userProfileDto.emails.forEach((email) => {
						email.preferred = false;

						if (userProfileDto.preferredMethodOfCommunication == PreferredMethodOfCommunication[3] && email.emailTypeId === EmailType[1]) {
							//Set Preferred as Work Email
							email.preferred = true;
						}
						if (userProfileDto.preferredMethodOfCommunication == PreferredMethodOfCommunication[2] && email.emailTypeId === EmailType[2]) {
							//push Preferred as Personal Email
							email.preferred = true;
						}
					});
				}
				console.log('UserProfileDataDto for Credentials/My PUT');
				console.log(userProfileDto);
				this.employeeService.updateUserProfileData(userProfileDto).subscribe(
					(result: any) => {
						this.logResult(result, 'Update Preferred Contact Result: ');
						this.showPreferredContactSuccessMessage = true;
						this.loadingService.stopLoading();
						if (this.showPasswordUsernameSuccessMessage) {
							this.profileForm.reset();
							this.getMyInfo();
						} else {
							this.getEmployeeInfo();
						}
					},
					(error: any) => {
						this.log.logError('An error occurred while attempting to save Preferred Contact Method');
						this.preferredContactErrorMessage =
							error.error && error.error.errors && error.error.errors.length > 0
								? error.error.errors[0].message
								: 'Unable to Save Preferred Contact';
						this.showPreferredContactErrorMessage = true;
						this.loadingService.stopLoading();
						if (this.showPasswordUsernameSuccessMessage) {
							this.profileForm.reset();
							this.getMyInfo();
						}
					}
				);
			},
			(error: any) => {
				this.log.logError('An error occurred while attempting to save Preferred Contact Method');
				this.preferredContactErrorMessage =
					error.error && error.error.errors && error.error.errors.length > 0
						? error.error.errors[0].message
						: 'Unable to Save Preferred Contact';
				this.showPreferredContactErrorMessage = true;
				this.loadingService.stopLoading();
				if (this.showPasswordUsernameSuccessMessage) {
					this.profileForm.reset();
					this.getMyInfo();
				}
			}
		);
	}

	checkIfPreferredContactUpdateToBeCalled() {
		return (
			this.showPreferredCommunicationMethodSection &&
			this.employeeDto.preferredMethodOfCommunication !== PreferredMethodOfCommunication[this.emailRegType.value.regType]
		);
	}

	onCancel() {
		this.resetPatchedFormControls();
		this.router.navigate(['../dashboard'], { relativeTo: this.route.parent });
	}

	// Resets fields that are not on the profileForm but used to get values on save
	resetPatchedFormControls() {
		this.emailRegType.reset();
	}
}
