import { Directive, Input, HostListener, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
/**
 * Used to delay the FilterChange by the filterChangeDelayMs
 * @note derived from https://www.telerik.com/kendo-angular-ui/components/inputs/debounce-valuechange/
 */
@Directive({
	selector: '[AfterFilterChanged]',
})
export class AfterFilterChangedDirective implements OnDestroy {
	@Output()
	public afterFilterChanged: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * Milliseconds to delay the change, default 500
	 */
	@Input()
	public filterChangeDelayMs: number = 500;

	//used to catch filter changes as a stream
	private streamFilterChange: Subject<any> = new Subject<any>();

	//subscription listening in on streamValueChange
	private subscriptionDebounce: Subscription;

	/**
	 * Catches the filterChange event from the associated control
	 * @param value Catches the FilterChange event
	 */
	@HostListener('filterChange', ['$event'])
	public onFilterChange(filter: any): void {
		console.log('onFilterChange ' + filter);
		this.streamFilterChange.next(filter);
	}

	constructor() {
		//set up subscription to wait for debounceTime to emit afterFilterChanged event
		this.subscriptionDebounce = this.streamFilterChange.pipe(debounceTime(this.filterChangeDelayMs)).subscribe((value: any) => {
			this.afterFilterChanged.next(value);
		});
	}

	ngOnDestroy(): void {
		this.subscriptionDebounce.unsubscribe();
	}
}
