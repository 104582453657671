import { DatePipe } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

@Component({
	selector: 'kpa-grid-date-range-filter',
	templateUrl: './kpa-grid-date-range-filter.component.html',
	styleUrls: ['./kpa-grid-date-range-filter.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class KpaGridDateRangeComponent extends BaseFilterCellComponent {
	@Input() public filter: CompositeFilterDescriptor;

	@Input()
	public field: string;

	constructor(
		filterService: FilterService,
		public datePipe: DatePipe
	) {
		super(filterService);
	}

	public get start(): Date {
		const first = this.findByOperator('gte');

		return (first || <FilterDescriptor>{}).value;
	}

	public get end(): Date {
		const end = this.findByOperator('lte');
		return (end || <FilterDescriptor>{}).value;
	}

	public get hasFilter(): boolean {
		return this.filtersByField(this.field).length > 0;
	}

	public clearFilter(): void {
		this.filterService.filter(this.removeFilter(this.field));
	}

	public filterRange(start: Date, end: Date): void {
		this.filter = this.removeFilter(this.field);

		const filters = [];

		if (start) {
			let convertedStartDate = this.datePipe.transform(start, 'yyyy-MM-dd');
			filters.push({
				field: this.field,
				operator: 'gte',
				value: convertedStartDate,
			});
		}

		if (end) {
			let convertedEndDate = this.datePipe.transform(end, 'yyyy-MM-dd');
			filters.push({
				field: this.field,
				operator: 'lte',
				value: convertedEndDate,
			});
		}

		const root = this.filter || {
			logic: 'and',
			filters: [],
		};

		if (filters.length) {
			root.filters.push(...filters);
		}

		this.filterService.filter(root);
	}
	// Works fine when field is on Grid once
	private findByOperator(op: string): FilterDescriptor {
		return this.filtersByField(this.field).filter(({ operator }) => operator === op)[0];
	}
}
