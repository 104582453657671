import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserInfoService } from '@app/core/services/user-info.service';
import { LogService } from './log.service';

@Injectable({
	providedIn: 'root',
})
export class LandingGuardService implements CanActivate {
	constructor(
		private readonly router: Router,
		private readonly userInfoService: UserInfoService,
		private log: LogService
	) {}

	public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		// Get the UserInfo and determine the appropriate landing page
		return this.userInfoService.userInfo.pipe(
			switchMap((userInfo) => {
				let hasEhs = false;

				if (userInfo && userInfo.permissions) {
					let indexOfEhs = userInfo.permissions.findIndex((p) => p.featureId == 'Ehs');
					if (indexOfEhs !== -1) {
						hasEhs = true;
					}
				} else {
					hasEhs = this.userInfoService.getUserFeatureFromStorage();
				}

				// If the UserInfo hasn't been set, try to pull it from Local Storage
				userInfo = userInfo || this.userInfoService.getUserInfoFromStorage();

				// If the UserInfo still wasn't found, just return false.  Otherwise,
				// see if the user should be redirected.
				if (userInfo) {
					// Get the landing page with the UserInfo
					const url = this.userInfoService.getLandingPageUrl(userInfo, null, hasEhs);

					// Navigate to the URL - if the Router fails, this is an external
					// URL, so use the Window location to redirect
					this.router
						.navigateByUrl(url)
						.then(() => {
							console.log('LandingGuard - Navigate with Router: ' + url);
						})
						.catch(() => {
							console.log('LandingGuard - Navigate with URL: ' + url);
							window.location.href = url;
						});

					// Return true to indicate all is well
					return of(true);
				} else {
					// Return false to indicate no login
					return of(false);
				}
			})
		);
	}
}
