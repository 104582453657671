import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, withLatestFrom, mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { UserInfoService } from './user-info.service';
import { LandingRoutes } from '../components/landing/landing-routes';
import { EmployeeService } from '@app/employee/employee/shared/services/employee.service';
import { SecurityService } from '@app/core/services/security.service';
import { ClientService } from '@app/clients/shared/services/client.service';
import { UserState } from '../models/user-settings-dto';

@Injectable({
	providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
	constructor(
		private readonly authService: AuthService,
		private readonly userInfoService: UserInfoService,
		private readonly router: Router,
		private readonly employeeService: EmployeeService,
		private readonly securityService: SecurityService,
		private readonly clientService: ClientService
	) {}

	private redirectToContactMethodRequired(): Promise<boolean> {
		return this.router.navigateByUrl(LandingRoutes.ContactMethodRequired);
	}

	private redirectToUpdateContactInformation(): Promise<boolean> {
		return this.router.navigateByUrl(LandingRoutes.ContactMethodUpdate);
	}

	public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		var userValidated: Promise<boolean> = new Promise((resolve, reject) => {
			this.userInfoService.userInfo
				.pipe(
					withLatestFrom(this.authService.isLoggedIn),
					map(([userInfo, isLoggedIn]) => {
						// Anonymous user scenario
						if (!isLoggedIn) {
							this.authService.startAuthenticationAsync(state.url);
							resolve(false);
						}

						// Note: Need to reference local storage due to how the OIDC observable is handled and how that impacts the userInfo observable.
						let localUserInfo = this.userInfoService.getUserInfoFromStorage();

						// Incomplete security information scenario
						if (!(localUserInfo.primaryEmail || localUserInfo.primaryPhone)) {
							if (state.url != LandingRoutes.ContactMethodRequired) {
								state.url = LandingRoutes.ContactMethodRequired;
								this.redirectToContactMethodRequired();
								resolve(false);
							}
						}

						let uniqueContactInfoValidated = this.userInfoService.getContactUnique();

						// Check if user contact information is unique if we haven't checked already
						if (uniqueContactInfoValidated === 'false') {
							// Check if client allows duplicate emails/phone numbers
							this.clientService.getClientSettings(localUserInfo.clientId).subscribe((clientSettings) => {
								// If duplicate email/phone are allowed, don't check for
								if (clientSettings.allowDuplicateEmails) {
									resolve(true);
								} else {
									this.contactUpdate().subscribe((result) => {
										if (result === false) {
											this.redirectToUpdateContactInformation(); // Contact information not unique, route to update contact page
											resolve(false);
										} else {
											resolve(true); // Contact information is unique, authorize user
										}
									});
								}
							});
						} else {
							resolve(true); // user has valid contact info and is logged in
						}
					})
				)
				.subscribe(
					(result) => {},
					() => {
						resolve(true); // error
					}
				),
				() => {
					resolve(true); // completed
				};
		});
		return userValidated;
	}

	private contactUpdate(): Observable<boolean> {
		let emailCompany: string;
		let emailPersonal: string;
		let phoneNumber: string;

		return this.employeeService.getUserProfileData().pipe(
			// Check if Company Email is unique
			mergeMap((result) => {
				emailCompany =
					result.emails.find((value) => value.emailTypeId === 'Company') &&
					result.emails.find((value) => value.emailTypeId === 'Company').emailAddress;

				emailPersonal =
					result.emails.find((value) => value.emailTypeId === 'Personal') &&
					result.emails.find((value) => value.emailTypeId === 'Personal').emailAddress;

				phoneNumber = result.phoneNumber;

				return this.employeeService.isEmailUnique(emailCompany);
			}),

			// Check if Personal Email is unique
			mergeMap((result) => {
				if (result === true) {
					return this.employeeService.isEmailUnique(emailPersonal);
				}
				return of(false);
			}),

			// Check if Phone Number is unique
			mergeMap((result) => {
				if (result === true) {
					return this.securityService.isPhoneNumberUnique(phoneNumber);
				}
				return of(false);
			}),

			// Return result and set local storage flag
			mergeMap((result) => {
				this.userInfoService.setContactUnique('true');
				return of(result);
			})
		);
	}
}
