import { Injectable } from '@angular/core';
import { UserFeatureListDto } from '../models/user-feature-list-dto';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseApiClient } from './base-api-client.service';
import { LogService } from './log.service';
import { Environment } from '@env/environment';
import { LoadingIndicatorService } from './loading-indicator.service';
import { KpaReturnResult } from '../models/kpa-return-result';

export interface IUserFeaturesService {
	url: string;

	getMyFeatures(): Observable<KpaReturnResult<UserFeatureListDto>>;
}

@Injectable({
	providedIn: 'root',
})
export class UserFeaturesService extends BaseApiClient implements IUserFeaturesService {
	constructor(
		private readonly http: HttpClient,
		private readonly log: LogService,
		private readonly loadingIndicatorService: LoadingIndicatorService,
		environment: Environment
	) {
		super();
		this.url = environment.services['features'];
	}

	url: string;
	getMyFeatures(): Observable<KpaReturnResult<UserFeatureListDto>> {
		const url = `${this.url}features/GetMyFeatures`;

		this.loadingIndicatorService.startLoading();

		return this.http.get<KpaReturnResult<UserFeatureListDto>>(url).pipe(
			tap((): void => {
				this.log.logInformation(`fetched User Features`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}
}
