import { Directive, ElementRef, HostListener, TemplateRef, ViewContainerRef, Input, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
	selector: '[appFloatingDropDownlabel]',
})
export class FloatingDropDownlabelDirective {
	constructor(private element: ElementRef) {}

	ngAfterViewChecked() {
		this.valueChange(this.element.nativeElement.innerText);
	}

	// structural directives: https://blog.avenuecode.com/how-to-customize-your-angular-app-using-directives

	private readonly cssDirtyClass = 'dd-dirty';
	private readonly cssFocusClass = 'dd-focus';
	private readonly cssLabelClass = 'dd-label';
	private readonly cssDdlBorderClass = 'dd-border';
	private readonly cssFormFieldClass = 'k-form-field';

	//TODO: Need to figure out how to trigger data bindings

	private getParentClassList(): DOMTokenList {
		// Legacy logic to get element by ID; now done using nativeElement access
		const id = this.getId();
		const el: HTMLElement = id ? document.getElementById(id) : (this.element.nativeElement as HTMLElement);
		let parent = el.parentElement;

		if (!id) while (!parent.classList.contains(this.cssFormFieldClass) && parent.parentElement) parent = el.parentElement;
		else parent = parent.parentElement; // Legacy logic; I don't know why

		return parent && parent.classList;
	}

	private getId(): string {
		return this.element.nativeElement.id;
	}

	public focus(): void {
		this.getParentClassList().add(this.cssFocusClass);
	}

	public blur(): void {
		this.getParentClassList().remove(this.cssFocusClass);
	}

	public valueChange(value: any): void {
		if (value) {
			this.getParentClassList().add(this.cssDirtyClass);
		} else {
			this.getParentClassList().remove(this.cssDirtyClass);
		}
	}

	@HostListener('focus') onfocus() {
		this.focus();
	}

	@HostListener('blur') onblur() {
		this.blur();
	}

	@HostListener('selectionChange', ['$event']) onselectionchange(event: any) {
		this.valueChange(event.value);
	}
}
