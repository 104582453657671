import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})

// Shows or hides kpa spinner based on active requests
// Should be injected into services that require loading on the UI
// Should only be injected into components when custom collection odata functions are used
export class LoadingIndicatorService {
	private requestCount: number = 0;
	onLoadingChanged: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

	// Notifies observers for live requests
	startLoading(customLog?: string) {
		if (customLog) {
			console.log(`LoadingIndicator.startLoading: ${customLog}`);
		}
		this.requestCount++;
		this.onLoadingChanged.next(true);
	}

	// Notifies observers when all requests have finished
	stopLoading(customLog?: string) {
		if (customLog) {
			console.log(`LoadingIndicator.stopLoading: ${customLog}`);
		}
		this.requestCount = this.requestCount > 0 ? this.requestCount - 1 : this.requestCount;
		if (this.requestCount === 0) {
			this.onLoadingChanged.next(false);
		}
	}

	constructor() {}
}
