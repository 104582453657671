import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-kpa-inside-spinner',
	templateUrl: './kpa-inside-spinner.component.html',
	styleUrls: ['./kpa-inside-spinner.component.css'],
})
export class KpaInsideSpinnerComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
