import { NextObserver } from 'rxjs';
import { Event, NavigationStart, NavigationCancel, NavigationError, NavigationEnd } from '@angular/router';
import { ApplicationInsights, IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import { LogService } from './core/services/log.service';

export class AppRoutingObserver implements NextObserver<Event> {
	closed: boolean;

	private viewDictionary: { [id: number]: Date } = {};

	next(value: Event): void {
		if (value instanceof NavigationStart) {
			this.handleNavigationStart(value);
		}
		if (value instanceof NavigationCancel) {
			this.handleNavigationCancel(value);
		}
		if (value instanceof NavigationError) {
			this.handleNavigationError(value);
		}
		if (value instanceof NavigationEnd) {
			this.handleNavigationEnd(value);
		}
	}

	private handleNavigationStart(evt: NavigationStart): void {
		this.viewDictionary[evt.id] = new Date();
	}

	private handleNavigationCancel(evt: NavigationCancel): void {
		this.handleNavigationAbort(evt, evt.reason);
	}

	private handleNavigationError(evt: NavigationError): void {
		this.handleNavigationAbort(evt, evt.error.toString());
	}

	private handleNavigationAbort(evt: NavigationCancel | NavigationError, reason: string): void {
		const viewStart = this.viewDictionary[evt.id];
		const urlNormalize = this.normalizeUrl(evt.url);
		const urlLocationNormalize = this.normalizeUrl(location.origin + evt.url);

		const pageViewTelemetry: IPageViewTelemetry = {
			name: urlNormalize,
			uri: urlLocationNormalize,
			properties: {
				duration: Date.now() - viewStart.valueOf(),
				canceled: 'true',
				reason: reason,
			},
		};

		LogService.AppInsights.trackPageView(pageViewTelemetry);

		delete this.viewDictionary[evt.id];
	}

	private handleNavigationEnd(evt: NavigationEnd): void {
		const viewStart = this.viewDictionary[evt.id];
		if (viewStart) {
			const urlNormalize = this.normalizeUrl(evt.url);
			const urlLocationNormalize = this.normalizeUrl(location.origin + evt.url);
			const pageViewTelemetry: IPageViewTelemetry = {
				name: urlNormalize,
				uri: urlLocationNormalize,
				properties: {
					duration: Date.now() - viewStart.valueOf(),
				},
			};

			LogService.AppInsights.trackPageView(pageViewTelemetry);

			delete this.viewDictionary[evt.id];
		}
	}

	private normalizeUrl(url: string): string {
		const hashIdx = url.indexOf('#');
		if (hashIdx >= 0) {
			url = url.substring(0, hashIdx);
		}
		return url;
	}

	error(err: Error): void {}

	complete(): void {}
}
