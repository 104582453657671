import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { switchMap, take, withLatestFrom, catchError } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { AuthService } from './auth.service';
import { Environment } from '@env/environment';

export class HttpOperationErrorResponse extends HttpErrorResponse {
	constructor(
		original: HttpErrorResponse,
		public readonly operationId: string
	) {
		super({
			error: original.error,
			headers: original.headers,
			status: original.status,
			statusText: original.statusText,
			url: original.url,
		});
	}
}

/**
 * Intercepts HttpRequests and injects Authorization request header when applicable.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private readonly serviceUrls: string[] = [];
	private readonly authorizationHeaderKey = 'Authorization';
	private readonly originatorAuthorizationHeaderKey = 'Authorization-Originator';

	private authHeaders = {};
	private setHeaders = false;
	private ableAPIBaseUrl: string;

	constructor(
		private readonly authService: AuthService,
		private readonly environment: Environment
	) {
		// Save all of the service URLs in a local variable
		Object.keys(this.environment.services).forEach((key) => this.serviceUrls.push(this.environment.services[key]));
		this.ableAPIBaseUrl = environment.services['ableAPI'];
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Set operation ID for tracing where possible
		req = this.setTraceContext(req);

		//token for complynet api is attached in service.
		//may change how this works in future but for now this works
		if (req.url.includes(this.ableAPIBaseUrl)) {
			return next.handle(req);
		}
		// If this is a service URL, add the Authorization Headers.
		// Otherwise, simply return the next Interceptor operation.
		let obs = this.serviceUrls.filter((url) => req.url.startsWith(url)).length
			? this.authService.authorizationHeader.pipe(
					// Order of operations is important, need header to complete before take(1), otherwise it will be in an uncompletable state
					withLatestFrom(this.authService.authorizationOriginatorHeader),
					take(1),
					switchMap(([authorizationHeader, originatorAuthorizationHeader]) => {
						// Add regular Authorization if possible
						this.setAuthorizationHeader(this.authorizationHeaderKey, authorizationHeader);

						// Add Originator Authorization if possible
						this.setAuthorizationHeader(this.originatorAuthorizationHeaderKey, originatorAuthorizationHeader);

						// Add the Authorization Headers to the Request if necessary
						if (this.setHeaders) {
							req = req.clone({ setHeaders: this.authHeaders });
						}

						return next.handle(req);
					})
				)
			: next.handle(req);

		// Add operation ID to any error objects
		return this.augmentErrorHandling(obs, req.headers.get('traceparent'));
	}

	/**
	 * Sets an operation ID according to the W3C Proposed Trace Context Recommendation.
	 * @param request The outgoing request object.
	 * @see https://www.w3.org/TR/trace-context-1/
	 */
	private setTraceContext(request: HttpRequest<any>): HttpRequest<any> {
		var operationId = uuid().replace(/-/g, '');
		var parentSpan = uuid().replace(/-/g, '').substring(16);
		return request.clone({ setHeaders: { traceparent: `00-${operationId}-${parentSpan}-01` } });
	}

	/**
	 * Adds an Authorization header to the member variable.
	 * @param key The key for the auth header.
	 * @param value The value for the auth header.
	 */
	private setAuthorizationHeader(key: string, value: string) {
		// If the value is valid, add it to the member variable and
		// set the flag
		if (value) {
			this.authHeaders[key] = value;
			this.setHeaders = true;
		}
	}

	/**
	 * Adds the operation ID to error objects from processing the request.
	 * @param request The request observable.
	 * @param traceCtx The trace context header value.
	 */
	private augmentErrorHandling(request: Observable<HttpEvent<any>>, traceCtx: string): Observable<HttpEvent<any>> {
		const operationId = traceCtx.substring(3, 35);
		return request.pipe(catchError((error: HttpErrorResponse) => throwError(new HttpOperationErrorResponse(error, operationId))));
	}
}
