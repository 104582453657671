<app-kpa-spinner></app-kpa-spinner>
<h1>Support</h1>

<kpa-error-card
	mainTitle="Error"
	*ngIf="saveTicketFailed">
	Something went wrong. Please call support
</kpa-error-card>
<kpa-status-card
	state="success"
	mainTitle="Success"
	*ngIf="saveTicketSucceeded">
	Ticket created
</kpa-status-card>
<form
	[formGroup]="supportForm"
	fxLayout="column"
	fxLayoutAlign="stretch"
	class="kpa-formWrap"
	(keydown.enter)="$event.preventDefault()"
	(ngSubmit)="onSubmit()">
	<div fxLayout="column">
		<div
			class="card"
			fxLayout="column">
			<h3>Contact Us</h3>
			<p>Call, email, or submit a support ticket. We're here to help!</p>
			<div class="contact-text">(800) 486-0400</div>
			<div class="contact-text">support@kpa.io</div>

			<h3>Submit a Support Ticket</h3>
			<p>
				Provide the following information and select Submit to create a support ticket. We'll contact you at the phone number or email
				provided to assist you with your issue.
			</p>
			<div
				fxLayout="row"
				fxLayoutGap="60px grid"
				fxAlign="center">
				<div
					fxLayout="column"
					fxFlex="1 1 33.3%">
					<input
						type="hidden"
						name="name" />
					<input
						type="hidden"
						name="company" />
					<div>
						<kendo-floatinglabel
							id="inpText_Email_Label"
							text="Email">
							<input
								kendoTextBox
								id="inpText_Email"
								formControlName="email" />
						</kendo-floatinglabel>
					</div>
					<div>
						<validation-errors
							id="inpText_Email_ValidationErrors"
							[control]="supportForm">
						</validation-errors>
					</div>
					<div>
						<kpa-phone-box
							#inpText_Phone
							id="inpText_Phone_Label"
							formControlName="phone"
							label="Phone"></kpa-phone-box>
					</div>
					<div>
						<validation-errors
							id="inpText_Phone_ValidationErrors"
							[control]="supportForm">
						</validation-errors>
					</div>
					<div>
						<kendo-floatinglabel
							id="inpText_Subject_Label"
							text="Subject">
							<input
								kendoTextBox
								id="inpText_Subject"
								formControlName="subject"
								maxlength="255" />
						</kendo-floatinglabel>
					</div>
					<div>
						<validation-errors
							id="inpText_Subject_ValidationErrors"
							[control]="supportForm.get('subject')">
						</validation-errors>
					</div>
				</div>
			</div>

			<div>
				<kendo-floatinglabel
					id="inpText_Description_Label"
					text="Description"
					class="description-textbox">
					<textarea
						id="inpText_Description"
						kendoTextArea
						formControlName="description"
						maxlength="2250"
						[autoSize]="true"></textarea>
				</kendo-floatinglabel>
				<div class="character-count-text">{{ descriptionLength | async }}/2250</div>
			</div>
			<div>
				<validation-errors
					id="inpText_Description_ValidationErrors"
					[control]="supportForm.get('description')">
				</validation-errors>
			</div>
		</div>
	</div>

	<div
		fxLayout="row"
		fxLayoutGap="20px"
		fxLayoutAlign="end center"
		class="margin-bottom: 40px;">
		<div fxLayout="column">
			<button
				kendoButton
				kpaPrimaryButton
				id="btn__Prompt_Submit"
				class="standAloneBtn"
				type="submit"
				[disabled]="saveInitiated">
				Submit Ticket
			</button>
		</div>
	</div>
</form>
