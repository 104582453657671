import { Directive, ElementRef, HostListener, Input, Optional, Self } from '@angular/core';
import { TimePickerComponent } from '@progress/kendo-angular-dateinputs';

@Directive({
	selector: '[appFloatingTimePicker]',
})
export class FloatingTimePickerDirective {
	@Input() public initialTime: string;

	constructor(
		private readonly element: ElementRef,
		// Declared optional in case this is currently bound to a different kind of component anywhere
		@Optional() @Self() private readonly timePicker: TimePickerComponent
	) {}

	private readonly cssDirtyClass = 'dp-dirty';
	private readonly cssFocusClass = 'dp-focus';
	private readonly cssFormFieldClass = 'k-form-field';

	ngOnInit() {
		// Legacy logic to manually specify binding value
		if (this.initialTime) {
			this.valueChange(this.initialTime);
		}
		// Updated logic
		else if (this.timePicker) {
			this.valueChange(this.timePicker.value);
		}
	}

	ngAfterViewChecked() {
		this.valueChange(this.element.nativeElement.innerText);
	}
	private getParentClassList(): DOMTokenList {
		// Legacy logic to get element by ID; now done using nativeElement access
		const id = this.getId();
		const el: HTMLElement = id ? document.getElementById(id) : (this.element.nativeElement as HTMLElement);
		let parent = el.parentElement;

		if (!id) while (!parent.classList.contains(this.cssFormFieldClass) && parent.parentElement) parent = parent.parentElement;
		else parent = parent.parentElement.parentElement; // Legacy logic; I don't know why

		return parent && parent.classList;
	}

	private getId(): string {
		return this.element.nativeElement.id;
	}

	public focus(): void {
		this.getParentClassList().add(this.cssFocusClass);
	}

	public blur(): void {
		this.getParentClassList().remove(this.cssFocusClass);
	}

	public valueChange(value: any): void {
		if (value) {
			this.getParentClassList().add(this.cssDirtyClass);
		}
		if (value === null) {
			this.getParentClassList().remove(this.cssDirtyClass);
		}
	}

	@HostListener('focus') onfocus() {
		this.focus();
	}

	@HostListener('blur') onblur() {
		this.blur();
	}

	@HostListener('valueChange', ['$event']) onChange(event: any) {
		this.valueChange(event);
	}
}
