import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { LogService } from '@app/core/services/log.service';
import { Environment } from '../../../../environments/environment';
import { BaseApiClient } from '@app/core/services/base-api-client.service';
import { ClientDto } from '../models/clientDto';
import { UntypedFormGroup } from '@angular/forms';
import { LoadingIndicatorService } from '@app/core/services/loading-indicator.service';
import { HiringThingDto } from '../models/hiringThingDto';
import { ClientSettingDto } from '../models/clientSettingDto';
const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export interface IClientService {
	url: string;
	get(id: string): Observable<ClientDto>;
	save(formGroup: UntypedFormGroup): Observable<{ id: string }>;
	pushToHrDrive(formGroup: UntypedFormGroup): Observable<{ id: string }>;
}

@Injectable({
	providedIn: 'root',
})
export class ClientService extends BaseApiClient implements IClientService {
	/**
	 * The base URL for the API domain.
	 */
	public baseUrl: string;

	/**
	 * The URL to the API endpoint.
	 */
	public url: string;

	constructor(
		private readonly http: HttpClient,
		private readonly log: LogService,
		private readonly loadingIndicatorService: LoadingIndicatorService,
		environment: Environment
	) {
		super();
		this.baseUrl = environment.services['clients'];
		this.url = this.baseUrl + 'client';
	}

	/**
	 * Retrieves the entity
	 * @param id
	 */
	get(id: string): Observable<ClientDto> {
		const url = `${this.url}/${id}`;
		this.log.logInformation('Client Get with id:' + id);

		this.loadingIndicatorService.startLoading();
		return this.http.get<ClientDto>(url).pipe(
			tap((): void => {
				this.log.logInformation(`fetched Client id=${id}`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	/**
	 * Updates or creates an entity
	 * @param entity
	 */
	save(clientForm: UntypedFormGroup): Observable<any> {
		const entity = clientForm.value;
		const errorHandler = this.createErrorResponseHandler(this.log, clientForm);
		if (!entity.id) {
			return this.http.post(this.url, entity, httpOptions).pipe(
				tap((id) => this.log.logInformation(`created entity id=${id}`)),
				catchError(errorHandler)
			);
		} else {
			return this.http.put<any>(this.url + '/' + entity.id, entity, httpOptions).pipe(
				tap((id) => this.log.logInformation(`update entity id=${id}`)),
				catchError(errorHandler)
			);
		}
	}

	/**
	 * Push to HrDrive
	 * @param entity
	 */
	pushToHrDrive(clientForm: UntypedFormGroup): Observable<any> {
		const entity = clientForm.value;
		const errorHandler = this.createErrorResponseHandler(this.log, clientForm);
		if (entity.id) {
			const url = `${this.url}/${entity.id}/PushToHrDrive`;

			return this.http.post(url, httpOptions).pipe(
				tap((id) => this.log.logInformation(`integrated entity id=${id}`)),
				catchError(errorHandler)
			);
		}
	}

	/**
	 * Get HiringThingDto on logged in user clientId
	 */
	getHasHiringThing(clientId: string): Observable<any> {
		const errorHandler = this.createErrorResponseHandler(this.log);
		if (clientId) {
			const url = `${this.baseUrl}onboarding/${clientId}`;

			return this.http.get(url, httpOptions).pipe(
				tap((hiringThingDto: HiringThingDto) => this.log.logInformation(`Retrieved hiring thing on clientId=${hiringThingDto.clientId}`)),
				map((hiringThingDto: HiringThingDto) => hiringThingDto.hasHiringThing),
				catchError(errorHandler)
			);
		}
	}

	/**
	 * Get client settings
	 */
	getClientSettings(clientId: string): Observable<any> {
		const errorHandler = this.createErrorResponseHandler(this.log);
		if (clientId) {
			const url = `${this.baseUrl}client/${clientId}/settings`;

			return this.http.get(url, httpOptions).pipe(
				tap((clientSettingsDto: ClientSettingDto) => this.log.logInformation(`retrieved client settings for id=${clientId}`)),
				catchError(errorHandler)
			);
		}
	}
}
