import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseApiClient } from '@app/core/services/base-api-client.service';
import { LogService } from '@app/core/services/log.service';
import { Environment } from '@env/environment';
import { catchError, tap, map } from 'rxjs/operators';
import { ContactDto } from '../models/contactsDto';
import { LoadingIndicatorService } from '@app/core/services/loading-indicator.service';
import { Guid } from '@app/shared/models/guid';

const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

/**
 * Interface for ContactService
 */
export interface IContactService {
	url: string;
	get(userId: Guid): Observable<ContactDto>;
}

@Injectable({
	providedIn: 'root',
})
export class ContactsService extends BaseApiClient {
	url: string;
	public baseUrl: string;

	constructor(
		private readonly http: HttpClient,
		private readonly log: LogService,
		private readonly loadingIndicatorService: LoadingIndicatorService,
		environment: Environment
	) {
		super();
		this.baseUrl = environment.services['employees'];
		this.url = this.baseUrl + 'contact';
	}

	getContacts(ssoUserId: string): Observable<any> {
		const url = `${this.url}/${ssoUserId}`;

		this.loadingIndicatorService.startLoading();
		return this.http.get(url, httpOptions).pipe(
			tap((): void => {
				this.log.logInformation(`fetched contacts for ssoUserId with id=${ssoUserId}`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}
}
