import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, concatMap } from 'rxjs/operators';
import { UserInfoService } from '@app/core/services/user-info.service';
import { LogService } from './log.service';
import { LandingGuardService } from './landing-guard.service';

@Injectable({
	providedIn: 'root',
})
export class DashboardGuardService implements CanActivate {
	constructor(
		private readonly userInfoService: UserInfoService,
		private readonly landingGuardService: LandingGuardService,
		private log: LogService
	) {}

	public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.userInfoService.hasInternalRole.pipe(
			switchMap((hasInternalRole) => {
				//if the user is an internal user, don't allow them to go to dashboard; use the landing page logic
				if (hasInternalRole) {
					this.log.logInformation('User is an internal user; using landing page logic.');
					return this.landingGuardService.canActivate(_, state);
				} else {
					this.log.logInformation("User is not an internal user; continuing on to 'dashboard'.");
					return of(true);
				}
			})
		);
	}
}
