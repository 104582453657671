import { Component, EventEmitter, Input, forwardRef, Output, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { APP_BASE_HREF } from '@angular/common';
import { BaseComponent } from '@app/shared/base-component';
import { LogService } from '@app/core/services/log.service';
@Component({
	selector: 'kpa-phone-box',
	templateUrl: './phone-box.component.html',
	styleUrls: ['./phone-box.component.css'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PhoneBoxComponent),
			multi: true,
		},
		{ provide: APP_BASE_HREF, useValue: '/' },
	],
})
export class PhoneBoxComponent extends BaseComponent implements ControlValueAccessor {
	constructor(protected readonly log: LogService) {
		super(log);
	}

	@Input()
	disabled: boolean = false;

	@Input()
	label: string = 'Cell Phone';

	@Output()
	value = () => this.rawValue;

	@Output()
	onBlurred: EventEmitter<string> = new EventEmitter();

	@Input()
	public formattedValue: string;
	private rawValue: string;

	@ViewChild('inpText_PhoneBox_Label', { read: ElementRef, static: false }) phoneLabel: ElementRef;

	/// Function handler for propagating changes
	propagateChange = (_: any) => {};

	/// Take the FormControl value and update the rawValue and formattedValue
	writeValue(obj: any): void {
		this.rawValue = this.stripNonNumbers(obj);
		this.formattedValue = this.addFormatting(this.rawValue);
		if (this.rawValue != null && this.rawValue != '') {
			this.phoneLabel?.nativeElement?.classList?.remove('k-empty');
		}
	}

	/// Takes a reference to a function that Angular uses to update forms/other controls
	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	/// Function reference required for ControlValueAccessor
	registerOnTouched(fn: any): void {}

	/// Disable/Enable the input
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	/// Update the rawValue and formattedValues
	changeValues(value: string) {
		this.rawValue = this.stripNonNumbers(value);
		this.formattedValue = this.addFormatting(this.rawValue);

		this.propagateChange(this.rawValue);
	}

	/// Format the phone number when the values are changed
	onChangedValues(event: any) {
		let value = event.srcElement.value;
		this.changeValues(value);
	}

	/// Format the string to match '000 - 000 - 0000'
	addFormatting(str: string): string {
		let val = this.stripNonNumbers(str);

		if (val) {
			if (val.length == 10) {
				var match = val.match(/^(\d{3})(\d{3})(\d{4})$/);
				if (match) {
					return match[1] + ' - ' + match[2] + ' - ' + match[3];
				}
			}

			return val;
		}

		return null;
	}

	/// Remove all characters that aren't in 0..9
	stripNonNumbers(str: string): string {
		return ('' + str).replace(/\D/g, '');
	}

	/// When this component is focused, remove the formatting
	onFocus() {
		this.formattedValue = this.rawValue;
	}

	/// When this component is blurred, add the formatting back in
	onBlur() {
		this.formattedValue = this.addFormatting(this.rawValue);
		this.onBlurred.emit(this.rawValue);
	}

	/// Handle input event to restrict non-numeric characters
	onInput(event: any) {
		let input = event.target;
		input.value = this.stripNonNumbers(input.value);
		this.formattedValue = input.value;
	}
}
