import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FilterDescriptor, CompositeFilterDescriptor, isCompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, BaseFilterCellComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import { DropDownFilterSettings, MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { JsonHubProtocol } from '@aspnet/signalr';

@Component({
	selector: 'kpa-multiselect-search-filter',
	templateUrl: './kpa-multiselect-search-filter.html',
	styleUrls: ['./kpa-multiselect-search-filter.css'],
	encapsulation: ViewEncapsulation.None,
})
export class KpaMultiselectSearchFilter extends BaseFilterCellComponent implements OnDestroy, OnInit {
	@ViewChild('multiselect')
	public multiselect: MultiSelectComponent;

	/**
	 * This function flattens if filters has inner queries
	 * ex from this{logic: 'or', filters: Array(5)}
	 * @param filter
	 * @returns
	 */
	flatten = (filter) => {
		const filters = (filter || {}).filters;
		if (filters) {
			return filters.reduce((acc, curr) => acc.concat(curr.filters ? this.flatten(curr) : [curr]), []);
		}
		return [];
	};

	public filterSettings: DropDownFilterSettings = {
		caseSensitive: false,
		operator: 'contains',
	};

	private valueFilter: any[] = [];

	/**
	 * Holds new filter, used while user is selecting values
	 */
	public tempCompositeFilter: CompositeFilterDescriptor;

	/**
	 * Holds copy of previous tempCompositeFilter, so don't fire off if nothing changed
	 */
	public preValueChange: any[] = [];

	public valuesAfterValueChange: any[] = [];

	/**
	 * Filter from Grid, holds array of filters
	 * for each column
	 */
	@Input() public filter: CompositeFilterDescriptor;

	/**
	 * Data from parent control for selection list
	 */
	@Input() public data: any[];

	/** set text field on data array
	 * for example of Type has label and Id properties
	 * textField="label" and valueField="Id"
	 */
	@Input() public textField: string;

	/** set value field on data array
	 * for example of Type has label and Id properties
	 * textField="label" and valueField="Id"
	 */
	@Input() public valueField: string;

	/**
	 * The column with which the filter is associated.
	 * @type {ColumnComponent}
	 */
	@Input()
	public column: ColumnComponent;

	/** add selected ids here if you want to preselect some
	 */
	@Input() public selectedIds: any[];

	/**
	 * Event fired when the user types in a value to filter the data by
	 * so parent can apply changes to data to select from
	 */
	@Output() filterChangeSearch = new EventEmitter<any>();

	constructor(filterService: FilterService) {
		super(filterService);

		//set up subscription to wait for debounceTime to emit afterFilterChanged event
		this.subscriptionDebounce = this.streamFilterChange
			.pipe(debounceTime(500)) //hardcoding to 500ms, adjust later to input
			.subscribe((value: any) => {
				const makeEvent: any = { search: value, selectedValues: this.valueFilter };
				this.filterChangeSearch.next(makeEvent);
			});
	}
	/**
	 * Used to determine if an item has been selected
	 * Usually used to set the check box
	 */
	public isItemSelected(itemText: string): boolean {
		const selected = this.valueFilter;
		const selectedItems = selected.map((sel) => this.data.find((d) => d[this.valueField] === sel));
		const isSelected = selectedItems.some((item) => item.textField === itemText);
		return isSelected;
	}

	ngOnInit(): void {
		//make copy of filter for doing no change check
	}

	ngOnDestroy(): void {
		// unsubscribe the debounce subscription on destroy of component
		this.subscriptionDebounce.unsubscribe();
	}

	/**
	 * Capture the filterChange Event
	 * @param $event
	 */
	public filterChange($event: any) {
		//send filterChange to stream so can do debounce
		this.streamFilterChange.next($event);
	}

	/**
	 * This function gets called when users check values or clear values
	 * we clean up the filters object and
	 * re-create it with the updated ones
	 * ex:{field: 'PersonAssignedSsoUserId', operator: 'eq', value: 'abb99ba7-b0fb-4a7c-9a38-444af24723d0', ignoreCase: true}
	 * @param values
	 */
	public valueChange(values: any[]): void {
		console.log('valuechange' + values);

		// If is a pop up close and the filters were cleared by
		// clicking on the x, we need to trigger a grid refresh
		// and bring all the values back for the Assigned To column
		if (values.length === 0) {
			this.closed();
		}

		this.removeFilter(this.column.field);
		//hold new values for check later to fire filter change
		this.valuesAfterValueChange = Object.assign([], values);

		//hold previous filters
		const tempFilters = this.filter.filters;

		this.tempCompositeFilter = this.filter;

		//add new values to filter with or in composite
		if (values.length > 0) {
			this.tempCompositeFilter.filters = values.map((value) => ({
				field: this.column.field,
				operator: 'eq',
				value,
			}));
		}

		this.tempCompositeFilter.logic = 'or';

		//add previous filters to new list of filters
		tempFilters.forEach((t) => this.tempCompositeFilter.filters.push(t));

		//not firing off the filterService here as, the user may not be done selecting
	}

	/**
	 * Close event for the pop-up closing indicating user is done selecting
	 * we can fire off the filter event here.
	 */
	closed() {
		//compare to previous
		if (JSON.stringify(this.preValueChange) == JSON.stringify(this.valuesAfterValueChange)) {
			return; //same so do nothing
		}
		//set up for next time compare
		this.preValueChange = Object.assign([], this.valuesAfterValueChange);

		console.log('closed-fire filter event');
		this.filterService.filter(this.tempCompositeFilter);
		//set up so can compare on next change
	}

	/**
	 * Gets current filters on Grid based on filterField
	 * @param filter
	 * @returns
	 */
	getFilters(filter: CompositeFilterDescriptor | FilterDescriptor) {
		if (filter) {
			const filterComp = filter as CompositeFilterDescriptor;
			//need to walk the levels
			if (filterComp.filters) {
				const filters = [];
				for (let i = 0; i < filterComp.filters.length; i++) {
					if (isCompositeFilterDescriptor(filterComp.filters[i])) {
						const outerCompositeFilter = filterComp.filters[i] as CompositeFilterDescriptor;
						for (let j = 0; j < outerCompositeFilter.filters.length; j++) {
							if (outerCompositeFilter.filters[j]) {
								const filterVal = outerCompositeFilter.filters[j] as FilterDescriptor;
								//if field same, we found a filter
								if (filterVal.field === this.column.field) {
									filters.push(filterVal);
								}
							}
						}
					} else {
						// this is the filter
						const filterValue = filterComp.filters[i] as FilterDescriptor;
						if (filterValue.field === this.column.field) {
							filters.push(filterValue);
						}
					}
				}

				return filters;
			} else {
				return filterComp;
			}
		}
	}

	/**
	 * values of selected items from constructed filters it gets list of ids
	 * [  "abb99ba7-b0fb-4a7c-9a38-444af24723d0",  "69238dd6-d5b6-4607-a65a-871eacbc0296"]
	 * @param filter
	 * @returns
	 */
	public valueFilters(filter: CompositeFilterDescriptor): FilterDescriptor[] {
		if (filter) {
			//get the filters for the field
			const filterDrpd = this.getFilters(filter);

			const filterComp = {
				logic: 'or',
				filters: filterDrpd,
			};

			this.valueFilter.splice(0, this.valueFilter.length, ...this.flatten(filterComp).map(({ value }) => value));

			return this.valueFilter;
		} else return [];
	}

	/**
	 * used to catch filterChange events as a stream
	 */
	private streamFilterChange: Subject<any> = new Subject<any>();

	/**
	 * subscription listening in on streamValueChange
	 */
	private subscriptionDebounce: Subscription;
}
