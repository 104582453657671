import { Component, Input } from '@angular/core';
import { FilterDescriptor, CompositeFilterDescriptor, isCompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';

const flatten = (filter) => {
	const filters = (filter || {}).filters;
	if (filters) {
		return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
	}
	return [];
};

@Component({
	selector: 'my-dropdown-filter',
	template: `
		<kendo-multiselect
			[style]="minWidth"
			[data]="data"
			[textField]="textField"
			[valueField]="valueField"
			[valuePrimitive]="true"
			[value]="valueFilters(filter)"
			[autoClose]="false"
			kendoMultiSelectSummaryTag
			(valueChange)="valueChange($event)">
			<ng-template
				kendoMultiSelectItemTemplate
				let-dataItem>
				<input
					type="checkbox"
					kendoCheckBox
					[checked]="isItemSelected(dataItem.textField)" />
				<label class="k-checkbox-label">
					{{ dataItem.textField }}
				</label>
			</ng-template>
			<ng-template
				kendoMultiSelectGroupTagTemplate
				let-dataItems>
				<ng-container *ngIf="dataItems.length === 1; else groupedDisplay">
					{{ dataItems[0].textField }}
				</ng-container>
				<ng-template #groupedDisplay> {{ dataItems.length }} selected </ng-template>
			</ng-template>
		</kendo-multiselect>
	`,
})
export class DropDownListFilterComponent extends BaseFilterCellComponent {
	public Object = Object;

	public get selectedValue(): any {
		const filter = this.filterByField(this.valueField);
		return filter ? filter.value : null;
	}

	private valueFilter: any[] = [];

	@Input() public filter: CompositeFilterDescriptor;
	@Input() public data: any[];
	@Input() public textField: string;
	@Input() public valueField: string;
	@Input() public minWidth: string = 'min-width:220px';

	constructor(filterService: FilterService) {
		super(filterService);
	}

	public isItemSelected(itemText: string): boolean {
		const selected = this.valueFilter;
		const selectedItems = selected.map((sel) => this.data.find((d) => d[this.valueField] === sel));
		const isSelected = selectedItems.some((item) => item.textField === itemText);
		return isSelected;
	}

	public valueChange(values: any[]): void {
		this.removeFilter(this.valueField);
		const tempFilters = this.filter?.filters;
		const tempCompositeFilter: CompositeFilterDescriptor = this.filter;

		tempCompositeFilter.filters = values.map((value) => ({
			field: this.valueField,
			operator: 'eq',
			value,
		}));
		tempCompositeFilter.logic = 'or';
		tempFilters.forEach((t) => tempCompositeFilter.filters.push(t));

		this.filterService.filter(tempCompositeFilter);
	}

	getFilters(filter: CompositeFilterDescriptor | FilterDescriptor) {
		const filterComp = filter as CompositeFilterDescriptor;
		if (filterComp != null && filterComp.filters) {
			const filters = [];
			for (let i = 0; i < filterComp.filters.length; i++) {
				if (isCompositeFilterDescriptor(filterComp.filters[i])) {
					const outerCompositeFilter = filterComp.filters[i] as CompositeFilterDescriptor;
					for (let j = 0; j < outerCompositeFilter.filters.length; j++) {
						if (outerCompositeFilter.filters[j]) {
							const filterVal = outerCompositeFilter.filters[j] as FilterDescriptor;
							if (filterVal.field === this.valueField) {
								filters.push(filterVal);
							}
						}
					}
				} else {
					// this is the filter
					const filterValue = filterComp.filters[i] as FilterDescriptor;
					if (filterValue.field === this.valueField) {
						filters.push(filterValue);
					}
				}
			}
			return filters;
		} else {
			return filterComp;
		}
	}

	public valueFilters(filter: CompositeFilterDescriptor): FilterDescriptor[] {
		//get the filters for the field
		const filterDrpd = this.getFilters(filter);

		const filterComp = {
			logic: 'or',
			filters: filterDrpd,
		};

		this.valueFilter.splice(0, this.valueFilter.length, ...flatten(filterComp).map(({ value }) => value));

		return this.valueFilter;
	}
}
