import { Component, Input, OnInit } from '@angular/core';
import { ValidationErrors, AbstractControl } from '@angular/forms';

@Component({
	selector: 'validation-errors',
	templateUrl: './validation-errors.component.html',
	styleUrls: ['./validation-errors.component.css'],
})
export class ValidationErrorsComponent implements OnInit {
	@Input()
	public control: AbstractControl;
	public controlName: string;

	ngOnInit() {
		this.controlName = this.getControlName(this.control);
	}

	public get errors(): ValidationErrors {
		return this.control ? this.control.errors : null;
	}

	public get dirty(): boolean {
		return this.control && this.control.dirty;
	}

	getControlName(c: AbstractControl): string | null {
		if (c && c.parent && c.parent.controls) {
			const formGroup = c.parent.controls;
			return Object.keys(formGroup).find((name) => c === formGroup[name]) || null;
		}
	}
}
