import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Environment } from '@env/environment';

@Injectable({
	providedIn: 'root',
})
export class KpaComponentLibraryGuardService implements CanActivate {
	constructor(private environment: Environment) {}

	public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		//if this is a non prod environment, allow navigation
		//checking the baseDomain which is set by Octopus in the higher environments, and is hard coded in the local dev environment
		if (
			this.environment.baseDomain.platform == 'http://localhost:4200' ||
			this.environment.baseDomain.platform == 'https://platform.qa1.kpadev.com' ||
			this.environment.baseDomain.platform == 'https://platform.qa2.kpadev.com' ||
			this.environment.baseDomain.platform == 'https://platform.uat.kpadb.com' ||
			this.environment.baseDomain.platform == 'https://platform.uat2.kpadb.com' ||
			this.environment.baseDomain.platform == 'https://platform.uat3.kpadb.com'
		) {
			return of(true);
		} else {
			return of(false);
		}
	}
}
