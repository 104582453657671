import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'kpa-info-card',
	templateUrl: './kpa-info-card.component.html',
	styleUrls: ['./kpa-info-card.component.css'],
})
export class KpaInfoCardComponent implements OnInit {
	constructor() {}

	@Input()
	public mainTitle: string;

	ngOnInit() {}
}
