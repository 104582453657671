import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { from, forkJoin } from 'rxjs';
import { takeWhile, take } from 'rxjs/operators';
import { AuthService } from '@app/core/services/auth.service';
import { UserInfoService } from '@app/core/services/user-info.service';
import { HttpOperationErrorResponse } from '@app/core/services/auth-interceptor';
import { AuthRoutes } from './auth-routes';
import { LogService } from '@app/core/services/log.service';
import { Environment } from '@env/environment';
import { SecurityService } from '@app/core/services/security.service';

@Component({
	template: '',
})
export class AuthComponent implements OnInit {
	constructor(
		private readonly environment: Environment,
		private readonly authService: AuthService,
		private readonly userInfoService: UserInfoService,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly location: Location,
		private readonly log: LogService,
		private readonly securityService: SecurityService
	) {}

	ngOnInit() {
		const routePath = this.route.snapshot.url[0].path;

		this.log.logInformation('ngOnInit', { route: routePath });
		switch (routePath) {
			case AuthRoutes.AuthenticateCallback:
				this.subscribeToAuthenticationFork();
				break;
			case AuthRoutes.Logout:
				this.authService.logoutAsync(false);
				break;
			case AuthRoutes.LogoutCallback:
				// Notify frame of ongoing logout
				const baseAddress = /https:\/\/[^\/]+/.exec(this.environment.services['sso'])[0];
				if (parent) {
					parent.postMessage('kpa.cc.identity.client.externalsignout.loading', baseAddress);
				}
				break;
			case AuthRoutes.ImpersonateCallback:
				this.subscribeToAuthenticationFork(true);
				break;
			case AuthRoutes.StartImpersonation:
				const userId = this.route.snapshot.paramMap.get('userId');
				this.authService.startImpersonationAsync(userId, AuthRoutes.Landing);
				break;
			case AuthRoutes.EndImpersonation:
				this.authService.endImpersonationAsync(AuthRoutes.Landing);
				break;
			default:
				this.router.navigate([AuthRoutes.Landing]);
				break;
		}
	}

	/**
	 * Awaits completing authentication and fetching user info before redirecting the user.
	 * @param allowImpersonate True if impersonation is allowed; otherwise false.
	 */
	private subscribeToAuthenticationFork(allowImpersonate: boolean = false): void {
		// Await the completion of both observables (complete for user info is defined as a non-null value)
		const fork$ = forkJoin(
			from(this.authService.completeAuthenticationAsync(allowImpersonate)),
			this.userInfoService.userInfo.pipe(takeWhile((info) => info == null))
		);

		// Intended unmanaged subscription; completion guaranteed
		fork$.subscribe(
			(next) => {
				// Redirect to callback URL from completing auth
				this.router.navigateByUrl(next[0]);
			},
			async (error: Error) => {
				if (error instanceof HttpOperationErrorResponse) {
					// Calculate Go Back URL for error page
					// Cannot redirect to auth-callback as the login request state has already been consumed in user manager
					let backUrl = '/landing';
					if (allowImpersonate) {
						const user = await this.authService.user.pipe(take(1)).toPromise();
						backUrl = `/${AuthRoutes.StartImpersonation}/${user.profile.sub}`;
					}

					// Clear erroring user info
					await this.authService.logoutAsync(true);

					// Replace the location on top of the stack with a new target for "Go Back"
					this.location.replaceState(backUrl);

					// Fetch operation ID from error context and display friendly error page
					// This is scheduled for the next frame to allow replacing location state to complete
					setTimeout(() => this.router.navigate([`/error/${error.operationId}`]));
				} else {
					// TODO: Script problem, not sure what to do here other than log it
					this.log.logError(`${error.name}: ${error.message}`, { stack: error.stack });
				}
			}
		);
	}
}
