<div
	fxLayout="column"
	class="card"
	[ngStyle]="styles"
	[ngClass]="classes"
	id="{{ mainTitle ? mainTitle : secondaryTitle }}_Card_Wrap">
	<div
		fxLayout="row"
		fxLayoutAlign="space-between start"
		*ngIf="mainTitle"
		id="{{ mainTitle }}_Header">
		<h1>
			{{ mainTitle }}
		</h1>
		<div
			class="card-badges"
			*ngIf="showBadgeMain">
			<div
				id="{{ badgeId }}"
				[ngClass]="{
					'kpa-badge': true,
					'kpa-badge-gray': badgeStatus == badgeEnum.Inactive,
					'kpa-badge-green': badgeStatus == badgeEnum.Default || badgeStatus == badgeEnum.IconSuccess,
					'kpa-badge-orange': badgeStatus == badgeEnum.IconAlert
				}">
				<i
					[ngClass]="{
						fal: true,
						'fa-check-circle': badgeStatus == badgeEnum.IconSuccess,
						'fa-circle-exclamation': badgeStatus == badgeEnum.IconAlert
					}"></i>
				{{ badgeText }}
			</div>
		</div>
	</div>
	<div
		fxLayout="row"
		fxLayoutAlign="space-between start"
		class="card-head"
		*ngIf="secondaryTitle"
		id="{{ secondaryTitle }}_Header">
		<h3>
			{{ secondaryTitle }}
		</h3>
		<div
			class="card-badges"
			*ngIf="showBadge">
			<div
				id="{{ badgeId }}"
				[ngClass]="{
					'kpa-badge': true,
					'kpa-badge-gray': badgeStatus == badgeEnum.Inactive,
					'kpa-badge-green': badgeStatus == badgeEnum.Default || badgeStatus == badgeEnum.IconSuccess,
					'kpa-badge-orange': badgeStatus == badgeEnum.IconAlert
				}">
				<i
					[ngClass]="{
						fal: true,
						'fa-check-circle': badgeStatus == badgeEnum.IconSuccess,
						'fa-circle-exclamation': badgeStatus == badgeEnum.IconAlert
					}"></i>
				{{ badgeText }}
			</div>
		</div>
	</div>
	<div
		fxLayout="row"
		class="card-body-wrap"
		id="{{ mainTitle ? mainTitle : secondaryTitle }}_Card_Body_Wrap">
		<div
			class="card-body"
			id="{{ secondaryTitle }}_Card_Body">
			<ng-content></ng-content>
		</div>
	</div>
	<div
		fxLayout="row"
		fxLayoutAlign="end center"
		class="card-foot"
		id="{{ mainTitle ? mainTitle : secondaryTitle }}_Card_Foot"></div>
</div>
