import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[appDisableAll]',
	inputs: ['isDisabled'],
})
export class DisableAllDirective {
	constructor(private element: ElementRef) {}
	@Input() public isDisabled: boolean;

	ngOnInit() {
		if (this.isDisabled) {
			this.disableElement(this.element.nativeElement.querySelectorAll('kendo-dropdownlist'));
			this.disableElement(this.element.nativeElement.querySelectorAll('input'));
			this.disableElement(this.element.nativeElement.querySelectorAll('kendo-dateinput'));
		}
	}

	private disableElement(elements: any) {
		const len = elements.length;
		for (let i = 0; i < len; i++) {
			let shouldDisable = true;
			for (let j = 0; j < elements[i].attributes.length; j++) {
				const atts = elements[i].attributes[j];
				if (atts.name === 'skip-disable') {
					shouldDisable = false;
					continue;
				}
			}
			if (shouldDisable) {
				elements[i].readonly = true;
				elements[i].readOnly = true;
				elements[i].disabled = true;
				elements[i].disabled = 'disabled';
				elements[i].disabledIf = true;
			}
		}
	}
}
