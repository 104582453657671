import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { AnyMxRecord } from 'dns';
import { Item } from '../../../shared/models/item';
import { ActivatedRoute, Router } from '@angular/router';
import { WindowService } from '@progress/kendo-angular-dialog';

@Component({
	selector: 'kpa-field-mapping',
	templateUrl: './kpa-field-mapping.component.html',
	styleUrls: ['./kpa-field-mapping.component.css'],
})
export class KpaFieldMappingComponent implements OnInit, OnChanges {
	@Input() showCompletedIcon: any;
	@Input() isDelete: any;
	@Input() isMapping: any;
	@Input() group: any;
	@Input() index: any;
	@Input() isSelect: any;
	@Input() isMappingDisabled: any;
	@Input() toolTip: any;
	@Input() firstColumnValues: any;
	@Input() secondColumnValues: any;
	@Input() isMappingRequired: any;
	@Input() numberOfMappings: any;
	@Input() emptyPlaceholder: Item = { text: 'Select Column', value: null };
	@Input() emptySecondPlaceholder: Item = { text: 'Select Column', value: null };
	@Output()
	onDeleteEventEmit: EventEmitter<any> = new EventEmitter();
	@Output()
	onSelectFirstValEventEmit: EventEmitter<any> = new EventEmitter();
	@Output()
	onAdditionalMappingEventEmit: EventEmitter<any> = new EventEmitter();
	@Output()
	onSelectSecondValEventEmit: EventEmitter<any> = new EventEmitter();

	public isFirstColumnText = false;
	public isSecondColumnText = false;
	public isRequiredField = false;
	firstFilteredData: any;
	secondFilteredData: any;
	public get mappingFieldFirstColumn() {
		return this.group.get('mappingFieldFirstColumn');
	}

	public get mappingFieldSecondColumn() {
		return this.group.get('mappingFieldSecondColumn');
	}

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		private readonly router: Router
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		for (let property in changes) {
			let currentValue = changes[property].currentValue;
			let previousValue = changes[property].previousValue;

			if (currentValue == previousValue) {
				continue;
			}

			switch (property) {
				case 'secondColumnValues':
					this.secondFilteredData = this.secondColumnValues;
					break;

				default:
					break;
			}

			if (property == this.secondColumnValues) {
				this.secondFilteredData = this.secondColumnValues;
			}
		}
	}

	ngOnInit(): void {
		this.firstFilteredData = this.firstColumnValues;
		this.secondFilteredData = this.secondColumnValues;
		this.isRequiredField = this.isMappingRequired;

		if (this.firstColumnValues && this.firstColumnValues.length > 0) {
			this.isFirstColumnText = false;
		} else {
			this.isFirstColumnText = true;
		}

		if (this.secondColumnValues && this.secondColumnValues.length > 0) {
			this.isSecondColumnText = false;
		} else {
			this.isSecondColumnText = true;
		}
	}

	ngAfterViewInit() {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const definitionId = urlSearchParams.get('definitionId');
		setTimeout(() => {
			let prevPosition = parseInt(window.sessionStorage.getItem('kpa-field-mapping-pageYOffset'));
			if (!definitionId) {
				if (prevPosition) {
					var offsetPosition = prevPosition + window.pageYOffset;

					window.scrollTo({
						top: offsetPosition,
						behavior: 'smooth',
					});
				}
			} else {
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}, 450);
	}

	additionalMapping(index) {
		const firstColumnValue = this.group.get('mappingFieldFirstColumn').value;
		this.onAdditionalMappingEventEmit.emit({ value: firstColumnValue });
		window.sessionStorage.setItem('kpa-field-mapping-pageYOffset', window.pageYOffset.toString());
	}

	onFirstFieldChanged(value) {
		this.onSelectFirstValEventEmit.emit({ index: this.index, value: value });
	}

	public selectionChange(value: any): void {}

	onSecondFieldChanged(value) {
		this.onSelectSecondValEventEmit.emit({ index: this.index, value: value });
	}

	removeImport(index) {
		this.onDeleteEventEmit.emit(index);
	}

	firstFilterChanged(value) {
		const filteredVal = this.firstColumnValues;
		this.firstFilteredData = filteredVal.filter((s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1);
	}
	secondFilterChanged(value) {
		const filteredVal = this.secondColumnValues;
		this.secondFilteredData = filteredVal.filter((s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1);
	}
}
