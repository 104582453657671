import { SupportComponent } from './support/support.component';
import { ProfileComponent } from './profile/profile.component';
import { ConsultantsComponent } from './consultants/consultants.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CollectionPersonalInfoNoticeComponent } from './collection-personal-info-notice/collection-personal-info-notice.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SuggestionComponent } from './suggestion/suggestion.component';

const routes: Routes = [
	{
		path: 'support',
		component: SupportComponent,
	},
	{
		path: 'consultants',
		component: ConsultantsComponent,
	},
	{
		path: 'profile',
		component: ProfileComponent,
	},
	{
		path: 'CollectionPersonalInfoNotice',
		component: CollectionPersonalInfoNoticeComponent,
	},
	{
		path: 'PrivacyPolicy',
		component: PrivacyPolicyComponent,
	},
	{
		path: 'suggestion',
		component: SuggestionComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class HomeRoutingModule {}
