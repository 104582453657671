import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormArray, UntypedFormGroup, AbstractControl } from '@angular/forms';
import { LogService } from '@app/core/services/log.service';

interface ValidationErrorDescription {
	domain: string;
	reason: string;
	message: string;
	locationType: string;
	location: string;
	severity: undefined | 'Error' | 'Warning' | 'Information';
}

export abstract class BaseApiClient {
	constructor() {}

	protected createErrorResponseHandler(
		log?: LogService,
		binding?: UntypedFormArray | UntypedFormGroup,
		errorCallback?: (error: HttpErrorResponse) => void
	): (HttpErrorResponse) => Observable<never> {
		return (response: HttpErrorResponse) => {
			try {
				if (response.error instanceof ErrorEvent) {
					if (log) log.logError('Client side error: ' + response.error.message);
				} else {
					// Process bad request responses
					if (response.status === 400 && binding) {
						var errors = response.error.errors as ValidationErrorDescription[];

						if (errors)
							errors.forEach((error) => {
								if (error.severity == undefined || error.severity === 'Error') {
									// Add the error to the located control, or if not found or the location type is 'Resource', the top level binding
									var ctrl =
										error.locationType === 'Resource' ? binding : this.tryGetControl(binding as AbstractControl, error.location) || binding;
									var theseErrors = ctrl.errors || {};
									var apiErrors = (theseErrors.apiErrors as string[]) || [];
									apiErrors.push(error.message);
									theseErrors.apiErrors = apiErrors;
									ctrl.setErrors(theseErrors);
								}
							});
					}
					// Log out other error responses
					else if (log) log.logError('Server side error: ' + response.url + ' ' + response.message);
				}

				if (errorCallback) errorCallback(response);
			} catch (innerError) {
				try {
					// Swallow exceptions in the error handler
					if (log) log.logException(innerError, { outerException: response.toString() });
				} catch {}
			} finally {
				// Rethrow for the observable pipeline
				return throwError(response);
			}
		};
	}

	/**
	 * Attempts to find a corresponding AbstractControl based on the location provided by the error payload.
	 * @param binding The FormGroup to receive validation errors.
	 * @param location The location of the error provided by the response.
	 */
	private tryGetControl(binding: AbstractControl, location: string): AbstractControl {
		// Transform API location notation to Reactive Forms notation
		location = location
			.replace(/(?:^|\.)(\w)/g, (s) => s.toLowerCase())
			.replace(/(.\[|\]\.)/g, (s) => (s[1] == '.' ? '.' : s[0] + '.'))
			.replace(/(^\[|\]$)/g, '');
		return binding.get(location);
	}
}
