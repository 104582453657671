import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DateRange } from '@app/shared/models/date-range';

@Component({
	selector: 'kpa-kpa-date-range-filter',
	templateUrl: './kpa-date-range-filter.component.html',
	styleUrls: ['./kpa-date-range-filter.component.css'],
})
export class KpaDateRangeFilterComponent implements OnInit {
	range: DateRange = { start: null, end: null };
	appliedRange: DateRange = { start: null, end: null };
	@Output() selectedDateRangeEvent = new EventEmitter<DateRange>();

	constructor() {}

	ngOnInit(): void {}

	onApplyClick() {
		this.appliedRange.start = this.range.start;
		this.appliedRange.end = this.range.end;
		this.selectedDateRangeEvent.emit(this.appliedRange);
	}

	applyDisabled(): boolean {
		return (
			this.range.start == null ||
			this.range.end == null ||
			(this.appliedRange.start == this.range.start && this.appliedRange.end == this.range.end)
		);
	}

	clearDisabled(): boolean {
		return this.range.start == null && this.range.end == null;
	}

	onClearClick() {
		let emitChanges: boolean = this.appliedRange.start != null || this.appliedRange.end != null;
		this.appliedRange.start = null;
		this.range.start = null;
		this.appliedRange.end = null;
		this.range.end = null;
		if (emitChanges) {
			this.selectedDateRangeEvent.emit(this.appliedRange);
		}
	}
}
