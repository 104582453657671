export enum CardBadgeState {
	Default = 1,
	IconSuccess = 2,
	IconAlert = 3,
	Inactive = 4,
}

export enum KpaStatusCardState {
	Default = '',
	IconSuccess = 'success',
	IconWarning = 'warning',
	IconAlert = 'alert',
}
