import { Directive, Host, KeyValueDiffers, Optional } from '@angular/core';
import { GridComponent, PagerSettings } from '@progress/kendo-angular-grid';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import { KpaMonitorChanges } from '../../shared/kpa-monitor-changes';

@Directive({
	selector: '[kpaGrid]',
	exportAs: 'kpaGrid',
})
export class KpaGridDirective extends KpaMonitorChanges<GridComponent> {
	private static readonly PageSizes: number[] = [10, 25, 50];

	constructor(
		@Optional() private readonly grid: GridComponent,
		@Host() private readonly localization: LocalizationService,
		diff: KeyValueDiffers
	) {
		super(diff);

		if (!grid) throw 'The KpaGridDirective can only be applied to a Kendo GridComponent component.';
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.localization.register('pagerItemsPerPage', 'per page', true);

		if (!KpaGridDirective.PageSizes.includes(this.grid.pageSize)) this.grid.pageSize = KpaGridDirective.PageSizes[0];

		this.monitor(this.grid, 'pageable');
		this.monitor(this.grid.pageable as PagerSettings, 'buttonCount', 'info', 'previousNext', 'pageSizes', 'type');
	}

	setBehaviors(): void {
		const settings =
			typeof this.grid.pageable === 'boolean' ? (this.grid.pageable = <PagerSettings>{}) : (this.grid.pageable as PagerSettings);
		settings.buttonCount = 4;
		settings.info = true;
		settings.previousNext = true;
		settings.pageSizes = KpaGridDirective.PageSizes;
		settings.type = 'numeric';
	}
}
