import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User, UserManagerSettings, WebStorageStateStore, Log } from 'oidc-client';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { SESSION_STORAGE, LOCAL_STORAGE } from './storage';
import { Environment } from '@env/environment';
import { AuthRoutes } from '@app/core/components/auth/auth-routes';
import { ImpersonationUserManager, IMPERSONATION_STATE_KEY } from './impersonation.user-manager';
import { LogService } from './log.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	// Constants
	private static readonly _loginAsAcrKey = 'loginas';

	private _user: ReplaySubject<User>;

	public get user(): Observable<User> {
		return this._user;
	}

	private _originator: ReplaySubject<User>;

	public get originator(): Observable<User> {
		return this._originator;
	}

	private _isLoggedIn: Observable<boolean>;

	public get isLoggedIn(): Observable<boolean> {
		return this._isLoggedIn;
	}

	private _sessionId: Observable<string>;

	public get sessionId(): Observable<string> {
		return this._sessionId;
	}

	private _accessToken: Observable<string>;

	public get accessToken(): Observable<string> {
		return this._accessToken;
	}

	private _originatorAccessToken: Observable<string>;

	public get originatorAccessToken(): Observable<string> {
		return this._originatorAccessToken;
	}

	private _authorizationHeader: Observable<string>;

	public get authorizationHeader(): Observable<string> {
		return this._authorizationHeader;
	}

	private _originatorAuthorizationHeader: Observable<string>;

	public get authorizationOriginatorHeader(): Observable<string> {
		return this._originatorAuthorizationHeader;
	}

	private _isImpersonating: Observable<boolean>;

	public get isImpersonating(): Observable<boolean> {
		return this._isImpersonating;
	}

	private readonly manager: ImpersonationUserManager;

	private get clientSettings(): UserManagerSettings {
		return {
			authority: this.environment.services['sso'],
			client_id: 'kpa_platform_ui',
			redirect_uri: location.origin + this.router.createUrlTree(['/' + AuthRoutes.AuthenticateCallback]).toString(),
			post_logout_redirect_uri: location.origin,
			response_type: 'id_token token',
			scope: 'openid roles profile email kpa_platform_api',
			filterProtocolClaims: true,
			loadUserInfo: true,
			userStore: new WebStorageStateStore({ store: this.storage }),
		};
	}

	constructor(
		private readonly router: Router,
		private readonly environment: Environment,
		private readonly logger: LogService,
		@Inject(SESSION_STORAGE) private readonly storage: Storage,
		@Inject(LOCAL_STORAGE) private readonly impersonationStorage: Storage
	) {
		Log.logger = this.logger;
		this.manager = new ImpersonationUserManager(this.clientSettings, impersonationStorage);
		this.createObservables();
	}

	private createObservables(): void {
		this._user = new ReplaySubject<User>(1);
		this._isLoggedIn = this._user.pipe(map((user) => user && !user.expired));
		this._sessionId = this._user.pipe(map((user) => (user && user.profile && user.profile.sid) || null));
		this._accessToken = this._user.pipe(map((user) => (user && user.access_token) || null));
		this._authorizationHeader = this._user.pipe(map((user) => (user ? `${user.token_type} ${user.access_token}` : null)));

		this._originator = new ReplaySubject<User>(1);
		this._originatorAccessToken = this._originator.pipe(map((originator) => (originator && originator.access_token) || null));
		this._originatorAuthorizationHeader = this._originator.pipe(
			map((originator) => (originator ? `${originator.token_type} ${originator.access_token}` : null))
		);
		this._isImpersonating = this._originator.pipe(map((originator) => originator !== null));

		// Update public properties
		this.manager.getUser().then((user) => this._user.next(user));
		this.manager.getOriginator().then((user) => this._originator.next(user));
	}

	public startAuthenticationAsync(returnUrl: string = '/landing'): Promise<void> {
		return this.manager.signinRedirect({ state: { returnUrl: returnUrl } });
	}

	/**
	 * Wrapper for UserManager.completeAuthenticationCallback to support Impersonation   *
	 * @param allowImpersonate True for ImpersonateCallback, otherwise false.
	 * @returns The redirect URL requested for the user.
	 */
	public async completeAuthenticationAsync(allowImpersonate: boolean = false): Promise<string> {
		// Get both Originator and User
		const authenticatedUser = await this.manager.completeAuthenticationCallback(allowImpersonate);
		const originatorUser = await this.manager.getOriginator();

		// Set the Users in the member variables
		this._user.next(authenticatedUser);
		this._originator.next(originatorUser);

		// Return redirect as necessary
		const authenticatedUserState = authenticatedUser.state as any;
		return authenticatedUserState?.returnUrl || '';
	}

	/**
	 * Log out of the application and clear all storage
	 * @param fromExternal True if a federated logout and redirect to OIDC is not needed; otherwise false.
	 */
	public async logoutAsync(fromExternal: boolean = false): Promise<void> {
		// Check the Originator object to end Impersonation or log out.
		// If there is an Originator, stop Impersonation.  Otherwise, log the
		// (not Impersonating) User out of the application.
		const originator = await this.manager.getOriginator();

		if (originator) {
			await this.endImpersonationAsync(AuthRoutes.Landing);
		} else {
			// Defer execution for storage clear as the redirect requires the data
			setTimeout(() => {
				this.storage.clear();
				this.impersonationStorage.clear();
				this._user.next(null);
			});

			if (!fromExternal) {
				await this.manager.signoutRedirect();
			}
		}
	}

	/**
	 * Begin an impersonated session.
	 * Originator must have SecurityPermission.Impersonate permission.
	 * @param userId  UserID of the user to be impersonated.
	 * @param returnUrl Redirect Url following successful impersonation authentication.
	 * @param callbackUrl - URL used after the authentication has occurred.
	 */
	public startImpersonationAsync(userId: string, returnUrl?: string, callbackUrl: string = AuthRoutes.ImpersonateCallback): Promise<void> {
		// Set up state for the signin
		const state = { returnUrl: returnUrl };
		state[IMPERSONATION_STATE_KEY] = true;

		// Begin the signin
		return this.manager.signinRedirect({
			prompt: 'login',
			acr_values: `${AuthService._loginAsAcrKey}:${userId}`,
			redirect_uri: location.origin + this.router.createUrlTree([`/${callbackUrl}`]).toString(),
			state: state,
		});
	}

	/**
	 * Cease an impersonated session and return control to the Originator session.
	 */
	public async endImpersonationAsync(returnUrl?: string): Promise<void> {
		// Get rid of the Impersonated User and retrieve the Originator
		// from Local Storage
		const user = await this.manager.endImpersonationCallback();

		// Clean up the state of the User Observables
		this._originator.next(null);
		this._user.next(user);

		// Force a new Impersonation call as the Originator so that
		// the Session can be replaced
		return this.startImpersonationAsync(user.profile.sub, returnUrl, AuthRoutes.ImpersonateCallback);
	}
}
