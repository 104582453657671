<div class="sso-layout">
	<div
		class="background-image"
		[class.blue]="theme === blue">
		<img
			src="/assets/layout/images/login/diamonds.png"
			alt=""
			role="presentation" />
	</div>
	<div
		*ngIf="(user$ | async) && (originator$ | async)"
		class="impersonation-banner-wrapper">
		<a
			[href]="exitImpersonationUrl"
			class="k-button k-primary kpa-nav-exit-impersonation"
			>Exit Impersonation</a
		>
		<div class="kpa-nav-impersonation-banner">
			<span>{{ originator$ | async }} IMPERSONATING {{ user$ | async }}</span>
		</div>
	</div>
	<div class="content-wrap">
		<div class="content">
			<img
				class="banner"
				src="/assets/layout/images/login/logo.png"
				alt="Vera Suite by KPA" />
			<div class="content-area">
				<router-outlet></router-outlet>
			</div>
		</div>
		<footer>
			<p>Support: <a href="tel:+18004860400">(800) 486-0400</a> or <a href="mailto:support@kpa.io">support&#64;kpa.io</a></p>
			<p>Copyright ©{{ year }} KPA Services, LLC.</p>
		</footer>
	</div>
</div>
