import { AfterViewChecked, AfterViewInit, Component, NgZone, OnDestroy, OnInit, Renderer2, HostListener } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map, withLatestFrom, take } from 'rxjs/operators';
import { Environment } from '../../../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppRoutingObserver } from '../../../app-routing-observer';
import { NgElement, WithProperties } from '@angular/elements';
import { MkoPermissions, HrmPermissions, UserInfoService } from '@app/core/services/user-info.service';
import { SecurityService } from '@app/core/services/security.service';
import { UserInfoDto } from '@app/core/models/user-info-dto';
import { User } from 'oidc-client';
import { ApplicationFeature } from '@app/core/models/application-feature-enums';
import { LogService } from '@app/core/services/log.service';

declare function initWalkMe(walkMeUrl, userId, clientId, roleName, ehsRole, hrRole, hasAccessList): void;
declare function initDataLayer(
	userId,
	clientIdKpaId,
	primaryServiceToIdKpaId,
	ehsRole,
	hrRole,
	isImpersonated,
	clientName,
	locationName,
	platformRoleName,
	userName
): void;

@Component({
	selector: 'kpa-platform-layout',
	templateUrl: './platform-layout.component.html',
	styleUrls: ['./platform-layout.component.css'],
})
export class PlatformLayoutComponent implements OnDestroy, OnInit, AfterViewChecked, AfterViewInit {
	// Constants
	private static readonly _hrmLoginAsFragment = 'Account/LoginAs';
	private static readonly _mkoLoginAsFragment = 'LoginAs.aspx';
	private static readonly _noUserInfoFound = '~ No user info ~';

	userId: string;

	showUi: Observable<boolean>;

	routerForElement: Router;

	preAuthenticateHrDrive: boolean;

	hrDrivePreAuthLanding: SafeResourceUrl;

	preAuthenticateMyKpaOnline: boolean;

	myKpaOnlinePreAuthLanding: SafeResourceUrl;

	layoutMode = 'overlay';

	rippleInitListener: any;

	rippleMouseDownListener: any;

	sid$: Observable<string>;
	accessToken$: Observable<string>;
	originatorAccessToken$: Observable<string>;
	navBaseApi: string;
	logVerbosity: number;
	logToConsole: string;
	appInsightsConnectionString: string;
	/**
	 * Holds ApplicationInsight Instrumentation Key
	 * @todo Keeping for now until fix up CommonNav and footer
	 */
	appInsightsKey: string;
	originator$: Observable<string>;
	user$: Observable<string>;

	constructor(
		public renderer: Renderer2,
		public zone: NgZone,
		private readonly router: Router,
		private readonly domSanitizer: DomSanitizer,
		private readonly authService: AuthService,
		private readonly environment: Environment,
		private readonly userInfoService: UserInfoService,
		private readonly securityService: SecurityService,
		private readonly logService: LogService
	) {
		this.configureSubscribers();
		window.onresize = (e) => {
			zone.run(() => {
				this.logService.debug('InnerWidth: ' + window.innerWidth);
				this.logService.debug('InnerHeight: ' + window.innerHeight);
				this.fixLeftNavStretch();
			});
		};
	}

	private configureSubscribers() {
		this.logService.debug('PlatformLayout:configureSubscribers');
		this.router.events.subscribe(new AppRoutingObserver());

		this.sid$ = this.authService.sessionId;
		this.accessToken$ = this.authService.accessToken;
		this.originatorAccessToken$ = this.authService.originatorAccessToken;

		// Set up flags for the IFRAMEs that begin the authentication
		// for MKO and HRM
		this.userInfoService
			.hasPermission(ApplicationFeature.MkoApplication, MkoPermissions.ApplicationMembership)
			.pipe(
				take(1),
				withLatestFrom(this.userInfoService.hasPermission(ApplicationFeature.HrDrive, HrmPermissions.ApplicationMembership).pipe(take(1))),
				map(([hasMko, hasHrm]) => {
					// will cause iframes to render.  their src's to come later
					this.preAuthenticateMyKpaOnline = hasMko;
					this.preAuthenticateHrDrive = hasHrm;

					if (hasMko) {
						this.loadMko();
					}

					// Only trigger loading of HRM from here if they don't have
					// MKO.  Someone with both will do MKO first and then HRM.
					if (!hasMko && hasHrm) {
						this.loadHrm();
					}

					return;
				})
			)
			.subscribe();

		// Set up Observables for the Originator and Impersonator names:
		// The AuthService.originator value is non-null only if the User
		// has begun Impersonation.  Passing a null value to getUserName
		// results in a blank string being passed to Common Nav.
		this.originator$ = this.authService.originator.pipe(map((originator) => this.getUserName(originator)));

		this.user$ = this.userInfoService.getDisplayName();
	}

	private loadMko(): void {
		this.authService.isImpersonating
			.pipe(
				take(1),
				withLatestFrom(this.authService.user),
				map(([isImpersonating, user]) => {
					this.myKpaOnlinePreAuthLanding = this.generateLoginAsUrl(
						[this.environment.preAuthLanding.myKpaOnline, PlatformLayoutComponent._mkoLoginAsFragment],
						user.profile.sub,
						user.profile.sid
					);
				})
			)
			.subscribe();
	}

	private loadHrm(): void {
		this.authService.isImpersonating
			.pipe(
				take(1),
				withLatestFrom(this.authService.user),
				map(([isImpersonating, user]) => {
					this.hrDrivePreAuthLanding = this.generateLoginAsUrl(
						[this.environment.preAuthLanding.hrDrive, PlatformLayoutComponent._hrmLoginAsFragment],
						user.profile.sub,
						user.profile.sid
					);
				})
			)
			.subscribe();
	}

	@HostListener('window:message', ['$event'])
	onMessage(event) {
		// mko is done, load hrm
		if (event.data === 'kpa.mko.admin.loaded' && this.preAuthenticateHrDrive) {
			this.loadHrm();
		}
	}

	public fixLeftNavStretch() {
		const winHeight = window.innerHeight;
		this.logService.debug('WinHeight' + winHeight);

		const footerHeight = 253;
		const x = document.getElementById('kpa-content-inner').offsetHeight;
		const contentHeight = x + footerHeight;

		this.logService.debug('ContentHeight' + contentHeight);

		if (winHeight > contentHeight) {
			document.getElementById('layout-wrapper').style.height = '100%';
			document.getElementById('layout-main').style.height = '100%';
			// document.getElementById('kpa-content-wrapper').style.height = "100%";
		} else if (winHeight < contentHeight) {
			document.getElementById('layout-wrapper').style.height = 'initial';
			document.getElementById('layout-main').style.height = 'initial';
			// document.getElementById('kpa-content-wrapper').style.height = "initial";
			this.logService.debug('winHeight < conentHeight, Else TRIGGERED');
		}
		this.logService.debug('fixLeftNavStretch TRIGGERED');
	}

	ngOnInit() {
		this.logService.debug('platform-layout-component: starting ngOnInit');

		this.navBaseApi = this.environment.services['navigation'];
		this.logVerbosity = this.environment.logLevel;
		this.logToConsole = this.environment.logToConsole;
		this.appInsightsConnectionString = this.environment.appInsightsConnectionString;

		// TODO: See if there's a more deterministic way of excluding auth callback from the render supression rule
		const kpaCommonNavElementJS = document.querySelector('#kpa-common-nav-element-js') as NgElement &
			WithProperties<{
				donavigation: boolean;
				token: string;
				hostingapplication: string;
				nokpacaching: boolean;
			}>;

		if (kpaCommonNavElementJS) {
			kpaCommonNavElementJS.addEventListener('urlclicked', (e) => this.urlClick(e));
		}

		this.zone.runOutsideAngular(() => {
			this.bindRipple();
		});

		this.securityService.getMyUserInfo().subscribe((data: UserInfoDto) => {
			const walkMeUrl = this.environment.walkMeUrl;

			// Get the EHS and hr roles from the Myuserinfo call
			let ehsRole;
			if (data.ehsRole) {
				ehsRole = data.ehsRole.roleName;
			}

			let hrRole;
			if (data.hrRole) {
				hrRole = data.hrRole.roleName;
			}

			let hasAccessList = false;
			if (data.accessListId && data.accessListId !== '') {
				hasAccessList = true;
			}

			let platformRoleName;
			if (data.roles) {
				platformRoleName = data.roles[0].roleName;
			}

			if (this.environment.walkmeConfig) {
				initWalkMe(walkMeUrl, data.userId, data.clientId, data.roles[0].roleName, ehsRole, hrRole, hasAccessList);
			}

			this.authService.isImpersonating.subscribe((impersonating: boolean) => {
				initDataLayer(
					data.userId,
					data.clientKpaId,
					data.primaryServiceToKpaId,
					ehsRole,
					hrRole,
					impersonating.toString(),
					data.clientName,
					data.locationName,
					platformRoleName,
					data.userName
				);
			});

			this.userId = data.userId;
		});

		this.fixLeftNavStretch();
	}

	urlClick(e) {
		this.logService.debug('urlclicked: ' + e.detail);

		const urls = JSON.parse(e.detail);
		if (urls.route) {
			this.router.navigateByUrl(urls.route); // , {skipLocationChange: true});
		} else {
			if (urls.fullUrl) {
				window.open(urls.fullUrl, '_self'); // or window.location.href....
			}
		}
	}

	ngAfterViewInit() {
		// viewChild is set after the view has been initialized
		this.logService.debug('app-component:AfterViewInit');
	}

	ngAfterViewChecked() {}

	bindRipple() {
		this.rippleInitListener = this.init.bind(this);
		document.addEventListener('DOMContentLoaded', this.rippleInitListener);
	}

	init() {
		this.rippleMouseDownListener = this.rippleMouseDown.bind(this);
		document.addEventListener('mousedown', this.rippleMouseDownListener, false);
	}

	rippleMouseDown(e) {
		for (let target = e.target; target && target !== this; target = target['parentNode']) {
			if (!this.isVisible(target)) {
				continue;
			}

			// Element.matches() -> https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
			if (this.selectorMatches(target, '.ripplelink, .ui-button')) {
				const element = target;
				this.rippleEffect(element, e);
				break;
			}
		}
	}

	selectorMatches(el, selector) {
		const p = Element.prototype;
		const f =
			p['matches'] ||
			p['webkitMatchesSelector'] ||
			p['mozMatchesSelector'] ||
			p['msMatchesSelector'] ||
			function (s) {
				return [].indexOf.call(document.querySelectorAll(s), this) !== -1;
			};
		return f.call(el, selector);
	}

	isVisible(el) {
		return !!(el.offsetWidth || el.offsetHeight);
	}

	rippleEffect(element, e) {
		if (element.querySelector('.ink') === null) {
			const inkEl = document.createElement('span');
			this.addClass(inkEl, 'ink');

			if (this.hasClass(element, 'ripplelink') && element.querySelector('span')) {
				element.querySelector('span').insertAdjacentHTML('afterend', "<span class='ink'></span>");
			} else {
				element.appendChild(inkEl);
			}
		}

		const ink = element.querySelector('.ink');
		this.removeClass(ink, 'ripple-animate');

		if (!ink.offsetHeight && !ink.offsetWidth) {
			const d = Math.max(element.offsetWidth, element.offsetHeight);
			ink.style.height = d + 'px';
			ink.style.width = d + 'px';
		}

		const x = e.pageX - this.getOffset(element).left - ink.offsetWidth / 2;
		const y = e.pageY - this.getOffset(element).top - ink.offsetHeight / 2;

		ink.style.top = y + 'px';
		ink.style.left = x + 'px';
		ink.style.pointerEvents = 'none';
		this.addClass(ink, 'ripple-animate');
	}

	hasClass(element, className) {
		if (element.classList) {
			return element.classList.contains(className);
		} else {
			return new RegExp('(^| )' + className + '( |$)', 'gi').test(element.className);
		}
	}

	addClass(element, className) {
		if (element.classList) {
			element.classList.add(className);
		} else {
			element.className += ' ' + className;
		}
	}

	removeClass(element, className) {
		if (element.classList) {
			element.classList.remove(className);
		} else {
			element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
		}
	}

	getOffset(el) {
		const rect = el.getBoundingClientRect();

		return {
			top: rect.top + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0),
			left: rect.left + (window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0),
		};
	}

	unbindRipple() {
		if (this.rippleInitListener) {
			document.removeEventListener('DOMContentLoaded', this.rippleInitListener);
		}
		if (this.rippleMouseDownListener) {
			document.removeEventListener('mousedown', this.rippleMouseDownListener);
		}
	}

	ngOnDestroy() {
		this.unbindRipple();
	}

	isMobile() {
		return window.innerWidth <= 1024;
	}

	isTablet() {
		const width = window.innerWidth;
		return width <= 1024 && width > 640;
	}

	isHorizontal() {
		return this.layoutMode === 'horizontal';
	}

	isOverlay() {
		return this.layoutMode === 'overlay';
	}

	/**
	 * Generates a LoginAs URL for impersonation.
	 * @param urlParts The parts of the URL.
	 * @param userId The user identifier.
	 * @param sid The login session identifier.
	 */
	private generateLoginAsUrl(urlParts: string[], userId: string, sid: string): SafeResourceUrl {
		// Join the URL parts
		const url = `${urlParts.join('/')}?userId=${userId}&sid=${sid}`;

		// Now mark the URL trusted
		return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
	}

	// Method to pull a name from the User
	public getUserName(user: User, defaultValue: string = ''): string {
		// Default to a generic value
		let rtn = defaultValue;

		// If the User is valid, try to get the values
		if (user && user.profile) {
			// Get the values
			const displayName = user.profile.preferred_username || '';
			const email = user.profile.email || '';

			// Return Display Name or Email
			rtn = displayName || email || PlatformLayoutComponent._noUserInfoFound;
		}

		// Return the result
		return rtn;
	}
}
