import { AfterContentChecked, Directive, KeyValueDiffer, KeyValueDiffers, OnInit } from '@angular/core';

@Directive()
export abstract class KpaMonitorChanges<TMonitored> implements OnInit, AfterContentChecked {
	private trackers: { differ: KeyValueDiffer<string, any>; target: any; properties: string[] }[] = [];
	private monitoredProperties: (keyof TMonitored)[];
	private ignoreNextTrackedChange: boolean = false;

	protected constructor(
		private readonly diff: KeyValueDiffers,
		private readonly monitoredObject?: TMonitored,
		...monitoredProperties: (keyof TMonitored)[]
	) {
		if (monitoredObject && typeof monitoredObject !== 'object') throw 'Primitive types cannot be monitored for property changes.';
		if (!monitoredObject && monitoredProperties.length) throw 'Cannot monitor provided properties for a null or undefined object.';
		this.monitoredProperties = monitoredProperties;
	}

	ngOnInit(): void {
		this.setBehaviors();
		if (this.monitoredObject) this.monitor(this.monitoredObject, ...this.monitoredProperties);
	}

	ngAfterContentChecked(): void {
		this.trackers.forEach((config) => {
			const changes = config.differ.diff(config.target);
			if (changes && !this.ignoreNextTrackedChange) {
				changes.forEachChangedItem((delta) => {
					if (config.properties.includes(delta.key)) {
						setTimeout(() => {
							this.ignoreNextTrackedChange = true;
							this.setBehaviors();
						});
						throw `Do not alter the '${delta.key}' property as it is being maintained by a KPA attached behavior.`;
					}
				});
			}
		});
		this.ignoreNextTrackedChange = false;
	}

	protected monitor<TTarget>(monitoredObject: TTarget, ...monitoredProperties: (keyof TTarget)[]) {
		this.trackers.push({
			differ: this.diff.find(monitoredObject).create<string, any>(),
			target: monitoredObject,
			properties: monitoredProperties as string[],
		});
	}

	abstract setBehaviors(): void;

	/**
	 * Immediately applies mutable behaviors without triggering a warning exception.
	 */
	protected forceSetBehaviors(): void {
		this.ignoreNextTrackedChange = true;
		this.setBehaviors();
	}
}
