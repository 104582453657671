<kendo-floatinglabel
	fxFlex
	id="inpText_PhoneBox_Label"
	#inpText_PhoneBox_Label
	text="{{ label }}">
	<input
		maxlength="10"
		kendoTextBox
		id="inpText_PhoneBox"
		[disabled]="disabled"
		[(value)]="formattedValue"
		(change)="onChangedValues($event)"
		(blur)="onBlur()"
		(focus)="onFocus()"
		(input)="onInput($event)" />
</kendo-floatinglabel>
