import { Directive, KeyValueDiffers, Optional } from '@angular/core';
import { ButtonDirective } from '@progress/kendo-angular-buttons';
import { KpaMonitorChanges } from '../../shared/kpa-monitor-changes';

@Directive({
	selector: '[kpaTextButton]',
	exportAs: 'kpaTextButton',
})
export class KpaTextButtonDirective extends KpaMonitorChanges<ButtonDirective> {
	constructor(
		@Optional() private readonly directive: ButtonDirective,
		diff: KeyValueDiffers
	) {
		super(diff, directive, 'look', 'primary');
		if (!directive || !(directive instanceof ButtonDirective))
			throw 'The KpaTextButtonDirective has a sibling dependency of the Kendo ButtonDirective. Please apply this attribute directive to your button element.';
	}

	setBehaviors(): void {
		this.directive.look = 'flat';
		this.directive.primary = true;
	}
}
