import { AfterContentInit, Directive, HostBinding, Input, Optional, QueryList } from '@angular/core';
import { ButtonDirective, ButtonGroupComponent } from '@progress/kendo-angular-buttons';
import { Subscription } from 'rxjs';

@Directive({
	selector: '[kpaButtonGroup]',
	exportAs: 'kpaButtonGroup',
})
export class KpaButtonGroupDirective implements AfterContentInit {
	private _toggleable: boolean = false;

	@Input('kpaToggleable')
	public get toggleable(): boolean {
		return this._toggleable;
	}
	public set toggleable(value: boolean) {
		if (this._toggleable == value) return;
		this._toggleable = value;
		this.onToggleableChanged();
	}

	@HostBinding('class.kpa-button-group')
	get decoratorClass(): boolean {
		return true;
	}

	get buttons(): QueryList<ButtonDirective> {
		return this.buttonGroup && this.buttonGroup.buttons;
	}

	private subscriptions: Subscription[] = [];

	constructor(@Optional() private readonly buttonGroup: ButtonGroupComponent) {
		if (!buttonGroup) throw 'The KpaButtonGroupDirective can only be applied to a Kendo ButtonGroup component.';
	}

	ngAfterContentInit(): void {
		this.buttons.changes.subscribe(() => this.onButtonsChanged());
		this.onButtonsChanged();
	}

	onToggleableChanged(): void {
		this.setBehaviors();
	}

	private onButtonsChanged(): void {
		this.unsubscribeAll();
		this.subscribeAll();
		this.setBehaviors();
	}

	private setBehaviors(): void {
		if (!this.buttons) return;
		this.buttons.forEach((button) => {
			button.primary = true;
			button.look = button.selected ? 'flat' : 'outline';
			button.toggleable = this.toggleable;
		});
	}

	private unsubscribeAll(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.subscriptions = [];
	}

	private subscribeAll(): void {
		if (!this.buttons) return;
		this.buttons.forEach((button) => {
			this.subscriptions.push(
				button.selectedChange.subscribe(() => {
					button.look = button.selected ? 'flat' : 'outline';
				})
			);
			button.primary = button.selected;
		});
	}
}
