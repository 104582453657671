import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'kpa-error-card',
	templateUrl: './kpa-error-card.component.html',
	styleUrls: ['./kpa-error-card.component.css'],
})
export class KpaErrorCardComponent implements OnInit {
	@Input()
	public mainTitle: string;

	@Input()
	public id: string;

	constructor() {}

	ngOnInit() {}
}
