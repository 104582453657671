import { Component, OnInit } from '@angular/core';
import { Environment } from '@env/environment';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
	constructor(private readonly environment: Environment) {}
	ngOnInit() {
		window['thunderSf'] = this.environment.thunderSf;
		const thunderSfScript = document.getElementById('thunderSfScript');
		if (thunderSfScript) {
			thunderSfScript['src'] = this.environment.thunderSf.source;
		}
	}
}
