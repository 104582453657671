import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class KpaThemeSampleGuard implements CanActivate {
	constructor(private readonly environment: Environment) {}

	canActivate(
		_route: ActivatedRouteSnapshot,
		_state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return !this.environment.production;
	}
}
