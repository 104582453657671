<h1 id="h1_ConsultantsTitle">{{ viewTitle }}</h1>

<div>
	<div fxFlex>
		<form
			fxLayout="column"
			id="form_consultants"
			class="background-card">
			<div
				fxLayout="column"
				fxFlex="100%">
				<div
					fxLayout="row"
					fxFlex
					fxLayoutGap="60px"
					fxLayoutAlign="space-between"
					dir="ltr">
					<div
						fxFlex="70%"
						id="list_consultantsList"
						fxLayout="column">
						<div
							*ngFor="let consultant of consultants; let i = index"
							id="listItem_consultant_{{ i }}"
							fxFlex
							fxLayout="row"
							class="flexGroup"
							fxLayoutAlign="space-between center">
							<div
								fxFlex="37%"
								fxLayout="column"
								fxLayoutAlign="center">
								<div
									class="profile-pic"
									fxFlex
									fxLayoutAlign="center">
									<img
										class="{{ consultant.imgClass }}"
										id="img_consultantPicture_{{ i }}"
										[src]="consultant.apiUrl"
										alt="" />
								</div>
							</div>
							<div
								fxFlex="63%"
								fxLayout="column">
								<div
									fxFlex
									fxLayout="column"
									fxLayoutGap="10px"
									fxLayoutAlign="start"
									id="detailsGroup_consultantDetails_{{ i }}">
									<h3 id="h3_consultantName_{{ i }}">{{ consultant.firstName }} {{ consultant.lastName }}</h3>
									<p id="p_consultantDetails_{{ i }}">{{ consultant.lineofBusiness }}</p>
									<p id="p_consultantPhoneNumber_{{ i }}">
										<a href="tel:{{ consultant.phoneNumber }}">{{ consultant.phoneNumber | phone }}</a>
									</p>
									<p id="p_consultantEmail_{{ i }}">
										<a href="mailto:{{ consultant.email }}">{{ consultant.email }}</a>
									</p>
								</div>
							</div>
						</div>
						<div
							*ngIf="!dataLoaded"
							class="loading consultantsLoader">
							<app-kpa-inside-spinner></app-kpa-inside-spinner>
						</div>
					</div>
					<div
						fxFlex="30%"
						fxLayout="column"
						fxLayoutAlign="start end">
						<div
							fxFlex
							class="support-card"
							id="section_supportSection">
							<p
								id="p_supportHeader"
								class="support-header">
								Need technical support?
							</p>
							<a
								id="a_supportButton"
								class="support-button"
								[href]="supportLink">
								<span
									id="span_supportButtonText"
									class="support-button-text"
									>OPEN A SUPPORT TICKET</span
								>
							</a>
							<p
								id="p_supportParagraph"
								class="support-line">
								or email or call us at:
							</p>
							<p>
								<a
									id="a_supportEmail"
									href="mailto:Support@kpa.io"
									>Support&#64;kpa.io</a
								>
							</p>
							<p>
								<a
									id="a_supportPhoneNumber"
									href="tel:8004860400"
									>(800) 486-0400</a
								>
							</p>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
