export const gridData = [
	{
		ProductID: 1,
		ProductName: 'Chai',
		SupplierID: 1,
		CategoryID: 1,
		QuantityPerUnit: '10 boxes x 20 bags',
		UnitPrice: 18,
		UnitsInStock: 39,
		UnitsOnOrder: 0,
		AssignedTo: 'firstname1 lastname1',
		AssignedToId: '1',
		ReviewedById: '11',
		ReviewedBy: 'firstname11 lastname11',
		ReorderLevel: 10,
		Discontinued: false,
		Category: {
			CategoryID: 1,
			CategoryName: 'Beverages',
			Description: 'Soft drinks, coffees, teas, beers, and ales',
		},
		FirstOrderedOn: new Date(1996, 8, 20),
		LastOrderedOn: new Date(2021, 8, 20),
	},
	{
		ProductID: 2,
		ProductName: 'Chang',
		SupplierID: 1,
		CategoryID: 1,
		QuantityPerUnit: '24 - 12 oz bottles',
		UnitPrice: 19,
		UnitsInStock: 17,
		UnitsOnOrder: 40,
		AssignedToId: '2',
		ReviewedById: '22',
		AssignedTo: 'firstname2 lastname2',
		ReviewedBy: 'firstname22 lastname22',
		ReorderLevel: 25,
		Discontinued: false,
		Category: {
			CategoryID: 1,
			CategoryName: 'Beverages',
			Description: 'Soft drinks, coffees, teas, beers, and ales',
		},
		FirstOrderedOn: new Date(1996, 7, 12),
		LastOrderedOn: new Date(2021, 8, 20),
	},
	{
		ProductID: 3,
		ProductName: 'Aniseed Syrup',
		SupplierID: 1,
		CategoryID: 2,
		QuantityPerUnit: '12 - 550 ml bottles',
		UnitPrice: 10,
		UnitsInStock: 13,
		AssignedTo: 'firstname3 lastname3',
		ReviewedBy: 'firstname33 lastname33',
		AssignedToId: '3',
		ReviewedById: '33',
		UnitsOnOrder: 70,
		ReorderLevel: 25,
		Discontinued: false,
		Category: {
			CategoryID: 2,
			CategoryName: 'Condiments',
			Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
		},
		FirstOrderedOn: new Date(1996, 8, 26),
		LastOrderedOn: new Date(2021, 8, 20),
	},
	{
		ProductID: 4,
		ProductName: "Chef Anton's Cajun Seasoning",
		SupplierID: 2,
		CategoryID: 2,
		QuantityPerUnit: '48 - 6 oz jars',
		UnitPrice: 22,
		UnitsInStock: 53,
		AssignedToId: '4',
		ReviewedById: '44',
		AssignedTo: 'firstname4 lastname4',
		ReviewedBy: 'firstname44 lastname44',
		UnitsOnOrder: 0,
		ReorderLevel: 0,
		Discontinued: false,
		Category: {
			CategoryID: 2,
			CategoryName: 'Condiments',
			Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
		},
		FirstOrderedOn: new Date(1996, 9, 19),
		LastOrderedOn: new Date(2021, 8, 20),
	},
	{
		ProductID: 5,
		ProductName: "Chef Anton's Gumbo Mix",
		SupplierID: 2,
		CategoryID: 2,
		QuantityPerUnit: '36 boxes',
		UnitPrice: 21.35,
		UnitsInStock: 0,
		AssignedToId: '5',
		ReviewedById: '55',
		AssignedTo: 'firstname5 lastname5',
		ReviewedBy: 'firstname55 lastname55',
		UnitsOnOrder: 0,
		ReorderLevel: 0,
		Discontinued: true,
		Category: {
			CategoryID: 2,
			CategoryName: 'Condiments',
			Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
		},
		FirstOrderedOn: new Date(1996, 7, 17),
		LastOrderedOn: new Date(2021, 8, 20),
	},
	{
		ProductID: 6,
		ProductName: "Grandma's Boysenberry Spread",
		SupplierID: 3,
		CategoryID: 2,
		QuantityPerUnit: '12 - 8 oz jars',
		UnitPrice: 25,
		UnitsInStock: 120,
		AssignedToId: '6',
		ReviewedById: '66',
		AssignedTo: 'firstname6 lastname6',
		ReviewedBy: 'firstname66 lastname66',
		UnitsOnOrder: 0,
		ReorderLevel: 25,
		Discontinued: false,
		Category: {
			CategoryID: 2,
			CategoryName: 'Condiments',
			Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
		},
		FirstOrderedOn: new Date(1996, 9, 19),
		LastOrderedOn: new Date(2021, 8, 20),
	},
	{
		ProductID: 7,
		ProductName: "Uncle Bob's Organic Dried Pears",
		SupplierID: 3,
		CategoryID: 7,
		QuantityPerUnit: '12 - 1 lb pkgs.',
		UnitPrice: 30,
		UnitsInStock: 15,
		AssignedToId: '7',
		ReviewedById: '77',
		AssignedTo: 'firstname7 lastname7',
		ReviewedBy: 'firstname77 lastname77',
		UnitsOnOrder: 0,
		ReorderLevel: 10,
		Discontinued: false,
		Category: {
			CategoryID: 7,
			CategoryName: 'Produce',
			Description: 'Dried fruit and bean curd',
		},
		FirstOrderedOn: new Date(1996, 7, 22),
		LastOrderedOn: new Date(2021, 8, 20),
	},
	{
		ProductID: 8,
		ProductName: 'Northwoods Cranberry Sauce',
		SupplierID: 3,
		CategoryID: 2,
		QuantityPerUnit: '12 - 12 oz jars',
		UnitPrice: 40,
		UnitsInStock: 6,
		AssignedToId: '8',
		ReviewedById: '88',
		AssignedTo: 'firstname8 lastname8',
		ReviewedBy: 'firstname88 lastname88',
		UnitsOnOrder: 0,
		ReorderLevel: 0,
		Discontinued: false,
		Category: {
			CategoryID: 2,
			CategoryName: 'Condiments',
			Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
		},
		FirstOrderedOn: new Date(1996, 11, 1),
		LastOrderedOn: new Date(2021, 8, 20),
	},
	{
		ProductID: 9,
		ProductName: 'Mishi Kobe Niku',
		SupplierID: 4,
		CategoryID: 6,
		QuantityPerUnit: '18 - 500 g pkgs.',
		UnitPrice: 97,
		UnitsInStock: 29,
		AssignedToId: '9',
		ReviewedById: '99',
		AssignedTo: 'firstname9 lastname9',
		ReviewedBy: 'firstname99 lastname99',
		UnitsOnOrder: 0,
		ReorderLevel: 0,
		Discontinued: true,
		Category: {
			CategoryID: 6,
			CategoryName: 'Meat/Poultry',
			Description: 'Prepared meats',
		},
		FirstOrderedOn: new Date(1997, 1, 21),
		LastOrderedOn: new Date(2021, 8, 20),
	},
	{
		ProductID: 10,
		ProductName: 'Ikura',
		SupplierID: 4,
		CategoryID: 8,
		QuantityPerUnit: '12 - 200 ml jars',
		UnitPrice: 31,
		UnitsInStock: 31,
		AssignedToId: '10',
		ReviewedById: '101',
		AssignedTo: 'firstname10 lastname10',
		ReviewedBy: 'firstname101 lastname101',
		UnitsOnOrder: 0,
		ReorderLevel: 0,
		Discontinued: false,
		Category: {
			CategoryID: 8,
			CategoryName: 'Seafood',
			Description: 'Seaweed and fish',
		},
		FirstOrderedOn: new Date(1996, 8, 5),
		LastOrderedOn: new Date(2021, 8, 20),
	},
	{
		ProductID: 11,
		ProductName: 'Slap Ya Mama Original Blend Cajun Seasoning',
		SupplierID: 2,
		CategoryID: 2,
		QuantityPerUnit: '48 - 4 oz cans',
		UnitPrice: 22,
		UnitsInStock: 53,
		AssignedTo: 'firstname11 lastname11',
		ReviewedBy: 'firstname111 lastname111',
		AssignedToId: '11',
		ReviewedById: '111',
		UnitsOnOrder: 0,
		ReorderLevel: 0,
		Discontinued: false,
		Category: {
			CategoryID: 2,
			CategoryName: 'Condiments',
			Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
		},
		FirstOrderedOn: new Date(1996, 9, 19),
		LastOrderedOn: new Date(2021, 8, 20),
	},
	{
		ProductID: 12,
		ProductName: "Tony Chachere's Original Creole Seasoning",
		SupplierID: 2,
		CategoryID: 2,
		QuantityPerUnit: '48 - 6 oz cans',
		UnitPrice: 22,
		UnitsInStock: 53,
		AssignedToId: '12',
		ReviewedById: '112',
		AssignedTo: 'firstname12 lastname12',
		ReviewedBy: 'firstname112 lastname112',
		UnitsOnOrder: 0,
		ReorderLevel: 0,
		Discontinued: false,
		Category: {
			CategoryID: 2,
			CategoryName: 'Condiments',
			Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
		},
		FirstOrderedOn: new Date(1996, 9, 19),
		LastOrderedOn: new Date(2021, 8, 20),
	},
];
