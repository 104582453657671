import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';

@Component({
	selector: 'kpa-dismiss-message',
	templateUrl: './kpa-dismiss-message.component.html',
})
export class KpaDismissMessageComponent implements OnInit {
	@Input()
	public messageText: string;

	@Input()
	public increaseViewTime: boolean;

	@Input()
	public secondaryMessageText: string;

	@ViewChild('kpaNotificationTemplate', { read: TemplateRef, static: true })
	public notificationTemplate: TemplateRef<any>;

	@Input()
	public messageType: any;

	@Input()
	public horizontalPosition: any;

	@Input()
	public verticalPosition: any;

	@Input()
	public showIcon: any;

	public increasedTime = 10000;
	public displayTime = 4000;

	constructor(private notificationService: NotificationService) {}

	public show(): void {
		this.notificationService.show({
			content: this.notificationTemplate,
			position: { horizontal: this.horizontalPosition, vertical: this.verticalPosition },
			animation: { type: 'fade', duration: 300 },
			type: { style: this.messageType, icon: this.showIcon },
			hideAfter: this.increaseViewTime ? this.increasedTime : this.displayTime,
		});
	}
	public showAsPromise(): Promise<void> {
		this.show();
		return new Promise((resolve) => setTimeout(resolve, this.increaseViewTime ? this.increasedTime : this.displayTime));
	}

	ngOnInit() {}
}
