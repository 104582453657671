<div
	fxLayout="column"
	class="card kpa-info-card">
	<div
		fxLayout="row"
		fxLayoutAlign="space-between start"
		*ngIf="mainTitle"
		id="mainTitleContainer">
		<h1><i class="fal fa-circle-info"> </i> {{ mainTitle }}</h1>
	</div>
	<div
		fxLayout="row"
		class="card-body-wrap">
		<div class="card-body">
			<ng-content></ng-content>
		</div>
	</div>
	<div
		fxLayout="row"
		fxLayoutAlign="end center"
		class="card-foot"></div>
</div>
