import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SecurityService } from '@app/core/services/security.service';
import { ConsultantsService } from '../shared/services/consultants.service';
import { ConsultantDto } from '../shared/models/ConsultantDto';
import { LineOfBusinessEnum } from '../shared/models/LineOfBusinessEnum';
import { NavigationService } from '../shared/services/navigation.service';

@Component({
	selector: 'app-consultants',
	templateUrl: './consultants.component.html',
	styleUrls: ['./consultants.component.css'],
})
export class ConsultantsComponent implements OnInit {
	viewTitle: string;
	consultants: ConsultantDto[] = [];
	ListOfConsultants: ConsultantDto[] = [];
	supportLink: string = window.location + '#0'; //default to do nothing until the correct url is pulled from the navigation api
	public dataLoaded = false;

	constructor(
		private titleService: Title,
		private readonly securityService: SecurityService,
		private readonly consultantsService: ConsultantsService,
		private readonly navigationService: NavigationService
	) {}

	ngOnInit() {
		this.titleService.setTitle('Consultants');
		this.viewTitle = 'Your Consultants';
		this.loadConsultants();
		this.getSupportLink();
	}

	/**
	 * Retrieves current user's primary location serviceToId then calls function to get the consultants
	 */
	private loadConsultants() {
		this.securityService.getMyUserInfo().subscribe((obj) => {
			this.loadServiceToConsultants(obj.primaryLocationServiceToId);
		});
	}

	/**
	 * Retrieves ConsultantDtos and populates their data and images
	 * @param primaryLocationServiceToId
	 */
	private loadServiceToConsultants(primaryLocationServiceToId) {
		this.consultantsService.getConsultants(primaryLocationServiceToId).subscribe((obj) => {
			this.consultants = obj.sort((a, b) => {
				if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
					return -1;
				}
				if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
					return 1;
				}
				return 0;
			});
			this.ListOfConsultants = this.consultants;
			const key = 'consultantId';
			this.consultants = [...new Map(this.consultants.map((item) => [item[key], item])).values()];
			this.consultants.map((item) => {
				this.ListOfConsultants.map((item1) => {
					if (
						item.consultantId === item1.consultantId &&
						item.lineofBusiness !== item1.lineofBusiness &&
						!item.lineofBusiness.includes(item1.lineofBusiness)
					) {
						let PrimaryLOB = item.lineofBusiness;
						let SecondaryLOB = item1.lineofBusiness;
						let LOB = '';
						if (item.consultantType == 'PrimaryConsultant') {
							LOB = PrimaryLOB + ', ' + SecondaryLOB;
						} else {
							LOB = SecondaryLOB + ', ' + PrimaryLOB;
						}

						item.lineofBusiness = LOB;
						return this.consultants;
					}
				});
			});
			this.consultants.forEach((e) => {
				this.consultantsService.getPhoto(e.consultantId).subscribe(
					(url) => {
						e.apiUrl = url;
						e.imgClass = 'profile-pic-img';
					},
					(err) => {
						e.apiUrl = './assets/images/emptyImage.png';
						e.imgClass = 'profile-pic-empty-img';
					}
				);
			});

			this.dataLoaded = true;
		});
	}

	private getSupportLink() {
		this.navigationService.getTopNodes().subscribe((topData) => {
			const supportNode = topData.find((n) => n.name == 'Top:Support');
			if (supportNode) {
				const platformSupportNode = supportNode.childNodes.find((n) => n.name == 'Platform:Top:Question:Support');
				if (platformSupportNode) {
					this.supportLink = platformSupportNode.fullUrl;
				}
			}
		});
	}
}
