export class StringConstants {
	public static savingScreenStatus = 'saving';
	public static invalidScreenStatus = 'invalid';
	public static loadingScreenStatus = 'loading';
	public static loadingMessage = 'Loading...';
	public static saveSuccessMessage = 'Successful Save';
	public static gridNotLoadedMessage = 'Enter a search term above to see results.';
	public static noDataFoundMessage = 'No records available.';
	public static errorMessage = 'An unexpected error occurred while retrieving tasks';
}
