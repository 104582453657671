import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class DateHttpInterceptor implements HttpInterceptor {
	iso8601 = /^(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})(\.\d+)?([+-](\d{2})\:(\d{2})|Z)?$/;

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap((event) => {
				if (event instanceof HttpResponse) {
					const body = event.body;
					this.convertToDate(body);
				}
			})
		);
	}

	convertToDate(body) {
		if (body === null || body === undefined) {
			return body;
		}

		if (typeof body !== 'object') {
			return body;
		}

		for (const key of Object.keys(body)) {
			const value = body[key];
			if (this.isIso8601(value)) {
				body[key] = new Date(value);
			} else if (typeof value === 'object') {
				this.convertToDate(value);
			}
		}
	}

	isIso8601(value) {
		if (value === null || value === undefined) {
			return false;
		}

		return this.iso8601.test(value);
	}
}
