import { Injectable, ErrorHandler, isDevMode } from '@angular/core';
import { LogService } from './log.service';

@Injectable({
	providedIn: 'root',
})
export class AppErrorHandler implements ErrorHandler {
	constructor(private readonly logService: LogService) {}

	handleError(error: any): void {
		try {
			if (isDevMode()) {
				console.log(error);
			}
			if (error.message) this.logService.logError(error.message);
		} catch (ex) {
			// Swallow
		}
	}
}
