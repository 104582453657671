<app-kpa-spinner></app-kpa-spinner>
<div id="profile-settings">
	<h1>Profile Settings</h1>
	<div *ngIf="showPasswordUsernameErrorMessage">
		<kpa-error-card [mainTitle]="passwordUsernameErrorMessageTitle">
			{{ passwordUsernameErrorMessage }}
		</kpa-error-card>
	</div>

	<div *ngIf="showPasswordUsernameSuccessMessage">
		<kpa-success-card [mainTitle]="'Success!'"> Successfully Updated Credentials. </kpa-success-card>
	</div>

	<div *ngIf="showPreferredContactErrorMessage">
		<kpa-error-card [mainTitle]="'Unable to Save Preferred Contact'">
			{{ preferredContactErrorMessage }}
		</kpa-error-card>
	</div>

	<div *ngIf="showPreferredContactSuccessMessage">
		<kpa-success-card [mainTitle]="'Success!'"> Successfully Updated Preferred Contact. </kpa-success-card>
	</div>

	<kpa-card>
		<form [formGroup]="profileForm">
			<!-- Product Preference -->
			<div class="row-strip-product">
				<h2>Product Preference</h2>
				<div
					fxLayout="row"
					class="row-strip">
					<div
						fxLayout="column"
						class="row-strip-contents">
						<div>
							<p>Use the dropdown below to select your product preference, then select Save.</p>
						</div>
						<div>
							<kendo-dropdownlist
								id="productList-dropdown"
								class="dropdown-list"
								[data]="productListData"
								[value]="productListSelected"></kendo-dropdownlist>
						</div>
					</div>

					<div class="row-strip-info">
						<p class="row-strip-info-text">
							The Product Preference will determine what information is displayed first on your dashboard, and the default product filter
							for certain pages, like the Issues List.
						</p>
					</div>
				</div>
			</div>

			<!-- Profile Username -->
			<div class="row-strip-username">
				<h2>Username</h2>
				<div
					*ngIf="!isSSOEnabled"
					fxLayout="row"
					class="row-strip">
					<div
						fxLayout="column"
						class="row-strip-contents">
						<div>
							<p>Edit your username below, then select Save.</p>
						</div>
						<div>
							<!-- Username -->
							<div>
								<kendo-floatinglabel
									id="profile-username-textbox"
									class="profile-username-textbox"
									text="Username">
									<input
										kendoTextBox
										formControlName="username" />
								</kendo-floatinglabel>
								<validation-errors [control]="profileForm.get('username')"></validation-errors>
							</div>
						</div>
					</div>

					<div class="row-strip-info">
						<p class="row-strip-info-text">
							If you decide to change your username, make sure it's something easy to remember - you'll need it next time you log in!
						</p>
					</div>
				</div>
				<div
					*ngIf="isSSOEnabled"
					fxLayout="row"
					class="row-strip">
					<div class="row-strip-info-text">
						<div class="warning-message">
							<div class="warning-message-icon">
								<i class="fa-light fa-circle-exclamation fa-2x"></i>
							</div>
							You are using Single Sign On between Vera Suite and your Company.<br />
							Please work with your company administrator to change your username or password.
						</div>
					</div>
				</div>
			</div>

			<!-- Profile Password -->
			<div
				[ngClass]="{
					'row-strip-password-last-section': showPreferredCommunicationMethodSection == false,
					'row-strip-password': showPreferredCommunicationMethodSection == true
				}">
				<h2>Password</h2>
				<div
					*ngIf="!isSSOEnabled"
					fxLayout="row"
					class="row-strip">
					<div
						fxLayout="column"
						class="row-strip-contents">
						<div>
							<p>Enter your current password and the new password, then select Save.</p>
						</div>
						<div>
							<!-- Current Password -->
							<div>
								<kendo-floatinglabel
									id="profile-currentPassword-textbox"
									class="profile-currentPassword-textbox"
									text="Current Password"
									appShowHidePassword>
									<input
										kendoTextBox
										type="password"
										formControlName="currentPassword" />
									<validation-errors [control]="profileForm.get('currentPassword')"></validation-errors>
								</kendo-floatinglabel>
								<validation-errors [control]="profileForm.get('username')"></validation-errors>
							</div>

							<!-- New Password -->
							<div>
								<kendo-floatinglabel
									id="profile-newPassword-textbox"
									class="profile-newPassword-textbox"
									text="New Password"
									appShowHidePassword>
									<input
										kendoTextBox
										type="password"
										formControlName="newPassword" />
									<validation-errors [control]="profileForm.get('newPassword')"></validation-errors>
								</kendo-floatinglabel>
								<validation-errors [control]="profileForm.get('username')"></validation-errors>
							</div>

							<!-- Confirm Password -->
							<div>
								<kendo-floatinglabel
									id="profile-confirmPassword-textbox"
									class="profile-confirmPassword-textbox"
									text="Confirm Password"
									appShowHidePassword>
									<input
										kendoTextBox
										type="password"
										formControlName="confirmPassword" />
									<validation-errors [control]="profileForm.get('confirmPassword')"></validation-errors>
								</kendo-floatinglabel>
								<validation-errors [control]="profileForm.get('username')"></validation-errors>
							</div>
						</div>
					</div>

					<div class="row-strip-info">
						<div class="row-strip-info-text">
							<p>Passwords must be at least 10 characters, and contain each of the following:</p>
							<ul style="list-style-type: disc">
								<li>a lower case letter</li>
								<li>an upper case letter</li>
								<li>a number</li>
								<li>a special character (ex: !, ?, &, %)</li>
							</ul>
						</div>
					</div>
				</div>
				<div
					*ngIf="isSSOEnabled"
					fxLayout="row"
					class="row-strip">
					<div class="row-strip-info-text">
						<div class="warning-message">
							<div class="warning-message-icon">
								<i class="fa-light fa-circle-exclamation fa-2x"></i>
							</div>
							You are using Single Sign On between Vera Suite and your Company.<br />
							Please work with your company administrator to change your username or password.
						</div>
					</div>
				</div>
			</div>
			<!-- Preferred Contact Method -->
			<div
				class="row-strip-preferred-contact"
				*ngIf="showPreferredCommunicationMethodSection">
				<h2>Preferred Contact Method</h2>
				<div
					fxLayout="row"
					class="row-strip">
					<div
						fxLayout="column"
						class="row-strip-contents">
						<div>
							<p>Select your preferred contact method</p>
						</div>
						<div [formGroup]="emailRegType">
							<div *ngIf="showWorkEmailRadio">
								<input
									kendoRadioButton
									#inpRadio_WorkEmail_UCL
									formControlName="regType"
									type="radio"
									name="regType"
									id="inpRadio_WorkEmail_UCL"
									class="k-radio"
									value="3" />
								<label
									class="k-radio-label"
									id="inpRadioLabel_WorkEmail_UCL"
									for="inpRadio_WorkEmail_UCL"
									>{{ workEmailLabel }}</label
								>
							</div>
							<div *ngIf="showPersonalEmailRadio">
								<input
									kendoRadioButton
									#inpRadio_PersonalEmail_UCL
									formControlName="regType"
									type="radio"
									name="regType"
									id="inpRadio_PersonalEmail_UCL"
									class="k-radio"
									value="2" />
								<label
									class="k-radio-label"
									id="inpRadioLabel_PersonalEmail_UCL"
									for="inpRadio_PersonalEmail_UCL"
									>{{ personalEmailLabel }}</label
								>
							</div>
							<div *ngIf="showPhoneRadio">
								<input
									kendoRadioButton
									#inpRadio_CellPhone_UCL
									formControlName="regType"
									type="radio"
									name="regType"
									id="inpRadio_CellPhone_UCL"
									class="k-radio"
									value="1" />
								<label
									class="k-radio-label"
									id="inpRadioLabel_CellPhone_UCL"
									for="inpRadio_CellPhone_UCL"
									>{{ cellPhoneLabel }}</label
								>
							</div>
						</div>
					</div>
					<div class="row-strip-info">
						<div class="row-strip-info-text">
							<p>This will be used for password reset requests</p>
						</div>
					</div>
				</div>
			</div>
		</form>

		<!-- Buttons -->
		<div
			fxLayout="row"
			fxLayoutAlign="end"
			fxLayoutGap="15px">
			<button
				id="profile-btn-cancel"
				kendoButton
				kpaSecondaryButton
				(click)="onCancel()">
				Cancel
			</button>
			<button
				id="profile-btn-save"
				kendoButton
				kpaPrimaryButton
				(click)="onSave()">
				Save
			</button>
		</div>
	</kpa-card>
</div>
