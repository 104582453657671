<kendo-dialog
	id="discard-dialog-prompt"
	(close)="onCancel()"
	[minWidth]="250"
	[height]="200"
	[width]="450"
	title="Unsaved Changes">
	<div>
		<p id="discard-dialog-prompt-txt">You have unsaved changes, are you sure you want to discard the changes?</p>
	</div>

	<kendo-dialog-actions [layout]="actionsLayout">
		<button
			id="discard-dialog-prompt-btn-cancel"
			kendoButton
			kpaSecondaryButton
			(click)="onCancel()">
			No
		</button>

		<button
			id="discard-dialog-prompt-btn-confirm"
			kendoButton
			kpaPrimaryButton
			(click)="onConfirm()"
			themeColor="primary">
			Yes, Discard
		</button>
	</kendo-dialog-actions>
</kendo-dialog>
