import { Directive, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { DataBindingDirective, GridComponent, DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { anyChanged } from '@progress/kendo-angular-common';
import { Subscription } from 'rxjs';

import { ODataBaseBinding } from './odata-base-binding.service';
import { SortDescriptor } from '@progress/kendo-data-query';

/**
 * Options for behavior of the ODataBindingDirective.
 */
export enum ODataBindingOptions {
	/**
	 * Default behavior. Bind on grid initialization and rebind on all state changes.
	 */
	None = 0,
	/**
	 * Require filters be present to perform a remote rebind. If filters are not present, an empty result is emitted on rebinding.
	 */
	RequireFilters = 1 << 0,
	/**
	 * Do not automatically perform rebind on a grid state change.
	 */
	RebindManually = 1 << 1,
}

/**
 * A directive to provide binding behavior between an OData data source and grid component.
 */
@Directive({
	selector: '[odataBinding]',
	exportAs: 'kpaODataBinding',
})
export class ODataBindingDirective extends DataBindingDirective implements OnInit, OnDestroy, OnChanges {
	/**
	 * The data source.
	 */
	@Input('odataBinding') private readonly dataSource: ODataBaseBinding;

	/**
	 * The binding behavior options.
	 */
	@Input() private readonly bindingOptions: ODataBindingOptions = ODataBindingOptions.None;

	/**
	 * The data source subscription.
	 */
	private serviceSubscription: Subscription;

	/**
	 * The grid sort change event subscription.
	 */
	private readonly sortChangeSubscription: Subscription;

	/**
	 * Has ngOnInit been called
	 */
	private ngOnInitCompleted: boolean = false;

	/**
	 * True if the bound grid's state has values in the filter collection; otherwise false.
	 */
	private get hasFilters(): boolean {
		return this.state.filter && this.state.filter.filters && this.state.filter.filters.length > 0;
	}

	/**
	 * Creates an ODataBindingDirective.
	 * @param grid The grid to bind to the data source.
	 */
	constructor(grid: GridComponent) {
		super(grid);
		this.sortChangeSubscription = this.bindSortChange();
	}

	/**
	 * Adds a listener for sort changes as this appears to be missing from the current binding implementation.
	 * Can be removed if patched in later package dependency versions.
	 */
	private bindSortChange(): Subscription {
		return this.grid.sortChange.subscribe((next: SortDescriptor[]) => this.ngOnChanges({ sort: new SimpleChange(null, next, false) }));
	}

	/**
	 * Initinalizes the data source subscription and binds immediately if desired.
	 */
	public ngOnInit(): void {
		if (this.dataSource != null) {
			this.serviceSubscription = this.dataSource.subscribe(
				(result) => this.pushResult(result),
				(err) => {
					this.grid.loading = false;
					throw err;
				}
			);

			super.ngOnInit();

			// Bind immediately if manual binding is not specified (method checks for required filter option)
			if (!this.hasOption(ODataBindingOptions.RebindManually)) {
				this.rebind();
			}
		}

		this.ngOnInitCompleted = true;
	}

	/**
	 * Cancels subscriptions.
	 */
	public ngOnDestroy(): void {
		if (this.sortChangeSubscription) {
			this.sortChangeSubscription.unsubscribe();
		}

		if (this.serviceSubscription) {
			this.serviceSubscription.unsubscribe();
		}

		super.ngOnDestroy();
	}

	/**
	 * Processes changes only if manual rebind is not requested.
	 * @param changes The changes.
	 */
	public ngOnChanges(changes: SimpleChanges): void {
		const hasFilterChange = changes['filter'] !== undefined;
		// ngOnChanges can be called before ngOnInit, but we want ngOnInit to handle the initial bind
		if (
			this.ngOnInitCompleted &&
			((!this.hasOption(ODataBindingOptions.RebindManually) && hasFilterChange) || // Prevent rebind on manual rebind flag
				anyChanged(['pageSize', 'skip', 'sort', 'group'], changes))
		) {
			// Allow auto rebind on pagination, sorting, and grouping changes
			this.rebind();
		}
	}

	/**
	 * Overrides superclass behavior of calling rebind twice.
	 * @param state The state.
	 */
	public onStateChange(state: DataStateChangeEvent): void {
		super.applyState(state);
	}

	/**
	 * Requests new data from the remote source based on behavior options.
	 */
	public rebind(): void {
		// Validate filters present if option specified.
		if (this.hasOption(ODataBindingOptions.RequireFilters) && !this.hasFilters) {
			this.pushResult({ data: [], total: 0 });
		}
		// Rebind to remote data
		else if (this.dataSource != null) {
			this.grid.loading = true;
			this.dataSource.query(this.state);
		}
	}

	/**
	 * Updates the grid binding with the result.
	 * @param result The grid data result.
	 */
	private pushResult(result: GridDataResult): void {
		this.grid.loading = false;
		this.grid.data = result;
		this.notifyDataChange();
	}

	/**
	 * True if this binding directive has the requested option; otherwise false.
	 * @param options The option to interrogate.
	 */
	private hasOption(options: ODataBindingOptions): boolean {
		return (this.bindingOptions & options) === options;
	}
}
