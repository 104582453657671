/**
 * A transient store intended to be associated with a particular user session.
 */
export abstract class EphemeralStorage {
	/**
	 * Creates ephemeral storage that will identify values using key, area, and session ID.
	 * @param areaPrefix A prefix to add to underlying storage keys to segment this store's data.
	 * @param storage The underlying storage.
	 */
	protected constructor(
		protected readonly areaPrefix: string,
		protected readonly storage: Storage
	) {
		if (!areaPrefix || !areaPrefix.length) throw new Error('A non-empty area prefix must be provided.');
		if (!storage) throw new Error('A storage instance must be provided.');

		this._keys = new Array<string>(storage.length)
			.fill(null)
			.map((_, i) => storage.key(i))
			.filter((k) => k.startsWith(areaPrefix));
	}

	private readonly _keys: string[];
	/**
	 * The keys in the underlying storage.
	 */
	protected get keys(): string[] {
		return this._keys;
	}

	/**
	 * The user session identifier.
	 */
	public get sessionId(): string {
		return this.storage.getItem(`${this.areaPrefix}.sessionId`);
	}
	private setSessionId(sessionId: string): void {
		const key = `${this.areaPrefix}.sessionId`;
		if (sessionId) this.storage.setItem(key, sessionId);
		else this.storage.removeItem(key);
	}

	private _initialized: boolean = false;
	/**
	 * True of the session has been initialized; otherwise false.
	 */
	public get initialized(): boolean {
		return this._initialized;
	}

	/**
	 * Initializes this store with the identifier for the user session.
	 * @param sessionId The session id to be used with this store.
	 */
	public initialize(sessionId: string): void {
		if (!sessionId || !sessionId.length) throw new Error('A non-empty session ID must be provided.');

		// Abort if this session has been initialized
		if (sessionId === this.sessionId) {
			this._initialized = true;
			return;
		}

		this.dispose();
		this.setSessionId(sessionId);
		this._initialized = true;
	}

	/**
	 * Removes all items associated with this store.
	 */
	public dispose(): void {
		this.keys.forEach((key) => this.storage.removeItem(key));
		this.setSessionId(null);
		this._initialized = false;
	}

	/**
	 * Checks if the store has been initialized.
	 */
	protected checkInit(): void {
		if (!this._initialized) throw new Error('This store must be initialized before it is accessed.');
	}

	/**
	 * Formats the key with metadata needed to uniquely identify access for this area and user session.
	 * @param key The original key.
	 * @returns The formatted key.
	 */
	private formatKey(key: string): string {
		if (!key || !key.length) throw new Error('A non-empty key must be provided.');

		return `${this.areaPrefix}.${key}.${this.sessionId}`;
	}

	/**
	 * Gets a value for a key.
	 * @param key The key for the value.
	 * @returns The string value for the key if it exists; otherwise null.
	 */
	protected getItem(key: string): string {
		this.checkInit();
		key = this.formatKey(key);

		return this.storage.getItem(key);
	}

	/**
	 * Gets a value for a key.
	 * @param key The key for the value.
	 * @returns The deserialized value for the key if it exists; otherwise null.
	 */
	protected getItemAs<T>(key: string): T {
		const value = this.getItem(key);
		return value ? (JSON.parse(value) as T) : null;
	}

	/**
	 * Sets a value for a key.
	 * @param key The key for the value.
	 * @param value The value.
	 */
	protected setItem(key: string, value: any): void {
		this.checkInit();
		key = this.formatKey(key);

		if (typeof value !== 'string') value = JSON.stringify(value);

		this.storage.setItem(key, value);
		this._keys.push(key);
	}

	/**
	 * Removes a value for a key if that key exists.
	 * @param key The key for the value.
	 */
	protected removeItem(key: string): void {
		this.checkInit();
		key = this.formatKey(key);

		var keyIdx = this._keys.indexOf(key);
		if (keyIdx === -1) return;
		this.storage.removeItem(key);
		this._keys.splice(keyIdx, 1);
	}
}
