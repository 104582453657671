import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, process, State } from '@progress/kendo-data-query';
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';
import { Subscription } from 'rxjs';
import { IKpaSelectionItemBinding } from '../dropdowns/directives/kpa-selection.directive';
import { gridData as sampleGridData } from './sample-grid-data';
import { Item } from '@app/shared/models/item';

@Component({
	selector: 'kpa-theme-sample',
	templateUrl: './sample.component.html',
	styleUrls: ['./sample.component.css'],
})
export class KpaThemeSampleComponent implements OnInit, OnDestroy {
	constructor() {}

	public range = { start: new Date(2018, 10, 10), end: new Date(2018, 10, 20) };

	dummyDisableFlag: boolean = false;
	emptyPlaceholder: Item = { text: '', value: null };
	employeesList = [
		{ text: 'Other Employee', value: 1 },
		{ text: 'John Doe', value: 2 },
		{ text: 'Jane Doe', value: 3 },
	];
	unFilteredEmployeeList = this.employeesList;

	/* 
  add intial employees then append second list on typing in control for multi select  
  EmployeeSearchAssignedTo even  you can change data assigned to multi select 
  */
	public employeesAssignedTo: any[] = [
		{ textField: 'firstname1 lastname1', AssignedToId: '1' },
		{ textField: 'firstname2 lastname2', AssignedToId: '2' },
		{ textField: 'firstname3 lastname3', AssignedToId: '3' },
		{ textField: 'firstname4 lastname4', AssignedToId: '4' },
	];

	public employeesAssignedTo1: any[] = [
		{ textField: 'firstname5 lastname5', AssignedToId: '5' },
		{ textField: 'firstname6 lastname6', AssignedToId: '6' },
		{ textField: 'firstname7 lastname7', AssignedToId: '7' },
		{ textField: 'firstname8 lastname8', AssignedToId: '8' },
	];
	public ngOnInit(): void {
		this.initDatePicker();
		this.initAutoComplete();
		this.initDropdown();
		this.initMultiselect();
		this.initTextbox();
		this.initTextarea();
	}

	public ngOnDestroy(): void {
		this.destroyDatePicker();
		this.destroyAutoComplete();
		this.destroyDropdown();
		this.destroyMultiselect();
		this.destroyTextbox();
		this.destroyTextarea();
	}

	/**
	 * Chip bindings
	 */
	public chips = [
		{
			label: 'None',
			icon: null,
			type: 'none',
			removable: true,
		},
		{
			label: 'Info',
			icon: 'information',
			type: 'info',
			removable: true,
		},
		{
			label: 'Success',
			icon: 'check-outline',
			type: 'success',
			removable: true,
		},
		{
			label: 'Warning',
			icon: 'warning',
			type: 'warning',
			removable: true,
		},
		{
			label: 'Error',
			icon: 'warning',
			type: 'error',
			removable: true,
		},
	];

	/**
	 * Date picker bindings
	 */
	public now = new Date();

	private get today(): Date {
		const d = new Date();
		d.setHours(0, 0, 0, 0);
		return d;
	}

	public datePickerValue: UntypedFormControl = new UntypedFormControl(this.today);
	public datePickerRequired: UntypedFormControl = new UntypedFormControl(true);
	public datePickerRequiredSubscription: Subscription;

	private get datePickerValidators(): ValidatorFn[] {
		const v: ValidatorFn[] = [
			(control) => {
				if (!control.value || (<Date>control.value).getTime() <= this.now.getTime()) return null;
				return { maxdate: 'The date exceeds the maximum date allowed.' };
			},
		];
		if (this.datePickerRequired.value === true) v.push(Validators.required);
		return v;
	}

	private setDatePickerValidators(): void {
		this.datePickerValue.setValidators(this.datePickerValidators);
		this.datePickerValue.updateValueAndValidity();
	}

	private initDatePicker(): void {
		this.setDatePickerValidators();
		this.datePickerRequiredSubscription = this.datePickerRequired.valueChanges.subscribe(() => {
			this.setDatePickerValidators();
		});
	}
	// event from multi select when you type something it will add second list
	EmployeeSearchAssignedTo($event) {
		// here you can filer based on $event.search
		this.employeesAssignedTo = this.employeesAssignedTo.concat(this.employeesAssignedTo1);
	}

	private destroyDatePicker(): void {
		this.datePickerRequiredSubscription.unsubscribe();
	}

	/**
	 * Autocomplete bindings
	 */
	public autoCompleteListItems: string[] = [
		'Albania',
		'Andorra',
		'Armenia',
		'Austria',
		'Azerbaijan',
		'Belarus',
		'Belgium',
		'Bosnia & Herzegovina',
		'Bulgaria',
		'Croatia',
		'Cyprus',
		'Czech Republic',
		'Denmark',
		'Estonia',
		'Finland',
		'France',
		'Georgia',
		'Germany',
		'Greece',
		'Hungary',
		'Iceland',
		'Ireland',
		'Italy',
		'Kosovo',
		'Latvia',
		'Liechtenstein',
		'Lithuania',
		'Luxembourg',
		'Macedonia',
		'Malta',
		'Moldova',
		'Monaco',
		'Montenegro',
		'Netherlands',
		'Norway',
		'Poland',
		'Portugal',
		'Romania',
		'Russia',
		'San Marino',
		'Serbia',
		'Slovakia',
		'Slovenia',
		'Spain',
		'Sweden',
		'Switzerland',
		'Turkey',
		'Ukraine',
		'United Kingdom',
		'Vatican City',
	];

	public autoCompleteValue: UntypedFormControl = new UntypedFormControl(null);
	public autoCompleteRequired: UntypedFormControl = new UntypedFormControl(true);
	private autoCompleteRequiredSubscription: Subscription;

	public autoCompleteFilterSettings: DropDownFilterSettings = {
		caseSensitive: false,
		operator: 'contains',
	};

	private get autoCompleteValidators(): ValidatorFn[] {
		const v: ValidatorFn[] = [
			(control) => {
				if (!control.value || this.autoCompleteListItems.includes(control.value)) return null;
				return { includes: 'The data collection does not include the current control value.' };
			},
		];
		if (this.autoCompleteRequired.value === true) v.push(Validators.required);
		return v;
	}

	private setAutoCompleteValidators(): void {
		this.autoCompleteValue.setValidators(this.autoCompleteValidators);
		this.autoCompleteValue.updateValueAndValidity();
	}

	private initAutoComplete(): void {
		this.setAutoCompleteValidators();
		this.autoCompleteRequiredSubscription = this.autoCompleteRequired.valueChanges.subscribe(() => {
			this.setAutoCompleteValidators();
		});
	}

	private destroyAutoComplete(): void {
		this.autoCompleteRequiredSubscription.unsubscribe();
	}

	/**
	 * Dropdown List Bindings
	 */

	public dropdownItems: IKpaSelectionItemBinding<number>[] = [
		{ text: 'X-Small', value: 1 },
		{ text: 'Small', value: 2 },
		{ text: 'Medium', value: 3 },
		{ text: 'Large', value: 4 },
		{ text: 'X-Large', value: 5 },
		{ text: '2X-Large', value: 6 },
	];

	public dropdownValue: UntypedFormControl = new UntypedFormControl(null);
	public dropdownRequired: UntypedFormControl = new UntypedFormControl(true);
	private dropdownRequiredSubscription: Subscription;

	private get dropdownValidators(): ValidatorFn[] {
		const v: ValidatorFn[] = [];
		if (this.dropdownRequired.value === true) v.push(Validators.required);
		return v;
	}

	private setDropdownValidators(): void {
		this.dropdownValue.setValidators(this.dropdownValidators);
		this.dropdownValue.updateValueAndValidity();
	}

	private initDropdown(): void {
		this.setDropdownValidators();
		this.dropdownRequiredSubscription = this.dropdownRequired.valueChanges.subscribe(() => {
			this.setDropdownValidators();
		});
	}

	private destroyDropdown(): void {
		this.dropdownRequiredSubscription.unsubscribe();
	}

	/**
	 * Multiselect Bindings
	 */

	public multiselectItems: IKpaSelectionItemBinding<number>[] = [
		{ text: 'Small', value: 1 },
		{ text: 'Medium', value: 2 },
		{ text: 'Large', value: 3 },
		{ text: 'XL', value: 4 },
		{ text: 'XXL', value: 5 },
	];

	public multiselectRequired: UntypedFormControl = new UntypedFormControl(true);
	public multiselectValue: UntypedFormControl = new UntypedFormControl([]);
	private multiselectRequiredSubscription: Subscription;

	private get multiselectValidators(): ValidatorFn[] {
		const v: ValidatorFn[] = [Validators.maxLength(3)];
		if (this.multiselectRequired.value === true) v.push(Validators.required);
		return v;
	}

	private setMultiselectValidators(): void {
		this.multiselectValue.setValidators(this.multiselectValidators);
		this.multiselectValue.updateValueAndValidity();
	}

	private initMultiselect(): void {
		this.setMultiselectValidators();
		this.multiselectRequiredSubscription = this.multiselectRequired.valueChanges.subscribe(() => {
			this.setMultiselectValidators();
		});

		// Do value initialization after first frame as Kendo fails under certain init conditions
		setTimeout(() => {
			this.multiselectValue.setValue([2]);
		});
	}

	private destroyMultiselect(): void {
		this.multiselectRequiredSubscription.unsubscribe();
	}

	/**
	 * Textbox Bindings
	 */

	public textboxRequired: UntypedFormControl = new UntypedFormControl(true);
	public textboxValue: UntypedFormControl = new UntypedFormControl('80026');
	public textboxRequiredSubscription: Subscription;

	private get textboxValidators(): ValidatorFn[] {
		const v = [Validators.pattern(/^\d{5}$/)];
		if (this.textboxRequired.value === true) v.push(Validators.required);
		return v;
	}

	private setTextboxValidators(): void {
		this.textboxValue.setValidators(this.textboxValidators);
		this.textboxValue.updateValueAndValidity();
	}

	private initTextbox(): void {
		this.setTextboxValidators();
		this.textboxRequiredSubscription = this.textboxRequired.valueChanges.subscribe(() => {
			this.setTextboxValidators();
		});
	}

	private destroyTextbox(): void {
		this.textboxRequiredSubscription.unsubscribe();
	}

	/**
	 * Textarea Bindings
	 */

	private readonly textareaContent: string = `Spicy jalapeno bacon ipsum dolor amet duis porchetta corned beef, aliqua jowl quis dolore consequat lorem. Shank consectetur 
    sausage aliqua laborum leberkas sint. Salami kevin ut hamburger commodo jerky biltong incididunt ut velit. Irure ex enim rump, 
    anim jerky consectetur fatback pig boudin cillum jowl proident. Non esse pork loin anim pork belly.`;

	public textareaRequired: UntypedFormControl = new UntypedFormControl(true);
	public textareaValue: UntypedFormControl = new UntypedFormControl(this.textareaContent.replace(/\s+/g, ' '));
	public textareaRequiredSubscription: Subscription;

	private get textareaValidators(): ValidatorFn[] {
		const v = [];
		if (this.textareaRequired.value === true) v.push(Validators.required);
		return v;
	}

	private setTextareaValidators(): void {
		this.textareaValue.setValidators(this.textareaValidators);
		this.textareaValue.updateValueAndValidity();
	}

	private initTextarea(): void {
		this.setTextareaValidators();
		this.textareaRequiredSubscription = this.textareaRequired.valueChanges.subscribe(() => {
			this.setTextareaValidators();
		});
	}

	private destroyTextarea(): void {
		this.textareaRequiredSubscription.unsubscribe();
	}

	/**
	 * Grid Bindings
	 */

	public gridState: State = {
		skip: 0,
		take: 5,
		filter: {
			logic: 'and',
			filters: [{ field: 'ProductName', operator: 'contains', value: 'Chef' }],
		},
	};

	public gridData: GridDataResult = process(sampleGridData, this.gridState);

	public gridDataStateChange(state: DataStateChangeEvent): void {
		this.gridState = state;
		this.gridData = process(sampleGridData, this.gridState);
	}
	dummyOnClick() {}
	dummyOnChange(event) {}
	handleFilter(value) {
		this.employeesList = this.unFilteredEmployeeList.filter((s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1);
	}

	/**
	 * Panel Bar gridData
	 */
	public expandMode: PanelBarExpandMode = PanelBarExpandMode.Single;

	/**
	 * Tooltip Bindings
	 */
	public tooltipPosition: UntypedFormControl = new UntypedFormControl('top');
}
