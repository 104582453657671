<div
	fxLayout="column"
	class="card kpa-status-card {{ state }}">
	<div
		fxLayout="row"
		fxLayoutAlign="space-between start"
		*ngIf="mainTitle"
		id="mainTitleContainer"
		class="{{ state }}">
		<h1>
			<i
				class="fal"
				[ngClass]="{
					'fa-circle-exclamation': stateClass == true,
					'fa-triangle-exclamation': stateClass == false
				}">
			</i
			>{{ mainTitle }}
		</h1>
		<h1 class="close">
			<span
				class="fal fa-times"
				(click)="hide()"></span>
		</h1>
	</div>
	<div
		fxLayout="row"
		class="card-body-wrap">
		<div class="card-body">
			<ng-content></ng-content>
		</div>
	</div>
	<div
		fxLayout="row"
		fxLayoutAlign="end center"
		class="card-foot"></div>
</div>
