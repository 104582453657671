import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class AccountManagementAuthGuardService implements CanActivate {
	constructor(private readonly authService: AuthService) {}

	public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.authService.isLoggedIn.pipe(
			tap((isLoggedIn) => {
				if (!isLoggedIn) {
					this.authService.startAuthenticationAsync(state.url);
				}
			})
		);
	}
}
