import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'kpa-button-group',
	templateUrl: './button-group.component.html',
})
export class KpaButtonGroupComponent {
	@Input() buttonGroup: any;

	public constructor() {}

	checkIconToDisplay(selectionValue: boolean) {
		return selectionValue === true ? 'check' : '';
	}
}
