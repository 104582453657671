import { Directive, HostBinding, KeyValueDiffers, Optional } from '@angular/core';
import { DropDownButtonComponent } from '@progress/kendo-angular-buttons';
import { KpaMonitorChanges } from '../../shared/kpa-monitor-changes';

@Directive({
	selector: '[kpaDropDownButton]',
	exportAs: 'kpaDropDownButton',
})
export class KpaDropDownButtonDirective extends KpaMonitorChanges<DropDownButtonComponent> {
	@HostBinding('class.kpa-dropdown-button')
	public get decoratorClass(): boolean {
		return true;
	}

	constructor(
		@Optional() private readonly button: DropDownButtonComponent,
		diff: KeyValueDiffers
	) {
		// todo: address
		super(diff, button, 'icon');

		if (!button) throw 'The KpaDropDownButtonDirective can only be applied to a Kendo DropDownButtonComponent component.';
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	setBehaviors(): void {
		this.button.icon = 'arrow-s';
		this.button.fillMode = 'outline';
		this.button.themeColor = 'primary';
	}
}
