<div
	fxLayout="row"
	class=""
	class="item-field status-badge inline">
	<div
		fxLayout="column"
		fxFlex="0 0 40%"
		class="align-items">
		<span *ngIf="label != null && label != ''"> {{ label }} </span>
	</div>
	<div
		fxLayout="column"
		fxFlex="0 0 40%"
		class="align-items">
		<div
			*ngIf="warning"
			class="warning">
			Fair
		</div>
		<div
			*ngIf="passing"
			class="passing">
			Good
		</div>
		<div
			*ngIf="failing"
			class="failing">
			Poor
		</div>
	</div>
</div>
