<div *ngIf="dirty && errors">
	<div
		*ngIf="errors.required"
		class="invalid-feedback">
		This value is required.
	</div>
	<div
		*ngIf="errors.min"
		class="invalid-feedback">
		The value must be greater than {{ errors.min.exclusive ? 'but not' : 'or' }} equal to {{ errors.min.min }}.
	</div>
	<div
		*ngIf="errors.max"
		class="invalid-feedback">
		The value must be less than {{ errors.max.exclusive ? 'but not' : 'or' }} equal to {{ errors.max.max }}.
	</div>
	<div
		*ngIf="errors.minlength"
		class="invalid-feedback">
		The value must have at least {{ errors.minlength.requiredLength }} characters.
	</div>
	<div
		*ngIf="errors.maxlength"
		class="invalid-feedback">
		The value may have {{ errors.maxlength.requiredLength }} characters at most.
	</div>
	<div
		*ngIf="errors.email"
		class="invalid-feedback">
		The value is an invalid email.
	</div>
	<div
		*ngIf="
			errors.pattern &&
			controlName !== 'questionValue' &&
			controlName !== 'ssoUsername' &&
			controlName !== 'primaryContactPhoneNumber' &&
			controlName !== 'zip'
		"
		class="invalid-feedback">
		The value doesn't match the required pattern.
	</div>
	<div
		*ngIf="errors.pattern && controlName !== 'questionValue' && controlName === 'ssoUsername'"
		class="invalid-feedback">
		The SSO Username doesn't match the required pattern.
	</div>
	<div
		*ngIf="errors.pattern && controlName === 'questionValue'"
		class="invalid-feedback">
		Please enter in a number.
	</div>
	<div
		*ngIf="errors.pattern && controlName === 'zip'"
		class="invalid-feedback">
		Please enter a valid ZIP Code.
	</div>
	<div
		*ngIf="errors.pattern && controlName === 'primaryContactPhoneNumber'"
		class="invalid-feedback">
		Phone number not valid.
	</div>
	<div
		*ngIf="errors.uiErrors"
		class="invalid-feedback">
		{{ errors.message }}
	</div>
	<div
		*ngIf="errors.minError"
		class="invalid-feedback">
		The value must be higher than {{ errors.minError.minValue | date: 'MM/dd/yyyy' }}, and in the format MM/DD/YYYY.
	</div>
	<div
		*ngIf="errors.maxError"
		class="invalid-feedback">
		The value must be lower than {{ errors.maxError.maxValue | date: 'MM/dd/yyyy' }}, and in the format MM/DD/YYYY.
	</div>
	<ng-container *ngIf="errors.apiErrors">
		<ng-container *ngFor="let message of errors.apiErrors">
			<div class="invalid-feedback">{{ message }}</div>
		</ng-container>
	</ng-container>
</div>
