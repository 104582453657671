import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { BaseApiClient } from '@app/core/services/base-api-client.service';
import { catchError, tap } from 'rxjs/operators';
import { LogService } from '@app/core/services/log.service';
import { Environment } from '@env/environment';
import { LoadingIndicatorService } from '@app/core/services/loading-indicator.service';

const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
export interface ISuggestionService {
	url: string;
	postSuggestion(data: any, serviceToUniversalId: string): Observable<any>;
}
@Injectable({
	providedIn: 'root',
})
export class SuggestionService extends BaseApiClient implements ISuggestionService {
	public baseUrl: string;
	public url: string; // URL to web api

	constructor(
		private readonly http: HttpClient,
		private readonly log: LogService,
		private readonly loadingIndicatorService: LoadingIndicatorService,
		environment: Environment
	) {
		super();
		this.baseUrl = environment.services['clients'];
		this.url = this.baseUrl + 'suggestion';
	}

	postSuggestion(data: any, serviceToId: string): Observable<any> {
		const urls = `${this.url}/${serviceToId}/createSuggestion`;
		return this.http.post<any>(urls, data, httpOptions).pipe(catchError(this.createErrorResponseHandler(this.log)));
	}
}
