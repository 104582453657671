import { Directive, KeyValueDiffers, Optional } from '@angular/core';
import { ButtonDirective } from '@progress/kendo-angular-buttons';
import { KpaMonitorChanges } from '../../shared/kpa-monitor-changes';

@Directive({
	selector: '[kpaSecondaryButton]',
	exportAs: 'kpaSecondaryButton',
})
export class KpaSecondaryButtonDirective extends KpaMonitorChanges<ButtonDirective> {
	constructor(
		@Optional() private readonly button: ButtonDirective,
		diff: KeyValueDiffers
	) {
		super(diff, button, 'look', 'primary');

		if (!button)
			throw 'The KpaSecondaryButtonDirective has a sibling dependency of the Kendo ButtonDirective. Please apply this attribute directive to your button element.';
	}

	setBehaviors(): void {
		this.button.look = 'outline';
		this.button.primary = true;
		this.button.themeColor = 'info';
		this.button.setAttribute('style', 'color: #1c75bc; border-color: #1c75bc;');
	}
}
