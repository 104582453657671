<div class="k-form-field">
	<span
		class="dd-label"
		id="{{ id }}_label"
		>{{ selectLabel }}</span
	>

	<div>
		<kendo-dropdownlist
			appFloatingDropDownlabel
			[data]="filteredData"
			[textField]="'text'"
			[valueField]="'value'"
			[defaultItem]="emptyPlaceholder"
			[filterable]="true"
			class="kendo-dropdown k-dropdown"
			(filterChange)="filterChanged($event)"
			[(value)]="value"
			(selectionChange)="selectionChanged($event)"
			id="{{ id }}_ddl">
		</kendo-dropdownlist>
	</div>

	<span class="dd-border"></span>
</div>
