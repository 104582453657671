<!-- Non-CA Policy -->
<div class="privacy-policy card">
	<a
		href="/assets/KPA Privacy Policy (non-CA).pdf"
		class="standAloneBtn add-button k-button k-primary right"
		>Print Document</a
	>
	<h1>Privacy Policy (non-CA)</h1>

	<div class="policy-date">Effective Date March 1st, 2020; Updated January 31st, 2020</div>

	<h2>Introduction</h2>
	<div class="paragraph">
		Your privacy and the trust you place in us to protect your information are very important to us. We are committed to complying with all
		applicable laws, including those related to data privacy and protection. We take steps to safeguard the information we hold and want to
		be transparent with you about how we use that information, and with whom we share it. This Privacy Policy is intended to provide you
		with information on how we collect, use, secure, and delete personal information. We encourage you to read this Privacy Policy carefully
		so that you understand our policies and practices regarding your information in our possession. Questions or comments regarding this
		Policy should be submitted to us by mail or email as follows:
	</div>

	<div class="contact-info">
		<span class="bold">KPA Corporate Office</span><br />
		11080 CIRCLE POINT ROAD<br />
		SUITE 200<br />
		WESTMINSTER​, CO ​80020<br />
		Phone: <a href="phone:866-356-1735">(866) 356-1735</a><br />
		United States<br />
		<a href="mailto:Support@KPA.io">Support&#64;KPA.io</a><br />
	</div>

	<h3>Overview and Scope</h3>
	<div class="paragraph">
		KPA, LLC, and our affiliate companies (collectively “KPA” “we,” “us,” or “our”), delivers compliance solutions that help our clients
		achieve regulatory compliance, control risk, protect their assets, and effectively manage employees.
	</div>

	<ul>
		<li>
			Our Software. We provide Software-as-a-Service (“SaaS”) based solutions, award-winning training, and on website consulting
			(collectively the “Software”) to thousands of our business customers throughout the United States and Canada.
		</li>
		<li>
			Our Websites. We maintain publicly accessible websites for commercial purposes including: autoadvisory.com, clean-dealer.com,
			compli.com, compligo.com, ehsstudy.com, elitedealeraward.com, fmsds.com, hrdrive.com, hrdrivehiring.com, hrsafetyonline.com,
			kapnickriskservicescenter.com, kpa.co, kpa.io, kpadb.com, kpadev.com, kpahq.com, hpahq.net, kpahr.com, kpahrm.com, kpaonline.com,
			kpapartnerresources.com, laborandemploymentsource.com, lossfreerx.com, mykpa.com, mykpaonline.com, nwpreferredbuilders.com,
			smstest.net, suceedms.com, suceedsafetytips.com, verasuit.com (collectively, the “Website”).
		</li>
		<li>
			Our Services. Together, our Software and our Website will be referred to in this Privacy Policy as “Services.” If you do not agree to
			the terms and conditions of this Privacy Policy, please do not use the Services. Use of our Services is deemed to be acceptance of the
			terms and conditions of this Privacy Policy.
		</li>
	</ul>

	<div class="paragraph">
		<span class="underline">Scope</span>. This Privacy Policy describes our data handling practices for using, maintaining, protecting and
		disclosing your personal information collected from your interaction with the Services. This Privacy Policy applies to our Services that
		collect data and display these terms. It does not apply to any website, service, or product that does not display or link to this
		Privacy Policy or that contains its own privacy policy. In order to provide those Services, we access, collect, store and use personal
		information.
	</div>

	<div class="paragraph">
		<span class="underline">Security</span>. KPA is committed to protecting your privacy in a variety of ways including using reasonable and
		appropriate industry accepted security measures to protect against loss, misuse and alteration of data contained in our systems. Any
		information given to us will never be sold, rented, traded, shared, or leased other than as outlined in this Policy.
	</div>

	<div class="paragraph">
		<span class="underline">Not intended for children</span>. Our Services are intended for our customer’s internal business management and
		are not intended for or directed to anyone under 16 years of age. We do not knowingly collect personal information from children. If we
		learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete
		that information as soon as possible.
	</div>

	<div class="paragraph">
		<span class="underline">Updates</span>. We may update this Privacy Policy from time to time; your continued use of our Services after we
		make changes is deemed to be acceptance of those changes, so please check our Privacy Policy periodically for updates. Updates will be
		noted in our Privacy Policy effective date above.
	</div>

	<h2>Collection</h2>

	<div class="paragraph">
		We collect your personal information in a variety of ways depending upon how you use and interact with our Services. Some of this
		information is provided directly by you, while other information may be provided by our customer (your employer) in connection with use
		of our Services.
	</div>

	<h3>Information Collected from and about You on the Website</h3>

	<div class="paragraph">
		We may collect information directly from you when you complete forms on our Website. This may include information you submit to us in a
		“Contact Us” form, a registration for a webinar, or the information you provide when downloading a whitepaper. The information we gather
		through these forms include the following types of information:
	</div>

	<ul class="wrap">
		<li>Name</li>
		<li>email</li>
		<li>state of residence</li>
		<li>phone number</li>
	</ul>

	<ul class="wrap">
		<li>employer</li>
		<li>job title</li>
		<li>information about your interests in our Services</li>
	</ul>

	<div class="paragraph">
		Employment Application. If you review our careers page through our Website and want to search open positions, you will be redirected to
		our recruiting partner who will have access to and manage the personal information that you provide. They will manage and share any
		personal information in accordance with their privacy policy presented on their website. To learn more about third parties who may have
		access to your data, please see "<a href="home/PrivacyPolicy/#nca-sharing-information">sharing information</a> portion of this Privacy
		Policy.
	</div>

	<h3>Information Collected from the Software</h3>

	<div class="paragraph">
		We collect certain personal information from our customer (your employer) or from you when you provide it to us in connection with use
		of our Software.
	</div>

	<div class="paragraph">
		<span class="underline">Personal Information collected from Software Users</span>. For licensed users designated by our customers who
		access our Software, you may be required to provide specific information (such as your login credentials) to verify your identity before
		accessing certain information within our Software. The types of information requested may include the following types of information:
	</div>

	<ul class="wrap">
		<li>job location</li>
		<li>hire date</li>
		<li>date of birth</li>
		<li>driver’s license</li>
		<li>social security number</li>
	</ul>

	<ul class="wrap">
		<li>supervisor information</li>
		<li>training records</li>
		<li>policy attestations</li>
		<li>other information used by employers for regulatory compliance</li>
	</ul>

	<div class="paragraph">
		<span class="underline">Personal Information Collected on behalf of our Customers</span>. As directed by our customer , we may import
		and store personal information in our Software supplied by a customer thought integrations with the customer’s in-house systems or from
		designated third parties, such as payroll and accounting systems, human resources information systems, applicant management systems, and
		learning management systems. The table below details the nature of the Software we provide and the types of personal information that we
		may collect:
	</div>

	<div class="indented">
		<div class="underline">EHS Compliance Software</div>
		<div>In this set of Software, we may collect the following types of personal information:</div>
		<ul>
			<li>Website inspection information</li>
			<li>Issue Tracking</li>
			<li>Training records</li>
		</ul>

		<div class="underline">HR Management Software</div>
		<div>In this set of Software, we may collect the following types of personal information:</div>
		<ul>
			<li>Applicant tracking</li>
			<li>Onboarding</li>
			<li>Workers Comp</li>
			<li>Health Records</li>
			<li>Benefits Administration</li>
			<li>Training records</li>
		</ul>

		<div class="underline">F&I Compliance Software</div>
		<div>This Software collects the following types of information:</div>
		<ul>
			<li>Website audit information</li>
			<li>Training records</li>
		</ul>
	</div>
	<h3>Personal Information Collected Indirectly</h3>

	<div class="paragraph">
		In addition to collecting information you provide to us directly, we also collect some personal information indirectly.
	</div>

	<div class="paragraph">
		<span class="underline">Information Collected from other Third Parties</span>. We may collect information about you from third party or
		public sources, such as social networks when you click “Share This” via Facebook, Twitter, Linked In, or other social media “like”
		buttons. Any data collected by a third party would be handled based on their privacy practices rather than this Policy.
	</div>

	<div class="paragraph">
		<span class="underline">Information Collected through Automated Technologies</span>. We may collect information about the computer or
		device you are using, including your IP address and browser type. Having information like this helps us improve our Website and deliver
		a better and more personalized service to our customers and Website visitors.
	</div>

	<div class="indented paragraph">
		The information we collect and share with our service providers includes browsing actions and patterns including traffic data, location
		data, logs, the resources that you access, and search queries. We do not provide any Personal Information to these third-party
		advertising partners, but they may combine this information with Personal Information they collect directly from you or receive from
		other sources.
	</div>

	<div class="indented paragraph">
		We want to be transparent about the automated technologies we use; for more information on the automatic information collection
		technologies we use, please see our <a href="home/PrivacyPolicy#nca-cookie">Cookie and Automated Technology Notice.</a>
	</div>

	<div class="paragraph">
		<span class="underline">Mobile Location Tracking</span>. Our Services may be accessible on a mobile device. When using a mobile device,
		we collect information about the device you are using and your connection including mobile operator or ISP and mobile phone number.
	</div>

	<div class="indented paragraph">
		Certain of our mobile apps offer opt–in, geo–location services and push notifications. Geo-location services provide location– based
		services, such as store locators and other personalized content. Push Notifications can include discounts, reminders or details about
		local events or promotions. Most mobile devices allow you to turn off location services or push notifications.
	</div>

	<div class="paragraph">
		<span class="underline">Do Not Track Requests</span>. Some browsers offer a “Do Not Track” privacy preference. Generally, when a user
		turns on the Do Not Track Signal, their browser sends a message to websites requesting that the user not be tracked. Our Website and
		Services currently do not respond to “Do Not Track” signals. However, you do have other options for opting out of tracking for
		interest-based advertising purposes as described in this Privacy Policy.
	</div>

	<h2>Use</h2>

	<div class="paragraph">
		KPA uses the information we collect to operate and improve our Services, to respond to requests about information or Services we offer,
		and to protect and secure the integrity of our systems and the data we hold. More specifically, we use personal information in the
		following ways:
	</div>

	<ul>
		<li>
			On Our Website. We use personal data collected from the Website to develop and improve our Services, to email users with marketing
			offers, to complete predictive analytics and data mining, and to provide interest-based advertising to help our message reach
			interested people.
		</li>
		<li>
			In Our Software. We use personal data to provide Services to end users, to communicate to customers and users about the Services, to
			respond to support requests, and to develop and improve the Services.
		</li>
	</ul>

	<h3>How we use personal information collected from the Website</h3>

	<div class="paragraph">
		We maintain a commercial, publicly available website in order to promote our business interests. When visitors complete a webform or
		send an email, we may use that information to respond to the request or inquiry. For participants of our web seminars, the only personal
		data we share is web seminar registration information and it is only shared with our web seminar presenters to provide this service.
	</div>

	<div class="paragraph">
		KPA does not sell, rent, lease, trade, or share visitors' personal data other than as outlined in this Privacy Policy.
	</div>

	<div class="underline">Opt Out</div>
	<div>
		When you provide us with your personal data or otherwise choose to sign up to receive email communications from us, we will use that
		information to send those communications to you. Individuals may "opt-out" of receiving e-mail communications through selections
		available on e-mails received.
	</div>

	<h3>How we use personal information collected from the Software</h3>

	<div class="paragraph">
		Use of your personal information within the Software is done at the direction of our customers to manage their compliance objectives.
		Each customer manages the data in accordance with their own internal policies and procedures. Any questions related to how that customer
		may process, use, or share your information should be directed to that customer.
	</div>

	<h3 id="nca-sharing-information">Sharing Information</h3>

	<div class="paragraph">
		There may be instances where we share your information with our trusted third-party service providers. There are also instances where we
		are required to share your information with other third parties based on certain criteria. This section details our data handling
		practices on how we share data.
	</div>

	<div class="paragraph indented">
		<span class="underline">Disclosure to Our Service Providers and Subcontractors</span>. We use third-party vendors who may have access to
		personal information. Our vendors are required to safeguard personal information to the same extent we safeguard personal information.
		They may use personal information exclusively to deliver the services to us. KPA does not allow vendors to use personal information
		collected from the Services to promote their own marketing objectives without your separate, prior consent.
	</div>

	<div class="paragraph indented">
		You might see an advertisement from us on your social media page. Sometimes we allow third party advertising companies and ad networks
		to use automatic data collection technologies to collect similar information about you for purposes of providing you with interest-based
		ads. Interest-based ads are helpful because they are more likely to be tailored to your particular interests. They are also more likely
		to help you discover new services that are relevant to you and your interests. Also, if interest-based tracking is enabled, you likely
		will not see the same ads over and over because the number of times you see an interest-based ad is usually limited. By opting-out of
		interest-based ads, you lose these types of benefits.
	</div>

	<div class="paragraph indented">
		<span class="underline">Response to Court Orders and Legal Process</span>. In certain situations, we may be required to disclose
		personal information in response to lawful requests by public authorities, including to meet national security or law enforcement
		requirements.
	</div>

	<div class="paragraph indented">
		We reserve the right to disclose your personal data as required by law and when we believe that disclosure is necessary to protect our
		rights, the security of our systems, Software and Website, and/or comply with a judicial proceeding, court order, or legal process
		served on us.
	</div>

	<div class="paragraph indented">
		<span class="underline">Transfer based on Business Assignment</span>. In the event KPA goes through a business transition, such as a
		merger, acquisition by another company, or sale of all or a portion of its assets, your personal data will likely be among the assets
		transferred. You will be notified via prominent notice on our Website for 30 days of any such change in ownership or control of your
		personal information.
	</div>

	<div class="paragraph indented">
		<span class="underline">Feedback provided to us</span>. Some of our customers like to provide information to us about their experiences
		using the Software or to share what they learned from information on our Website. When users provide feedback regarding our Services, we
		may share that information with other customers and prospects on our Website or through our Software.
	</div>

	<h2>Security</h2>

	<div class="paragraph">
		KPA uses reasonable security measures to protect personal information in its possession from loss, misuse and unauthorized access,
		disclosure, alteration, and destruction. We have implemented protections designed to secure your personal information from accidental
		loss and from unauthorized access, use, alteration, and disclosure. However, the safety and security of your information also depends on
		you. Where we have given you a password for access to certain parts of our Website, you are responsible for keeping this password
		confidential.
	</div>

	<div class="paragraph">
		We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once
		we receive it. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while
		we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
	</div>

	<div class="paragraph">
		If KPA learns of a security systems breach, KPA may attempt to notify you electronically so that appropriate protective steps can be
		taken. By using the Services or providing personal information to us, you agree that we can communicate with you electronically
		regarding security, privacy, and administrative issues relating to your use of the Services. We may post a notice via our Website if a
		security breach occurs. We may also send an email to you at the email address you have provided to us in these circumstances. Depending
		on where you live, you may have a legal right to receive notice of a security breach in writing.
	</div>

	<div class="paragraph">
		If you have any questions about security on our Website, you can email us at <a href="mailto:support@kpa.io">Support&#64;KPA.io</a> with
		"Questions about Web Website Security" in the subject line.
	</div>

	<h2>ACCESS</h2>

	<h3>Review or Update Information through the Software</h3>

	<div class="paragraph">
		KPA is engaged by companies around the United States to deliver our Software. Where personal information is collected in relation to
		that Software, we do so on behalf of our customers organizations and those customer organizations manage the data in accordance with
		their own internal policies and procedures.
	</div>

	<div class="paragraph">
		Any questions related to how that customer organization may process, use or share your information should be directed to that customer
		organization by contacting them directly as we are not responsible for their data management practices.
	</div>

	<h3>Review or Update Information through the Website and Opt-Out</h3>

	<div class="paragraph">
		We want to provide you with choices regarding the personal information you provide to us. If you do not wish to receive promotional
		emails from us, including our e-newsletters, you may either opt-out or follow the unsubscribe process at the bottom of the promotional
		email or send us an email at <a href="mailto:support@kpa.io">support&#64;kpa.io</a> Please note, even if you opt-out, you may still
		receive transactional emails from us (e.g., emails related to the completion of your registration, correction of user data, password
		reset requests, reminder emails that you have requested, and any other similar communications essential to your use of the Services)
		which may be necessary for us to continue to provide you with Services or respond to your enquiries.
	</div>

	<div class="paragraph">
		We do not control third parties’ collection or use of your information to serve interest-based ads. However, you can opt-out of
		receiving interest-based ads from third party advertisers and ad networks who are members of the NAI or who follow the DAA’s
		Self-Regulatory Principles for Online Behavioral Advertising by visiting the opt-out pages of the <span class="bold">NAI</span> and
		<span class="bold">DAA</span> websites. If you opt-out, you may still see contextual advertising from us, meaning you may still see
		personalized recommendations about our products and services and other similar features on this Website. You may also still see ads
		provided by us or others on other third-party websites; they just will not be based on your particular interests.
	</div>

	<div class="paragraph">
		Also note, your opt-out will be managed through the use of cookies, so if you delete these cookies or use a different browser or
		computer, you will have to make this same election again.
	</div>

	<h3>Report a Breach of the practices on detailed on this Privacy Policy</h3>

	<div class="paragraph">
		If you have received unwanted, unsolicited email sent by KPA or from any KPA system or purporting to be sent via KPA, please forward a
		copy of that email with your comments to <a href="mailto:support@kpa.io">support&#64;kpa.io</a> for review.
	</div>

	<div class="paragraph">
		If you have questions or complaints regarding our Privacy Policy or practices, please contact us at
		<a href="mailto:support@kpa.io">support&#64;kpa.io</a>
		with “Privacy Enquiry” in the subject line and provide detail on your question or complaint so that we may adequately respond. Questions
		or comments regarding this Policy should be submitted to KPA by mail or email as follows:
	</div>

	<div class="contact-info">
		<span class="bold">KPA Corporate Office</span><br />
		11080 CIRCLE POINT ROAD<br />
		SUITE 200<br />
		WESTMINSTER​, CO ​80020<br />
		Phone: <a href="phone:866-356-1735">(866) 356-1735</a><br />
		United States<br />
		<a href="mailto:Support@KPA.io">Support&#64;KPA.io</a><br />
	</div>

	<h2>Updates</h2>

	<div class="paragraph">
		Any updates or changes to our Privacy Policy will be posted to this Privacy Policy link and other places we deem appropriate, so you are
		aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. We reserve the right to
		modify this Privacy Policy at any time, so please review it frequently. If we make material changes to this policy, we will notify you
		via the Services, by email, or by means of a notice on our home page.
	</div>

	<h3>Dispute Resolution and Agreement to Arbitrate</h3>

	<div class="paragraph">
		Except where and to the extent prohibited by law, by using the Services, you and KPA agree that, if there is any controversy, claim,
		action, or dispute arising out of or related to your use of the Services or the breach, enforcement, interpretation, or validity of this
		Policy or any part of it (“Dispute”), both parties shall first try in good faith to settle such Dispute by providing written notice to
		the other party describing the facts and circumstances of the Dispute and allowing the receiving party thirty (30) days in which to
		respond to or settle the Dispute. Notice shall be sent to:
	</div>

	<ul>
		<li>KPA, at 1380 Forest Park Circle, Lafayette, CO 80026 or</li>
		<li>You, at the address we have on file for you.</li>
	</ul>

	<div class="paragraph">
		Both you and KPA agree that this dispute resolution procedure is a condition precedent that must be satisfied before initiating any
		litigation or filing any claim against the other party. IF ANY DISPUTE CANNOT BE RESOLVED BY THE ABOVE DISPUTE RESOLUTION PROCEDURE, YOU
		AGREE THAT THE SOLE AND EXCLUSIVE JURISDICTION FOR SUCH DISPUTE WILL BE DECIDED BY BINDING ARBITRATION ON AN INDIVIDUAL BASIS.
		ARBITRATION ON AN INDIVIDUAL BASIS MEANS THAT YOU WILL NOT HAVE, AND YOU WAIVE, THE RIGHT FOR A JUDGE OR JURY TO DECIDE YOUR CLAIMS, AND
		THAT YOU MAY NOT PROCEED IN A CLASS, CONSOLIDATED, OR REPRESENTATIVE CAPACITY. Other rights that you and KPA would otherwise have in
		court will not be available or will be more limited in arbitration, including discovery and appeal rights. All such disputes shall be
		exclusively submitted to the American Arbitration Association (www.adr.org) for binding arbitration under its rules then in effect,
		before one arbitrator to be mutually agreed upon by both parties.
	</div>

	<div class="paragraph">
		The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve any dispute arising
		under or relating to the interpretation, applicability, enforceability, or formation of this Policy, including any claim that all or any
		part of this Policy is void or voidable.
	</div>

	<h3>OTHER ARBITRATION AGREEMENTS</h3>

	<div class="paragraph">
		In the event of a conflict between this agreement to arbitrate and any other arbitration agreement between you and KPA (Other
		Arbitration Agreement), such as an arbitration agreement contained in a contract about and related to Services with KPA, the terms of
		the Other Arbitration Agreement shall govern and prevail in each instance.
	</div>

	<h3>CHOICE OF LAW</h3>

	<div class="paragraph">
		Any disputes or claims not subject to the arbitration provision discussed above shall be resolved by a court located in the State of
		Colorado and you agree and submit to the exercise of personal jurisdiction of such courts for the purpose of litigating any such claim
		or action.
	</div>

	<h2 id="nca-cookie">Cookie and Automated Technology Notice</h2>

	<div class="paragraph">
		Technologies such as: cookies, beacons, tags, and scripts are used by us and our tracking utility partners. These technologies are used
		in analyzing trends, administering the Website, tracking users’ movements around the Website and to gather demographic information about
		our user base as a whole. We may receive reports based on the use of these technologies by these companies on an individual as well as
		aggregated basis.
	</div>

	<div class="paragraph">
		KPA utilizes Cookie technology on our Websites for purposes of traffic analysis such as the time/date of the visit, the time/date of
		last visit, the page viewed, the referring Website, and other data. KPA also tracks click behavior in the emails it sends out. This data
		is used to update specific user-profile information, ascertain the areas of most interest to opt-in email recipients, and to personalize
		email messages to them.
	</div>

	<div class="paragraph">
		A “cookie” is a small text file that is stored on a user’s computer and downloaded to your computer or mobile device when you visit a
		website. Cookies then send information back to the originating website on each subsequent visit, or to another website that recognizes
		that same cookie. Cookies are useful because they allow a website to recognize a user’s device.
	</div>

	<div class="paragraph">
		Cookies perform many different jobs, like allowing you to navigate between pages efficiently, remembering your preferences, and
		generally improving a user’s experience on a website. They can also help to ensure that the advertisements you see online are more
		relevant to you and your interests. We use different types of cookies on our website, as explained below.
	</div>

	<h3>Required Cookies</h3>

	<div class="paragraph">
		These cookies are necessary for our Website to function properly and cannot be switched off in our systems. They are usually only set in
		response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling
		in forms. You can set your browser to block or alert you about these cookies, but some parts of our Website will work if you block these
		cookies. These cookies do not store any information that identifies you directly.
	</div>

	<h3>Functional Cookies</h3>

	<div class="paragraph">
		Functional cookies allow us to count visits and sources of traffic on our Website so we can measure and improve the performance of our
		Website. They help us to know which pages of our Website are the most and least popular and see how visitors move around our Website.
		The information collected by these cookies is used to create aggregated, anonymous analytics reports. If you do not allow these cookies
		we will not know when you have visited our Website and will not be able to monitor its performance.
	</div>

	<h3>Advertising Cookies</h3>

	<div class="paragraph">
		Advertising cookies may be set through our Website by our advertising partners. They may be used by those companies to build a profile
		of your interests and show you relevant advertisements on other Websites.
	</div>

	<div class="paragraph">
		These cookies do not store directly identifiable personal information but are based on uniquely identifying your browser and internet
		device. If you do not allow these cookies, you will experience less targeted advertising, but you will not stop receiving general
		advertising.
	</div>

	<h3>Social Media Cookies</h3>

	<div class="paragraph">
		These cookies are used to allow you to share information using a social media sharing button (such as the Facebook “Like”) or where you
		choose to link your account with us to your social media networking site such as Pinterest. The social media site will record that you
		have linked your social media account to your account with us. This information may then be used by the social media network to deliver
		targeted advertising to you.
	</div>

	<div class="table">
		<div class="table-body">
			<div class="table-row">
				<div class="table-header">Cookie</div>
				<div class="table-header">Type</div>
				<div class="table-header">Source</div>
			</div>
			<div class="table-row">
				<div class="table-cell">DSID</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">DoubleClick</div>
			</div>
			<div class="table-row">
				<div class="table-cell">IDE</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">DoubleClick</div>
			</div>
			<div class="table-row">
				<div class="table-cell">pardot</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">Paradot</div>
			</div>
			<div class="table-row">
				<div class="table-cell">visitor_id12882</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">Paradot</div>
			</div>
			<div class="table-row">
				<div class="table-cell">visitor_id12882-hash</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">Paradot</div>
			</div>
			<div class="table-row">
				<div class="table-cell">visitor_id548382</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">Paradot</div>
			</div>
			<div class="table-row">
				<div class="table-cell">visitor_id548382-hash</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">Paradot</div>
			</div>
			<div class="table-row">
				<div class="table-cell">S</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">Google</div>
			</div>
			<div class="table-row">
				<div class="table-cell">1P_JAR</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">Google</div>
			</div>
			<div class="table-row">
				<div class="table-cell">_gcl_au</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">Google</div>
			</div>
			<div class="table-row">
				<div class="table-cell">__stid</div>
				<div class="table-cell">Functional</div>
				<div class="table-cell">Share This</div>
			</div>
			<div class="table-row">
				<div class="table-cell">__unam</div>
				<div class="table-cell">Functional</div>
				<div class="table-cell">Share This</div>
			</div>
			<div class="table-row">
				<div class="table-cell">HSID</div>
				<div class="table-cell">Functional</div>
				<div class="table-cell">YouTube</div>
			</div>
			<div class="table-row">
				<div class="table-cell">PREF</div>
				<div class="table-cell">Functional</div>
				<div class="table-cell">YouTube</div>
			</div>
			<div class="table-row">
				<div class="table-cell">SAPISID</div>
				<div class="table-cell">Functional</div>
				<div class="table-cell">YouTube</div>
			</div>
			<div class="table-row">
				<div class="table-cell">SID</div>
				<div class="table-cell">Functional</div>
				<div class="table-cell">YouTube</div>
			</div>
			<div class="table-row">
				<div class="table-cell">SSID</div>
				<div class="table-cell">Functional</div>
				<div class="table-cell">YouTube</div>
			</div>
			<div class="table-row">
				<div class="table-cell">VISITOR_INFO1_LIVE</div>
				<div class="table-cell">Functional</div>
				<div class="table-cell">YouTube</div>
			</div>
			<div class="table-row">
				<div class="table-cell">_ga</div>
				<div class="table-cell">Performance</div>
				<div class="table-cell">Google</div>
			</div>
			<div class="table-row">
				<div class="table-cell">_gid</div>
				<div class="table-cell">Performance</div>
				<div class="table-cell">Google</div>
			</div>
			<div class="table-row">
				<div class="table-cell">__cfduid</div>
				<div class="table-cell">Required</div>
				<div class="table-cell">CloudFlare</div>
			</div>
			<div class="table-row">
				<div class="table-cell">_fbp</div>
				<div class="table-cell">Social Media</div>
				<div class="table-cell">Facebook</div>
			</div>
			<div class="table-row">
				<div class="table-cell">datr</div>
				<div class="table-cell">Social Media</div>
				<div class="table-cell">Facebook</div>
			</div>
			<div class="table-row">
				<div class="table-cell">Fr</div>
				<div class="table-cell">Social Media</div>
				<div class="table-cell">Facebook</div>
			</div>
			<div class="table-row">
				<div class="table-cell">Sb</div>
				<div class="table-cell">Social Media</div>
				<div class="table-cell">Facebook</div>
			</div>
			<div class="table-row">
				<div class="table-cell">Wd</div>
				<div class="table-cell">Social Media</div>
				<div class="table-cell">Facebook</div>
			</div>
			<div class="table-row">
				<div class="table-cell">visitor_id302971</div>
				<div class="table-cell"></div>
				<div class="table-cell">KPA</div>
			</div>
			<div class="table-row">
				<div class="table-cell">visitor_id302971-hash</div>
				<div class="table-cell"></div>
				<div class="table-cell">KPA</div>
			</div>
			<div class="table-row">
				<div class="table-cell">ID</div>
				<div class="table-cell"></div>
				<div class="table-cell">YouTube</div>
			</div>
			<div class="table-row">
				<div class="table-cell">_gat_UA-7045932-1</div>
				<div class="table-cell"></div>
				<div class="table-cell">KPA</div>
			</div>
			<div class="table-row">
				<div class="table-cell">visitor_id302971</div>
				<div class="table-cell"></div>
				<div class="table-cell">KPA</div>
			</div>
			<div class="table-row">
				<div class="table-cell">visitor_id302971-hash</div>
				<div class="table-cell"></div>
				<div class="table-cell">KPA</div>
			</div>
		</div>
	</div>
</div>

<!-- CA Policy -->
<div class="privacy-policy card">
	<a
		href="/assets/KPA Privacy Policy (CA).pdf"
		class="standAloneBtn add-button k-button k-primary right"
		>Print Document</a
	>
	<h1>Privacy Policy (CA)</h1>

	<div class="policy-date">Effective Date March 1st, 2020; Updated January 31st, 2020</div>

	<h2>Introduction</h2>
	<div class="paragraph">
		Your privacy and the trust you place in us to protect your information are very important to us. We are committed to complying with all
		applicable laws, including those related to data privacy and protection. We take steps to safeguard the information we hold and want to
		be transparent with you about how we use that information, and with whom we share it. This Privacy Policy is intended to provide you
		with information on how we collect, use, secure, and delete personal information. We encourage you to read this Privacy Policy carefully
		so that you understand our policies and practices regarding your information in our possession. Questions or comments regarding this
		Policy should be submitted to us by mail or email as follows:
	</div>

	<div class="contact-info">
		<span class="bold">KPA Corporate Office</span><br />
		11080 CIRCLE POINT ROAD<br />
		SUITE 200<br />
		WESTMINSTER​, CO ​80020<br />
		Phone: <a href="phone:866-356-1735">(866) 356-1735</a><br />
		United States<br />
		<a href="mailto:Support@KPA.io">Support&#64;KPA.io</a><br />
	</div>

	<h3>Overview and Scope</h3>
	<div class="paragraph">
		KPA, LLC and our affiliate companies (collectively “KPA” “we,” “us,” or “our”), delivers compliance solutions that help our clients
		achieve regulatory compliance, control risk, protect their assets, and effectively manage employees.
	</div>

	<ul>
		<li>
			Our Software. We provide Software-as-a-Service (“SaaS”) based solutions, award-winning training, and on website consulting
			(collectively the “Software”) to thousands of our business customers throughout the United States and Canada.
		</li>
		<li>
			Our Websites. We maintain publicly accessible websites for commercial purposes including: autoadvisory.com, clean-dealer.com,
			compli.com, compligo.com, ehsstudy.com, elitedealeraward.com, fmsds.com, hrdrive.com, hrdrivehiring.com, hrsafetyonline.com,
			kapnickriskservicescenter.com, kpa.co, kpa.io, kpadb.com, kpadev.com, kpahq.com, hpahq.net, kpahr.com, kpahrm.com, kpaonline.com,
			kpapartnerresources.com, laborandemploymentsource.com, lossfreerx.com, mykpa.com, mykpaonline.com, nwpreferredbuilders.com,
			smstest.net, suceedms.com, suceedsafetytips.com, verasuit.com (collectively, the “Website”).
		</li>
		<li>
			Our Services. Together, our Software and our Website will be referred to in this Privacy Policy as “Services.” If you do not agree to
			the terms and conditions of this Privacy Policy, please do not use the Services. Use of our Services is deemed to be acceptance of the
			terms and conditions of this Privacy Policy.
		</li>
	</ul>

	<div class="paragraph">
		<span class="underline">Scope</span>. This Privacy Policy describes our data handling practices for using, maintaining, protecting and
		disclosing your personal information collected from your interaction with the Services. This Privacy Policy applies to our Services that
		collect data and display these terms. It does not apply to any website, service, or product that does not display or link to this
		Privacy Policy or that contains its own privacy policy. In order to provide those Services, we access, collect, store and use personal
		information.
	</div>

	<div class="paragraph">
		<span class="underline">Security</span>. KPA is committed to protecting your privacy in a variety of ways including using reasonable and
		appropriate industry accepted security measures to protect against loss, misuse and alteration of data contained in our systems. Any
		information given to us will never be sold, rented, traded, shared, or leased other than as outlined in this Policy. Policy.
	</div>

	<div class="paragraph">
		<span class="underline">Not intended for children</span>. Our Services are intended for our customer’s internal business management and
		are not intended for or directed to anyone under 16 years of age. We do not knowingly collect personal information from children. If we
		learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete
		that information.
	</div>

	<div class="paragraph">
		<span class="underline">Updates</span>. We may update this Privacy Policy from time to time; your continued use of our Services after we
		make changes is deemed to be acceptance of those changes, so please check our Privacy Policy periodically for updates. Updates will be
		noted in our Privacy Policy effective date above.
	</div>

	<h2>Collection</h2>

	<div class="paragraph">
		We collect your personal information in a variety of ways depending upon how you use and interact with our Services. Some of this
		information is provided directly by you, while other information may be provided by our customer (your employer) in connection with use
		of our Services. Please also refer to our
		<a href="#">Notice at Collection of Personal Information</a>, which lists the categories of personal information KPA collects and the
		business purposes for collection. Below is also a chart regarding the personal information we have collected about consumers during the
		last 12 months.
	</div>

	<h3>Information Collected from and about You on the Website</h3>

	<div class="paragraph">
		We may collect information directly from you when you complete forms on our Website. This may include information you submit to us in a
		“Contact Us” form, a registration for a webinar, or the information you provide when downloading a whitepaper. The information we gather
		through these forms include the following types of information:
	</div>

	<ul class="wrap">
		<li>Name</li>
		<li>email</li>
		<li>state of residence</li>
		<li>phone number</li>
	</ul>

	<ul class="wrap">
		<li>employer</li>
		<li>job title</li>
		<li>information about your interests in our Services</li>
	</ul>

	<div class="paragraph">
		<span class="underline bold">Employment Application</span>. If you review our careers page through our Website and want to search open
		positions, you will be redirected to our recruiting partner who will have access to and manage the personal information that you
		provide. They will manage and share any personal information in accordance with their privacy policy presented on their website. To
		learn more about third parties who may have access to your data, please see "<a href="home/PrivacyPolicy#ca-sharing-information"
			>sharing information</a
		>" portion of this Privacy Policy.
	</div>

	<h3>Information Collected from the Software</h3>

	<div class="paragraph">
		We collect certain personal information from our customer (your employer) or from you when you provide it to us in connection with use
		of our Software.
	</div>

	<div class="paragraph">
		<span class="underline">Personal Information collected from Software Users</span>. For licensed users designated by our customers who
		access our Software, you may be required to provide specific information (such as your login credentials) to verify your identity before
		accessing certain information within our Software. The types of information requested may include the following types of information:
	</div>

	<ul class="wrap">
		<li>job location</li>
		<li>hire date</li>
		<li>date of birth</li>
		<li>driver’s license</li>
		<li>social security number</li>
	</ul>

	<ul class="wrap">
		<li>supervisor information</li>
		<li>training records</li>
		<li>policy attestations</li>
		<li>other information used by employers for regulatory compliance</li>
	</ul>

	<div class="paragraph">
		<span class="underline">Personal Information Collected on behalf of our Customers</span>. As directed by our customer, we may import and
		store personal information in our Software supplied by a customer thought integrations with the customer’s in-house systems or from
		designated third parties, such as payroll and accounting systems, human resources information systems, applicant management systems, and
		learning management systems. The table below details the nature of the Software we provide and the types of personal information that we
		may collect:
	</div>

	<div class="indented">
		<div class="underline">EHS Compliance Software</div>
		<div>In this set of Software, we may collect the following types of personal information:</div>
		<ul>
			<li>Website inspection information</li>
			<li>Issue Tracking</li>
			<li>Training records</li>
		</ul>

		<div class="underline">HR Management Software</div>
		<div>In this set of Software, we may collect the following types of personal information:</div>
		<ul>
			<li>Applicant tracking</li>
			<li>Onboarding</li>
			<li>Workers Comp</li>
			<li>Health Records</li>
			<li>Benefits Administration</li>
			<li>Training records</li>
		</ul>

		<div class="underline">F&I Compliance Software</div>
		<div>This Software collects the following types of information:</div>
		<ul>
			<li>Website audit information</li>
			<li>Training records</li>
		</ul>
	</div>

	<h3>Personal Information Collected Indirectly</h3>

	<div class="paragraph">
		In addition to collecting information you provide to us directly, we also collect some personal information indirectly.
	</div>

	<div class="paragraph">
		<span class="underline">Information Collected from other Third Parties</span>. We may collect information about you from third party or
		public sources, such as social networks when you click “Share This” via Facebook, Twitter, Linked In, or other social media “like”
		buttons. Any data collected by a third party would be handled based on their privacy practices rather than this Policy.
	</div>

	<div class="paragraph">
		<span class="underline">Information Collected through Automated Technologies</span>. We may collect information about the computer or
		device you are using, including your IP address and browser type. Having information like this helps us improve our Website and deliver
		a better and more personalized service to our customers and Website visitors.
	</div>

	<div class="indented paragraph">
		The information we collect and share with our service providers includes browsing actions and patterns including traffic data, location
		data, logs, the resources that you access, and search queries. We do not provide any Personal Information to these third-party
		advertising partners, but they may combine this information with Personal Information they collect directly from you or receive from
		other sources.
	</div>

	<div class="indented paragraph">
		We want to be transparent about the automated technologies we use; for more information on the automatic information collection
		technologies we use, please see our <a href="home/PrivacyPolicy#ca-cookie">Cookie and Automated Technology Notice.</a>
	</div>

	<div class="paragraph">
		<span class="underline">Mobile Location Tracking</span>. Our Services may be accessible on a mobile device. When using a mobile device,
		we collect information about the device you are using and your connection including mobile operator or ISP and mobile phone number.
	</div>

	<div class="indented paragraph">
		Certain of our mobile apps offer opt–in, geo–location services and push notifications. Geo-location services provide location– based
		services, such as store locators and other personalized content. Push Notifications can include discounts, reminders or details about
		local events or promotions. Most mobile devices allow you to turn off location services or push notifications.
	</div>

	<div class="paragraph">
		<span class="underline">Do Not Track Requests</span>. Some browsers offer a “Do Not Track” privacy preference. Generally, when a user
		turns on the Do Not Track Signal, their browser sends a message to websites requesting that the user not be tracked. Our Website and
		Services currently do not respond to “Do Not Track” signals. However, you do have other options for opting out of tracking for
		interest-based advertising purposes as described in this Privacy Policy.
	</div>

	<h2>Use</h2>

	<div class="paragraph">
		KPA uses the information we collect to operate and improve our Services, to respond to requests about information or Services we offer,
		and to protect and secure the integrity of our systems and the data we hold. More specifically, we use personal information in the
		following ways:
	</div>

	<ul>
		<li>
			On Our Website. We use personal data collected from the Website to develop and improve our Services, to email users with marketing
			offers, to complete predictive analytics and data mining, and to provide interest-based advertising to help our message reach
			interested people.
		</li>
		<li>
			In Our Software. We use personal data to provide Services to end users, to communicate to customers and users about the Services, to
			respond to support requests, and to develop and improve the Services.
		</li>
	</ul>

	<h3>How we use personal information collected from the Website</h3>

	<div class="paragraph">
		We maintain a commercial, publicly available website in order to promote our business interests. When visitors complete a webform or
		send an email, we may use that information to respond to the request or inquiry. For participants of our web seminars, the only personal
		data we share is web seminar registration information and it is only shared with our web seminar presenters to provide this service.
	</div>

	<div class="paragraph">
		KPA does not sell, rent, lease, trade, or share visitors' personal data other than as outlined in this Privacy Policy.
	</div>

	<div class="underline">Opt Out</div>
	<div class="paragraph">
		When you provide us with your personal data or otherwise choose to sign up to receive email communications from us, we will use that
		information to send those communications to you. Individuals may "opt-out" of receiving e-mail communications through selections
		available on e-mails received.
	</div>

	<h3>How we use personal information collected from the Software</h3>

	<div class="paragraph">
		Use of your personal information within the Software is done at the direction of our customers to manage their compliance objectives.
		Each customer manages the data in accordance with their own internal policies and procedures. Any questions related to how that customer
		may process, use, or share your information should be directed to that customer.
	</div>

	<h3 id="ca-sharing-information">Sharing Information</h3>

	<div class="paragraph">
		There may be instances where we share your information with our trusted third-party service providers. There are also instances where we
		are required to share your information with other third parties based on certain criteria. This section details our data handling
		practices on how we share data.
	</div>

	<div class="paragraph indented">
		<span class="underline">Disclosure to Our Service Providers and Subcontractors</span>. We use third-party vendors who may have access to
		personal information. Our vendors are required to safeguard personal information to the same extent we safeguard personal information.
		They may use personal information exclusively to deliver the services to us. KPA does not allow vendors to use personal information
		collected from the Services to promote their own marketing objectives without your separate, prior consent.
	</div>

	<div class="paragraph indented">
		You might see an advertisement from us on your social media page. Sometimes we allow third party advertising companies and ad networks
		to use automatic data collection technologies to collect similar information about you for purposes of providing you with interest-based
		ads. Interest-based ads are helpful because they are more likely to be tailored to your particular interests. They are also more likely
		to help you discover new services that are relevant to you and your interests. Also, if interest-based tracking is enabled, you likely
		will not see the same ads over and over because the number of times you see an interest-based ad is usually limited. By opting-out of
		interest-based ads, you lose these types of benefits.
	</div>

	<div class="paragraph indented">
		<span class="underline">Response to Court Orders and Legal Process</span>. In certain situations, we may be required to disclose
		personal information in response to lawful requests by public authorities, including to meet national security or law enforcement
		requirements.
	</div>

	<div class="paragraph indented">
		We reserve the right to disclose your personal data as required by law and when we believe that disclosure is necessary to protect our
		rights, the security of our systems, Software and Website, and/or comply with a judicial proceeding, court order, or legal process
		served on us.
	</div>

	<div class="paragraph indented">
		<span class="underline">Transfer based on Business Assignment</span>. In the event KPA goes through a business transition, such as a
		merger, acquisition by another company, or sale of all or a portion of its assets, your personal data will likely be among the assets
		transferred. You will be notified via prominent notice on our Website for 30 days of any such change in ownership or control of your
		personal information.
	</div>

	<div class="paragraph indented">
		<span class="underline">Feedback provided to us</span>. Some of our customers like to provide information to us about their experiences
		using the Software or to share what they learned from information on our Website. When users provide feedback regarding our Services, we
		may share that information with other customers and prospects on our Website or through our Software.
	</div>

	<h2>Notice at Collection of Personal Information</h2>

	<div class="paragraph">
		KPA respects the privacy of the information our customers entrust to us. We do not and will not sell your personal information. For a
		printable version of the Notice at Collection of Personal Information, please click
		<a href="/assets/Notice at Collection of Personal Information.pdf">here</a>.
	</div>

	<div class="table">
		<div class="table-body">
			<div class="table-row">
				<div class="table-header">Categories of personal information we collect from you</div>
				<div class="table-header">Business or commercial purpose(s)</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<div class="paragraph">Identifiers, such as:</div>
					<div class="paragraph">
						Name, address, email address, IP address, identification numbers (e.g., social security number, driver’s license number, state
						identification number, military identification number or passport number).
					</div>
				</div>
				<div class="table-cell">
					To help communicate with you regarding our products and services; to help respond to your requests and inquiries; to facilitate
					use and implementation of our products and services; to further develop and improve our products and services; to advertise and
					market to you; to complete predictive analytics and data mining
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<div class="paragraph">Characteristics of protected classifications under California or federal law, such as:</div>
					<div class="paragraph">Gender, date of birth.</div>
				</div>
				<div class="table-cell">
					To facilitate use and implementation of our products and services; to further develop and improve our products and services; to
					complete predictive analytics and data mining
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<div class="paragraph">Internet or other electronic network activity information, such as:</div>
					<div class="paragraph">IP address, browsing history, search history</div>
				</div>
				<div class="table-cell">
					To help communicate with you regarding our products and services; to help respond to your requests and inquiries; to facilitate
					use and implementation of our products and services; to further develop and improve our products and services; to advertise and
					market to you; to complete predictive analytics and data mining
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<div class="paragraph">Professional or employment related information, such as:</div>
					<div class="paragraph">Information about current and previous occupation(s); prior accident history</div>
				</div>
				<div class="table-cell">
					To facilitate use and implementation of our products and services; to further develop and improve our products and services; to
					complete predictive analytics and data mining
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<div class="paragraph">Education information, such as:</div>
					<div class="paragraph">Information about place and level of schooling.</div>
				</div>
				<div class="table-cell">
					To facilitate use and implementation of our products and services; to further develop and improve our products and services; to
					complete predictive analytics and data mining
				</div>
			</div>
		</div>
	</div>

	<h2>Security</h2>

	<div class="paragraph">
		KPA uses reasonable security measures to protect personal information in its possession from loss, misuse and unauthorized access,
		disclosure, alteration, and destruction. We have implemented protections designed to secure your personal information from accidental
		loss and from unauthorized access, use, alteration, and disclosure. However, the safety and security of your information also depends on
		you. Where we have given you a password for access to certain parts of our Website, you are responsible for keeping this password
		confidential.
	</div>

	<div class="paragraph">
		We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once
		we receive it. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while
		we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
	</div>

	<div class="paragraph">
		If KPA learns of a security systems breach, KPA may attempt to notify you electronically so that appropriate protective steps can be
		taken. By using the Services or providing personal information to us, you agree that we can communicate with you electronically
		regarding security, privacy, and administrative issues relating to your use of the Services. We may post a notice via our Website if a
		security breach occurs. We may also send an email to you at the email address you have provided to us in these circumstances. Depending
		on where you live, you may have a legal right to receive notice of a security breach in writing.
	</div>

	<div class="paragraph">
		If you have any questions about security on our Website, you can email us at <a href="mailto:support@kpa.io">support&#64;kpa.io</a> with
		"Questions about Web Website Security" in the subject line.
	</div>

	<h2>ACCESS</h2>

	<h3>Review or Update Information through the Software</h3>

	<div class="paragraph">
		KPA is engaged by companies around the United States to deliver our Software. Where personal information is collected in relation to
		that Software, we do so on behalf of our customers organizations and those customer organizations manage the data in accordance with
		their own internal policies and procedures.
	</div>

	<div class="paragraph">
		Any questions related to how that customer organization may process, use or share your information should be directed to that customer
		organization by contacting them directly as we are not responsible for their data management practices.
	</div>

	<h3>Review or Update Information through the Website and Opt-Out</h3>

	<div class="paragraph">
		We want to provide you with choices regarding the personal information you provide to us. If you do not wish to receive promotional
		emails from us, including our e-newsletters, you may either opt-out or follow the unsubscribe process at the bottom of the promotional
		email or send us an email at <a href="mailto:Support@KPA.io">Support&#64;KPA.io</a> Please note, even if you opt-out, you may still
		receive transactional emails from us (e.g., emails related to the completion of your registration, correction of user data, password
		reset requests, reminder emails that you have requested, and any other similar communications essential to your use of the Services)
		which may be necessary for us to continue to provide you with Services or respond to your enquiries.
	</div>

	<div class="paragraph">
		We do not control third parties’ collection or use of your information to serve interest-based ads. However, you can opt-out of
		receiving interest-based ads from third party advertisers and ad networks who are members of the NAI or who follow the DAA’s
		Self-Regulatory Principles for Online Behavioral Advertising by visiting the opt-out pages of the <span class="bold">NAI</span> and
		<span class="bold">DAA</span> websites. If you opt-out, you may still see contextual advertising from us, meaning you may still see
		personalized recommendations about our products and services and other similar features on this Website. You may also still see ads
		provided by us or others on other third-party websites; they just will not be based on your particular interests.
	</div>

	<div class="paragraph">
		Also note, your opt-out will be managed through the use of cookies, so if you delete these cookies or use a different browser or
		computer, you will have to make this same election again.
	</div>

	<h3>California Privacy Rights</h3>

	<div class="paragraph">
		California Civil Code section 1798.83 permits visitors to the Services who are California residents to request certain information, once
		a year, regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request,
		please send us an email using the contact information provided below and put “Shine the Light Request” in the subject line of your
		email.
	</div>

	<div class="paragraph">
		From January 1, 2020, California consumers have the following additional rights under the California Consumer Privacy Act (CCPA):
	</div>

	<div class="indented">
		<ul>
			<li class="underline">Right to know</li>
			<div class="paragraph">
				You have the right to request information about the categories and specific pieces of personal information we have collected about
				you, as well as the categories of sources from which such information is collected, the purpose for collecting such information, and
				the categories of third parties with whom we share such information.
			</div>
			<div class="paragraph">
				You have the right to request information about our sale or disclosure for business purposes of your personal information to third
				parties in the preceding 12 months.
			</div>

			<li class="underline">Right to delete</li>
			<div class="paragraph">
				You have the right to request the deletion of your personal information. Please note that notwithstanding your request, California
				law permits us to retain certain categories of personal information for numerous purposes, including information KPA uses to conduct
				business with your company (or client), and to comply with legal obligations, such as record retention requirements.
			</div>

			<li class="underline">Right to opt-out of sale</li>
			<div class="paragraph">
				You have the right to opt out of sale of your personal information to third parties. However, KPA does not, and will not, sell
				personal information to third parties.
			</div>

			<li class="underline">Right to non-discrimination</li>
			<div class="paragraph">You have the right to not be discriminated against for exercising any of these rights.</div>
		</ul>
	</div>

	<div class="paragraph">We do not sell or knowingly collect the personal information of minors under 16 years of age.</div>

	<div class="paragraph">
		If you would like to exercise one or more of the rights above, please contact us using the contact information provided below. You may
		designate an authorized agent to make a request on your behalf by providing us with either (i) a document signed by both you and the
		agent that clearly gives the agent the authority to act on your behalf, or (ii) a valid power of attorney or other equivalent legal
		document granting such authority. We may deny a request from an agent that does not submit proof that they have been authorized by you
		to act on your behalf.
	</div>

	<div class="paragraph">
		We may need to confirm your verifiable consumer request before completing your request, and, for example, may ask for you to confirm
		data points we already have about you. We will only use personal information provided in a verifiable consumer request to verify the
		requestor’s identity or authority to make the request.
	</div>

	<div class="paragraph">
		We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to 90
		days total), we will inform you of the reason and extension period in writing. Any disclosures we provide will only cover the 12-month
		period preceding the verified request’s receipt. The response we provide will also explain the reasons we cannot comply with a request,
		if applicable.
	</div>

	<div class="underline">Submission of CCPA Request</div>
	<div class="paragraph">
		You or your authorized agent can make requests and exercise rights under the CCPA, listed above, using this web form, calling our
		toll-free number at (866) 356-1735, or by submitting an email to <a href="mailto:support@kpa.io">support&#64;kpa.io</a>.
	</div>

	<h3>Report a Breach of the practices on detailed on this Privacy Policy</h3>

	<div class="paragraph">
		If you have received unwanted, unsolicited email sent by KPA or from any KPA system or purporting to be sent via KPA, please forward a
		copy of that email with your comments to <a href="mailto:support@kpa.io">support&#64;kpa.io</a> for review.
	</div>

	<div class="paragraph">
		If you have questions or complaints regarding our Privacy Policy or practices, please contact us at
		<a href="mailto:support@kpa.io">support&#64;kpa.io</a>
		with “Privacy Enquiry” in the subject line and provide detail on your question or complaint so that we may adequately respond. Questions
		or comments regarding this Policy should be submitted to KPA by mail or email as follows:
	</div>

	<div class="contact-info">
		<span class="bold">KPA Corporate Office</span><br />
		11080 CIRCLE POINT ROAD<br />
		SUITE 200<br />
		WESTMINSTER​, CO ​80020<br />
		Phone: <a href="phone:866-356-1735">(866) 356-1735</a><br />
		United States<br />
		<a href="mailto:Support@KPA.io">Support&#64;KPA.io</a><br />
	</div>

	<h2>Updates</h2>

	<div class="paragraph">
		Any updates or changes to our Privacy Policy will be posted to this Privacy Policy link and other places we deem appropriate, so you are
		aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. We reserve the right to
		modify this Privacy Policy at any time, so please review it frequently. If we make material changes to this policy, we will notify you
		via the Services, by email, or by means of a notice on our home page.
	</div>

	<h3>Dispute Resolution and Agreement to Arbitrate</h3>

	<div class="paragraph">
		Except where and to the extent prohibited by law, by using the Services, you and KPA agree that, if there is any controversy, claim,
		action, or dispute arising out of or related to your use of the Services or the breach, enforcement, interpretation, or validity of this
		Policy or any part of it (“Dispute”), both parties shall first try in good faith to settle such Dispute by providing written notice to
		the other party describing the facts and circumstances of the Dispute and allowing the receiving party thirty (30) days in which to
		respond to or settle the Dispute. Notice shall be sent to:
	</div>

	<ul>
		<li>KPA, at 1380 Forest Park Circle, Lafayette, CO 80026 or</li>
		<li>You, at the address we have on file for you.</li>
	</ul>

	<div class="paragraph">
		Both you and KPA agree that this dispute resolution procedure is a condition precedent that must be satisfied before initiating any
		litigation or filing any claim against the other party. IF ANY DISPUTE CANNOT BE RESOLVED BY THE ABOVE DISPUTE RESOLUTION PROCEDURE, YOU
		AGREE THAT THE SOLE AND EXCLUSIVE JURISDICTION FOR SUCH DISPUTE WILL BE DECIDED BY BINDING ARBITRATION ON AN INDIVIDUAL BASIS.
		ARBITRATION ON AN INDIVIDUAL BASIS MEANS THAT YOU WILL NOT HAVE, AND YOU WAIVE, THE RIGHT FOR A JUDGE OR JURY TO DECIDE YOUR CLAIMS, AND
		THAT YOU MAY NOT PROCEED IN A CLASS, CONSOLIDATED, OR REPRESENTATIVE CAPACITY. Other rights that you and KPA would otherwise have in
		court will not be available or will be more limited in arbitration, including discovery and appeal rights. All such disputes shall be
		exclusively submitted to the American Arbitration Association (www.adr.org) for binding arbitration under its rules then in effect,
		before one arbitrator to be mutually agreed upon by both parties.
	</div>

	<div class="paragraph">
		The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve any dispute arising
		under or relating to the interpretation, applicability, enforceability, or formation of this Policy, including any claim that all or any
		part of this Policy is void or voidable.
	</div>

	<h3>OTHER ARBITRATION AGREEMENTS</h3>

	<div class="paragraph">
		In the event of a conflict between this agreement to arbitrate and any other arbitration agreement between you and KPA (Other
		Arbitration Agreement), such as an arbitration agreement contained in a contract about and related to Services with KPA, the terms of
		the Other Arbitration Agreement shall govern and prevail in each instance.
	</div>

	<h3>CHOICE OF LAW</h3>

	<div class="paragraph">
		Any disputes or claims not subject to the arbitration provision discussed above shall be resolved by a court located in the State of
		Colorado and you agree and submit to the exercise of personal jurisdiction of such courts for the purpose of litigating any such claim
		or action.
	</div>

	<h2 id="ca-cookie">Cookie and Automated Technology Notice</h2>

	<div class="paragraph">
		Technologies such as: cookies, beacons, tags, and scripts are used by us and our tracking utility partners. These technologies are used
		in analyzing trends, administering the Website, tracking users’ movements around the Website and to gather demographic information about
		our user base as a whole. We may receive reports based on the use of these technologies by these companies on an individual as well as
		aggregated basis.
	</div>

	<div class="paragraph">
		KPA utilizes Cookie technology on our Websites for purposes of traffic analysis such as the time/date of the visit, the time/date of
		last visit, the page viewed, the referring Website, and other data. KPA also tracks click behavior in the emails it sends out. This data
		is used to update specific user-profile information, ascertain the areas of most interest to opt-in email recipients, and to personalize
		email messages to them.
	</div>

	<div class="paragraph">
		A “cookie” is a small text file that is stored on a user’s computer and downloaded to your computer or mobile device when you visit a
		website. Cookies then send information back to the originating website on each subsequent visit, or to another website that recognizes
		that same cookie. Cookies are useful because they allow a website to recognize a user’s device.
	</div>

	<div class="paragraph">
		Cookies perform many different jobs, like allowing you to navigate between pages efficiently, remembering your preferences, and
		generally improving a user’s experience on a website. They can also help to ensure that the advertisements you see online are more
		relevant to you and your interests. We use different types of cookies on our website, as explained below.
	</div>

	<h3>Required Cookies</h3>

	<div class="paragraph">
		These cookies are necessary for our Website to function properly and cannot be switched off in our systems. They are usually only set in
		response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling
		in forms. You can set your browser to block or alert you about these cookies, but some parts of our Website will work if you block these
		cookies. These cookies do not store any information that identifies you directly.
	</div>

	<h3>Functional Cookies</h3>

	<div class="paragraph">
		Functional cookies allow us to count visits and sources of traffic on our Website so we can measure and improve the performance of our
		Website. They help us to know which pages of our Website are the most and least popular and see how visitors move around our Website.
		The information collected by these cookies is used to create aggregated, anonymous analytics reports. If you do not allow these cookies
		we will not know when you have visited our Website and will not be able to monitor its performance.
	</div>

	<h3>Advertising Cookies</h3>

	<div class="paragraph">
		Advertising cookies may be set through our Website by our advertising partners. They may be used by those companies to build a profile
		of your interests and show you relevant advertisements on other Websites.
	</div>

	<div class="paragraph">
		These cookies do not store directly identifiable personal information but are based on uniquely identifying your browser and internet
		device. If you do not allow these cookies, you will experience less targeted advertising, but you will not stop receiving general
		advertising.
	</div>

	<h3>Social Media Cookies</h3>

	<div class="paragraph">
		These cookies are used to allow you to share information using a social media sharing button (such as the Facebook “Like”) or where you
		choose to link your account with us to your social media networking site such as Pinterest. The social media site will record that you
		have linked your social media account to your account with us. This information may then be used by the social media network to deliver
		targeted advertising to you.
	</div>

	<div class="table">
		<div class="table-body">
			<div class="table-row">
				<div class="table-header">Cookie</div>
				<div class="table-header">Type</div>
				<div class="table-header">Source</div>
			</div>
			<div class="table-row">
				<div class="table-cell">DSID</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">DoubleClick</div>
			</div>
			<div class="table-row">
				<div class="table-cell">IDE</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">DoubleClick</div>
			</div>
			<div class="table-row">
				<div class="table-cell">pardot</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">Paradot</div>
			</div>
			<div class="table-row">
				<div class="table-cell">visitor_id12882</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">Paradot</div>
			</div>
			<div class="table-row">
				<div class="table-cell">visitor_id12882-hash</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">Paradot</div>
			</div>
			<div class="table-row">
				<div class="table-cell">visitor_id548382</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">Paradot</div>
			</div>
			<div class="table-row">
				<div class="table-cell">visitor_id548382-hash</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">Paradot</div>
			</div>
			<div class="table-row">
				<div class="table-cell">S</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">Google</div>
			</div>
			<div class="table-row">
				<div class="table-cell">1P_JAR</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">Google</div>
			</div>
			<div class="table-row">
				<div class="table-cell">_gcl_au</div>
				<div class="table-cell">Advertising</div>
				<div class="table-cell">Google</div>
			</div>
			<div class="table-row">
				<div class="table-cell">__stid</div>
				<div class="table-cell">Functional</div>
				<div class="table-cell">Share This</div>
			</div>
			<div class="table-row">
				<div class="table-cell">__unam</div>
				<div class="table-cell">Functional</div>
				<div class="table-cell">Share This</div>
			</div>
			<div class="table-row">
				<div class="table-cell">HSID</div>
				<div class="table-cell">Functional</div>
				<div class="table-cell">YouTube</div>
			</div>
			<div class="table-row">
				<div class="table-cell">PREF</div>
				<div class="table-cell">Functional</div>
				<div class="table-cell">YouTube</div>
			</div>
			<div class="table-row">
				<div class="table-cell">SAPISID</div>
				<div class="table-cell">Functional</div>
				<div class="table-cell">YouTube</div>
			</div>
			<div class="table-row">
				<div class="table-cell">SID</div>
				<div class="table-cell">Functional</div>
				<div class="table-cell">YouTube</div>
			</div>
			<div class="table-row">
				<div class="table-cell">SSID</div>
				<div class="table-cell">Functional</div>
				<div class="table-cell">YouTube</div>
			</div>
			<div class="table-row">
				<div class="table-cell">VISITOR_INFO1_LIVE</div>
				<div class="table-cell">Functional</div>
				<div class="table-cell">YouTube</div>
			</div>
			<div class="table-row">
				<div class="table-cell">_ga</div>
				<div class="table-cell">Performance</div>
				<div class="table-cell">Google</div>
			</div>
			<div class="table-row">
				<div class="table-cell">_gid</div>
				<div class="table-cell">Performance</div>
				<div class="table-cell">Google</div>
			</div>
			<div class="table-row">
				<div class="table-cell">__cfduid</div>
				<div class="table-cell">Required</div>
				<div class="table-cell">CloudFlare</div>
			</div>
			<div class="table-row">
				<div class="table-cell">_fbp</div>
				<div class="table-cell">Social Media</div>
				<div class="table-cell">Facebook</div>
			</div>
			<div class="table-row">
				<div class="table-cell">datr</div>
				<div class="table-cell">Social Media</div>
				<div class="table-cell">Facebook</div>
			</div>
			<div class="table-row">
				<div class="table-cell">Fr</div>
				<div class="table-cell">Social Media</div>
				<div class="table-cell">Facebook</div>
			</div>
			<div class="table-row">
				<div class="table-cell">Sb</div>
				<div class="table-cell">Social Media</div>
				<div class="table-cell">Facebook</div>
			</div>
			<div class="table-row">
				<div class="table-cell">Wd</div>
				<div class="table-cell">Social Media</div>
				<div class="table-cell">Facebook</div>
			</div>
			<div class="table-row">
				<div class="table-cell">visitor_id302971</div>
				<div class="table-cell"></div>
				<div class="table-cell">KPA</div>
			</div>
			<div class="table-row">
				<div class="table-cell">visitor_id302971-hash</div>
				<div class="table-cell"></div>
				<div class="table-cell">KPA</div>
			</div>
			<div class="table-row">
				<div class="table-cell">ID</div>
				<div class="table-cell"></div>
				<div class="table-cell">YouTube</div>
			</div>
			<div class="table-row">
				<div class="table-cell">_gat_UA-7045932-1</div>
				<div class="table-cell"></div>
				<div class="table-cell">KPA</div>
			</div>
			<div class="table-row">
				<div class="table-cell">visitor_id302971</div>
				<div class="table-cell"></div>
				<div class="table-cell">KPA</div>
			</div>
			<div class="table-row">
				<div class="table-cell">visitor_id302971-hash</div>
				<div class="table-cell"></div>
				<div class="table-cell">KPA</div>
			</div>
		</div>
	</div>
</div>
