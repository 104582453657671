import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-contact-us-ali',
	templateUrl: './contact-us-ali.component.html',
	styleUrls: ['./contact-us-ali.component.css'],
})
export class ContactUsAliComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
