import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
	selector: '[kpaRequired]',
	exportAs: 'kpaRequired',
})
export class KpaRequiredDirective {
	private _required: boolean = true;

	@Input('kpaRequired')
	get required(): boolean {
		return this._required;
	}
	set required(value: boolean) {
		if (typeof value !== 'boolean') return;
		this._required = value;
	}

	@HostBinding('class.kpa-required')
	public get decoratorClass(): boolean {
		return this.required;
	}
}
