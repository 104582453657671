<div kendoRippleContainer>
	<h1>KPA Theme Components</h1>

	<h2>Button</h2>

	<h3>Primary Button</h3>
	<button
		kendoButton
		kpaPrimaryButton>
		Browse</button
	>&nbsp;
	<button
		kendoButton
		kpaPrimaryButton
		[icon]="'folder'">
		Browse</button
	>&nbsp;
	<button
		kendoButton
		kpaPrimaryButton
		[icon]="'folder'"></button>
	&nbsp;
	<button
		kendoButton
		kpaPrimaryButton
		[disabled]="true">
		Browse</button
	>&nbsp;
	<button
		kendoButton
		kpaPrimaryButton
		[icon]="'folder'"
		[disabled]="true">
		Browse</button
	>&nbsp;
	<button
		kendoButton
		kpaPrimaryButton
		[icon]="'folder'"
		[disabled]="true"></button>

	<h3>Secondary Button</h3>
	<button
		kendoButton
		kpaSecondaryButton>
		Browse</button
	>&nbsp;
	<button
		kendoButton
		kpaSecondaryButton
		[icon]="'folder'">
		Browse</button
	>&nbsp;
	<button
		kendoButton
		kpaSecondaryButton
		[icon]="'folder'"></button
	>&nbsp;
	<button
		kendoButton
		kpaSecondaryButton
		[disabled]="true">
		Browse</button
	>&nbsp;
	<button
		kendoButton
		kpaSecondaryButton
		[icon]="'folder'"
		[disabled]="true">
		Browse</button
	>&nbsp;
	<button
		kendoButton
		kpaSecondaryButton
		[icon]="'folder'"
		[disabled]="true"></button>

	<h3>Text Button</h3>
	<button
		kendoButton
		kpaTextButton>
		Browse</button
	>&nbsp;
	<button
		kendoButton
		kpaTextButton
		[icon]="'folder'">
		Browse</button
	>&nbsp;
	<button
		kendoButton
		kpaTextButton
		[icon]="'folder'"></button
	>&nbsp;
	<button
		kendoButton
		kpaTextButton
		[disabled]="true">
		Browse</button
	>&nbsp;
	<button
		kendoButton
		kpaTextButton
		[icon]="'folder'"
		[disabled]="true">
		Browse</button
	>&nbsp;
	<button
		kendoButton
		kpaTextButton
		[icon]="'folder'"
		[disabled]="true"></button>

	<h2>Button Group</h2>
	<kendo-buttongroup
		[selection]="'single'"
		kpaButtonGroup
		[kpaToggleable]="true">
		<button
			kendoButton
			[icon]="'align-left'"
			[selected]="true">
			Left
		</button>
		<button
			kendoButton
			[icon]="'align-center'">
			Center
		</button>
		<button
			kendoButton
			[icon]="'align-right'">
			Right
		</button>
		<button
			kendoButton
			[icon]="'align-justify'">
			Justified
		</button>
	</kendo-buttongroup>

	<h2>Chip</h2>
	<kendo-chip
		*ngFor="let chip of chips"
		[label]="chip.label"
		[icon]="chip.icon"
		[removeIcon]="'k-icon k-i-close'"
		[type]="chip.type"
		[removable]="chip.removable"></kendo-chip>

	<h2>Date Picker</h2>
	<input
		type="checkbox"
		#datePickerRequiredCheckbox
		kendoCheckBox
		[formControl]="datePickerRequired" />
	<kendo-label
		class="k-checkbox-label"
		[for]="datePickerRequiredCheckbox"
		text="Required"></kendo-label>
	<kendo-formfield [kpaRequired]="datePickerRequired.value">
		<kendo-floatinglabel text="Incident Date">
			<kendo-datepicker
				[formControl]="datePickerValue"
				[max]="now"></kendo-datepicker>
		</kendo-floatinglabel>
		<kendo-formhint *ngIf="datePickerRequired.value && (datePickerValue.pristine || !datePickerValue.errors)"> Required</kendo-formhint>
		<kendo-formerror *ngIf="datePickerValue.errors?.required">An incident date must be provided.</kendo-formerror>
		<kendo-formerror *ngIf="datePickerValue.errors?.maxdate">A historical incident date must be provided. </kendo-formerror>
	</kendo-formfield>

	<h2>Autocomplete</h2>
	<input
		type="checkbox"
		#autoCompleteRequiredCheckbox
		kendoCheckBox
		[formControl]="autoCompleteRequired" />
	<kendo-label
		class="k-checkbox-label"
		[for]="autoCompleteRequiredCheckbox"
		text="Required"></kendo-label>
	<kendo-formfield [kpaRequired]="autoCompleteRequired.valueChanges | async">
		<kendo-floatinglabel text="Country">
			<kendo-autocomplete
				[data]="autoCompleteListItems"
				[formControl]="autoCompleteValue"
				[suggest]="true"
				[kendoDropDownFilter]="autoCompleteFilterSettings"></kendo-autocomplete>
		</kendo-floatinglabel>
		<kendo-formhint *ngIf="autoCompleteRequired.value && (autoCompleteValue.pristine || !autoCompleteValue.errors)">
			Required</kendo-formhint
		>
		<kendo-formerror *ngIf="autoCompleteValue.errors?.required">You must select a country.</kendo-formerror>
		<kendo-formerror *ngIf="autoCompleteValue.errors?.includes">You must select a country from the suggestion list. </kendo-formerror>
	</kendo-formfield>

	<h2>Dropdown List</h2>
	<input
		type="checkbox"
		#dropdownRequiredCheckbox
		kendoCheckBox
		[formControl]="dropdownRequired" />
	<kendo-label
		class="k-checkbox-label"
		[for]="dropdownRequiredCheckbox"
		text="Required"></kendo-label>
	<kendo-formfield [kpaRequired]="dropdownRequired.valueChanges | async">
		<kendo-floatinglabel text="Shirt Size">
			<kendo-dropdownlist
				kpaDropDownList
				[data]="dropdownItems"
				[valuePrimitive]="true"
				[formControl]="dropdownValue">
			</kendo-dropdownlist>
		</kendo-floatinglabel>
		<kendo-formhint *ngIf="dropdownRequired.value && (dropdownValue.pristine || !dropdownValue.errors)">Required </kendo-formhint>
		<kendo-formerror *ngIf="dropdownValue.errors?.required">Shirt size is required.</kendo-formerror>
	</kendo-formfield>

	<h2>Multiselect</h2>
	<input
		type="checkbox"
		#multiselectRequiredCheckbox
		kendoCheckBox
		[formControl]="multiselectRequired" />
	<kendo-label
		class="k-checkbox-label"
		[for]="multiselectRequiredCheckbox"
		text="Required"></kendo-label>
	<kendo-formfield [kpaRequired]="multiselectRequired.valueChanges | async">
		<kendo-floatinglabel text="Shirt Sizes">
			<kendo-multiselect
				kpaMultiSelect
				[formControl]="multiselectValue"
				[data]="multiselectItems"
				[valuePrimitive]="true"
				[autoClose]="false">
				<ng-template
					kendoMultiSelectItemTemplate
					let-kpaItem>
					<kpa-multiselect-item [kpaItem]="kpaItem"></kpa-multiselect-item>
				</ng-template>
			</kendo-multiselect>
		</kendo-floatinglabel>
		<kendo-formhint *ngIf="multiselectRequired.value && (multiselectValue.pristine || !multiselectValue.errors)"> Required</kendo-formhint>
		<kendo-formerror *ngIf="multiselectValue.errors?.required">At least one size must be chosen.</kendo-formerror>
		<kendo-formerror *ngIf="multiselectValue.errors?.maxlength">No more than three sizes may be chosen. </kendo-formerror>
	</kendo-formfield>

	<h2>Textbox</h2>
	<input
		type="checkbox"
		#textboxRequiredCheckbox
		kendoCheckBox
		[formControl]="textboxRequired" />
	<kendo-label
		class="k-checkbox-label"
		[for]="textboxRequiredCheckbox"
		text="Required"></kendo-label>
	<kendo-formfield [kpaRequired]="textboxRequired.valueChanges | async">
		<kendo-floatinglabel text="Zip Code">
			<kendo-textbox [formControl]="textboxValue"></kendo-textbox>
		</kendo-floatinglabel>
		<kendo-formhint *ngIf="textboxRequired.value && (textboxValue.pristine || !textboxValue.errors)">Required </kendo-formhint>
		<kendo-formerror *ngIf="textboxValue.errors?.required">Zip code is required.</kendo-formerror>
		<kendo-formerror *ngIf="textboxValue.errors?.pattern">Zip code should only contain exactly five digits. </kendo-formerror>
	</kendo-formfield>

	<h2>Text Area</h2>
	<input
		type="checkbox"
		#textareaRequiredCheckbox
		kendoCheckBox
		[formControl]="textareaRequired" />
	<kendo-label
		class="k-checkbox-label"
		[for]="textareaRequiredCheckbox"
		text="Required"></kendo-label>
	<kendo-formfield [kpaRequired]="textareaRequired.valueChanges | async">
		<kendo-floatinglabel text="Comments">
			<kendo-textarea
				[formControl]="textareaValue"
				resizable="auto"></kendo-textarea>
		</kendo-floatinglabel>
		<kendo-formhint *ngIf="textareaRequired.value && (textareaValue.pristine || !textareaValue.errors)">Required </kendo-formhint>
		<kendo-formerror *ngIf="textareaValue.errors?.required">Comments are required.</kendo-formerror>
	</kendo-formfield>

	<h2>Checkbox</h2>
	<input
		type="checkbox"
		#sampleCheckbox
		kendoCheckBox />
	<kendo-label
		class="k-checkbox-label"
		[for]="sampleCheckbox"
		text="I accept the terms and conditions"></kendo-label>

	<h2>Grid</h2>
	<kendo-grid
		kpaGrid
		[data]="gridData"
		[pageSize]="gridState.take"
		[skip]="gridState.skip"
		[sort]="gridState.sort"
		[filter]="gridState.filter"
		[sortable]="true"
		[pageable]="true"
		[filterable]="true"
		[resizable]="true"
		(dataStateChange)="gridDataStateChange($event)">
		<kendo-grid-column
			field="ProductID"
			title="ID"
			width="68"
			[filterable]="false"
			[autoSize]="true">
		</kendo-grid-column>
		<kendo-grid-column
			field="ProductName"
			title="Product Name">
		</kendo-grid-column>
		<kendo-grid-column
			field="FirstOrderedOn"
			title="First Ordered On"
			width="240"
			[filterable]="false"
			format="{0:d}">
		</kendo-grid-column>
		<kendo-grid-column
			field="UnitPrice"
			title="Unit Price"
			width="180"
			[filterable]="false"
			format="{0:c}">
		</kendo-grid-column>
		<kendo-grid-column
			field="Discontinued"
			width="140"
			[filterable]="false">
			<ng-template
				kendoGridCellTemplate
				let-dataItem>
				<input
					kendoCheckBox
					type="checkbox"
					[checked]="dataItem.Discontinued"
					disabled />
			</ng-template>
		</kendo-grid-column>
		<kendo-grid-column
			field="LastOrderedOn"
			title="Last Ordered On"
			width="300"
			[filterable]="false">
			<ng-template
				kendoGridCellTemplate
				let-dataItem>
				<kpa-grid-date-range-filter
					field="LastOrderedOn"
					[filter]="filter"></kpa-grid-date-range-filter>
			</ng-template>
		</kendo-grid-column>
		<kendo-grid-column
			field="AssignedTo"
			title="AssignedTo"
			width="300">
			<ng-template
				kendoGridFilterCellTemplate
				let-filter>
				<kpa-multiselect-search-filter
					[filter]="filter"
					[data]="employeesAssignedTo"
					textField="textField"
					(filterChangesearch)="EmployeeSearchAssignedTo($event)"
					valueField="AssignedToId"
					style="width: 300px"
					filterField="AssignedToId">
				</kpa-multiselect-search-filter>
			</ng-template>
		</kendo-grid-column>
	</kendo-grid>

	<h2>Panel Bar</h2>
	<kendo-panelbar [expandMode]="expandMode">
		<kendo-panelbar-item
			[title]="'Basic Information'"
			[expanded]="true">
			<ng-template kendoPanelBarContent>
				<div class="kpa-item">
					<p>Did an incident occur, or was this a near miss?</p>
				</div>
			</ng-template>
		</kendo-panelbar-item>
		<kendo-panelbar-item [title]="'Investigation & Additional Information'">
			<kendo-panelbar-item
				[title]="'Involved Persons Details'"
				[expanded]="true">
				<ng-template kendoPanelBarContent>
					<div class="kpa-item">
						<p>Is this invovled person an employee?</p>
					</div>
				</ng-template>
			</kendo-panelbar-item>
			<kendo-panelbar-item [title]="'Witness Details'">
				<ng-template kendoPanelBarContent>
					<div class="kpa-item">
						<p>Is this witness an employee?</p>
					</div>
				</ng-template>
			</kendo-panelbar-item>
		</kendo-panelbar-item>
	</kendo-panelbar>

	<h2>Tab Strip</h2>
	<kendo-tabstrip>
		<kendo-tabstrip-tab
			[title]="'Paris'"
			[selected]="true">
			<ng-template kendoTabContent>
				<p>
					Paris is the capital and most populous city of France. It has an area of 105 square kilometres (41 square miles) and a population
					in 2013 of 2,229,621 within its administrative limits. The city is both a commune and department, and forms the centre and
					headquarters of the Île-de-France, or Paris Region, which has an area of 12,012 square kilometres (4,638 square miles) and a
					population in 2014 of 12,005,077, comprising 18.2 percent of the population of France.
				</p>
			</ng-template>
		</kendo-tabstrip-tab>
		<kendo-tabstrip-tab [title]="'New York City'">
			<ng-template kendoTabContent>
				<p>
					The City of New York, often called New York City or simply New York, is the most populous city in the United States. With an
					estimated 2015 population of 8,550,405 distributed over a land area of just 305 square miles (790 km2), New York City is also the
					most densely populated major city in the United States. Located at the southern tip of the state of New York, the city is the
					center of the New York metropolitan area, one of the most populous urban agglomerations in the world.
				</p>
				<p>
					A global power city, New York City exerts a significant impact upon commerce, finance, media, art, fashion, research, technology,
					education, and entertainment, its fast pace defining the term New York minute. Home to the headquarters of the United Nations, New
					York is an important center for international diplomacy and has been described as the cultural and financial capital of the world.
				</p>
			</ng-template>
		</kendo-tabstrip-tab>
		<kendo-tabstrip-tab [title]="'Tallinn'">
			<ng-template kendoTabContent>
				<p>
					Tallinn is the capital and largest city of Estonia. It is situated on the northern coast of the country, on the shore of the Gulf
					of Finland, 80 km (50 mi) south of Helsinki, east of Stockholm and west of Saint Petersburg. From the 13th century until 1918 (and
					briefly during the Nazi occupation of Estonia from 1941 to 1944), the city was known as Reval. Tallinn occupies an area of 159.2
					km2 (61.5 sq mi) and has a population of 443,894. Approximately 32% of Estonia's total population lives in Tallinn.
				</p>
				<p>
					Tallinn was founded in 1248, but the earliest human settlements are over 5,000 years old, making it one of the oldest capital
					cities of Northern Europe. Due to its strategic location, the city became a major trade hub, especially from the 14th to the 16th
					century, when it grew in importance as part of the Hanseatic League.
				</p>
			</ng-template>
		</kendo-tabstrip-tab>
		<kendo-tabstrip-tab
			[title]="'Sydney'"
			[disabled]="true"></kendo-tabstrip-tab>
	</kendo-tabstrip>

	<h2>Tooltip</h2>

	<p
		kendoTooltip
		[position]="tooltipPosition.value">
		Select a tooltip position below then mouse over
		<a
			href="#"
			title="Link description!"
			>this anchor</a
		>
		to view the tooltip.
	</p>

	<p>Which tooltip position would you like to preview?</p>
	<ul class="tooltipOptions">
		<li>
			<input
				#tooltipTop
				type="radio"
				kendoRadioButton
				[formControl]="tooltipPosition"
				value="top" />
			<kendo-label
				class="k-radio-label"
				[for]="tooltipTop"
				text="Top"></kendo-label>
		</li>
		<li>
			<input
				#tooltipBottom
				type="radio"
				kendoRadioButton
				[formControl]="tooltipPosition"
				value="bottom" />
			<kendo-label
				class="k-radio-label"
				[for]="tooltipBottom"
				text="Bottom"></kendo-label>
		</li>
		<li>
			<input
				#tooltipRight
				type="radio"
				kendoRadioButton
				[formControl]="tooltipPosition"
				value="right" />
			<kendo-label
				class="k-radio-label"
				[for]="tooltipRight"
				text="Right"></kendo-label>
		</li>
		<li>
			<input
				#tooltipLeft
				type="radio"
				kendoRadioButton
				[formControl]="tooltipPosition"
				value="left" />
			<kendo-label
				class="k-radio-label"
				[for]="tooltipLeft"
				text="Left"></kendo-label>
		</li>
	</ul>
</div>

<!-- <kendo-tilelayout [columns]="4">
	<kendo-tilelayout-item
		[col]="1"
		[colSpan]="4"
		class="section-styling">
		<kendo-tilelayout-item-header>
			<h1>Primary Buttons</h1>
			<p>The primary button styling used throughout the app. Used for buttons like Add, Create, Update, Confirm, Yes.</p>
		</kendo-tilelayout-item-header>
		<kendo-tilelayout-item-body class="item-alignment">
			<button
				kendoButton
				id="button-id-1"
				kpaPrimaryButton
				class="kpa-primary spacing"
				(click)="dummyOnClick()"
				[disabled]="this.dummyDisableFlag">
				Add
			</button>

			<button
				kendoButton
				id="button-id-2"
				kpaPrimaryButton
				class="kpa-primary spacing"
				(click)="dummyOnClick()"
				[disabled]="this.dummyDisableFlag">
				<span class="center-icon">
					<i
						class="fa fa-plus"
						aria-hidden="true"></i>
					Add
				</span>
			</button>

			<button
				kendoButton
				id="button-id-3"
				kpaPrimaryButton
				class="kpa-primary spacing"
				(click)="dummyOnClick()"
				[disabled]="this.dummyDisableFlag">
				<span class="center-icon">
					<i
						class="fa fa-plus"
						aria-hidden="true"></i>
				</span>
			</button>
		</kendo-tilelayout-item-body>
	</kendo-tilelayout-item>

	<kendo-tilelayout-item
		[col]="1"
		[colSpan]="4"
		class="section-styling">
		<kendo-tilelayout-item-header
			><h1>Secondary Buttons:</h1>
			<p>The secondary button styling used throughout the app. Used for buttons like Cancel, No</p>
		</kendo-tilelayout-item-header>
		<kendo-tilelayout-item-body class="item-alignment">
			<button
				kendoButton
				id="button-id-1-2"
				kpaSecondaryButton
				class="kpa-secondary spacing"
				(click)="dummyOnClick()"
				[disabled]="this.dummyDisableFlag">
				Add
			</button>

			<button
				kendoButton
				id="button-id-2-2"
				kpaSecondaryButton
				class="kpa-secondary spacing"
				(click)="dummyOnClick()"
				[disabled]="this.dummyDisableFlag">
				<span class="center-icon">
					<i
						class="fa fa-plus"
						aria-hidden="true"></i>
					Add
				</span>
			</button>

			<button
				kendoButton
				id="button-id-3-3"
				kpaSecondaryButton
				class="kpa-secondary spacing"
				(click)="dummyOnClick()"
				[disabled]="this.dummyDisableFlag">
				<span class="center-icon">
					<i
						class="fa fa-plus"
						aria-hidden="true"></i>
				</span>
			</button>
		</kendo-tilelayout-item-body>
	</kendo-tilelayout-item>

	<kendo-tilelayout-item
		[col]="1"
		[colSpan]="4"
		class="section-styling">
		<kendo-tilelayout-item-header
			><h1>DropDowns:</h1>
			<p></p>
		</kendo-tilelayout-item-header>
		<kendo-tilelayout-item-body class="item-alignment">
			<div
				class="k-form-field"
				fxLayout="column">
				<span
					class="dd-label"
					id="dropdown-label-1"
					>Employee Name (Type to find name...)</span
				>

				<kendo-dropdownlist
					appFloatingDropDownlabel
					[data]="employeesList"
					[textField]="'text'"
					[valueField]="'value'"
					[defaultItem]="emptyPlaceholder"
					[valuePrimitive]="true"
					class="kendo-dropdown k-dropdown"
					id="dropdown-1"
					name="peopleId"
					(selectionChange)="dummyOnChange($event)"
					(filterChange)="handleFilter($event)"
					[filterable]="true">
				</kendo-dropdownlist>
			</div>

			<div
				class="k-form-field"
				fxLayout="column">
				<span
					class="dd-label"
					id="dropdown-label-2"
					>Employee Name</span
				>

				<kendo-dropdownlist
					appFloatingDropDownlabel
					[data]="employeesList"
					[textField]="'text'"
					[valueField]="'value'"
					[defaultItem]="emptyPlaceholder"
					[valuePrimitive]="true"
					class="kendo-dropdown k-dropdown"
					id="dropdown-2"
					name="peopleId"
					(selectionChange)="dummyOnChange($event)">
				</kendo-dropdownlist>
			</div>
		</kendo-tilelayout-item-body>
	</kendo-tilelayout-item>

	<kendo-tilelayout-item
		[col]="1"
		[colSpan]="4"
		class="section-styling">
		<kendo-tilelayout-item-header
			><h1>MultiSelects:</h1>
			<p></p>
		</kendo-tilelayout-item-header>
		<kendo-tilelayout-item-body class="item-alignment"> </kendo-tilelayout-item-body>
	</kendo-tilelayout-item>
</kendo-tilelayout> -->
