import { User, UserManager, UserManagerSettings, UserSettings } from 'oidc-client';

export const IMPERSONATION_STATE_KEY = 'oidc.start_impersonation';

export class ImpersonationUserManager extends UserManager {
	/**
	 * Creates an ImpersonationUserManager
	 * @param settings The settings for the ImpersonationUserManager
	 * @param impersonationStorage The storage container for originator (can differ from user storage)
	 */
	constructor(
		settings: UserManagerSettings,
		private readonly impersonationStorage: Storage
	) {
		super(settings);
		this._originatorKey = `originator:${this.settings.authority}:${this.settings.client_id}`;
	}

	private readonly _originatorKey: string;

	/**
	 * Returns User object if impersonating, null otherwise.
	 */
	public async getOriginator(): Promise<User> {
		// Get the Originator from store and return if possible
		const storageString = this.impersonationStorage.getItem(this._originatorKey);

		const result = storageString ? new User(JSON.parse(storageString) as UserSettings) : null;

		return Promise.resolve(result);
	}

	/**
	 * Stores the originator
	 * @param originator The originator User
	 */
	private async setOriginator(originator: User): Promise<void> {
		const originatorString = originator.toStorageString();
		this.impersonationStorage.setItem(this._originatorKey, originatorString);
		return Promise.resolve();
	}

	/**
	 * Removes originator User object from storage if it exists.
	 */
	private async removeOriginator(): Promise<User> {
		const originator = await this.getOriginator();
		this.impersonationStorage.removeItem(this._originatorKey);
		return originator;
	}

	/**
	 * @deprecated Use completeAuthenticationCallback()
	 */
	public signinRedirectCallback(url?: string): Promise<User> {
		return super.signinRedirectCallback(url);
	}

	/**
	 * Completes authentication for a user
	 * @param allowImpersonate True for ImpersonateCallback, false otherwise.
	 * @param url Redirect Url
	 * @returns Logged in impersonated user if
	 */
	public async completeAuthenticationCallback(allowImpersonate: boolean, url?: string): Promise<User> {
		const currentUser = await this.getUser();
		const newUser = await super.signinRedirectCallback(url);

		// If currentUser and newUser are the same person, don't need to keep themselves as originator
		if (currentUser && currentUser.profile && newUser && newUser.profile) {
			if (currentUser.profile.sub == newUser.profile.sub) {
				//NOTE: when we get here, the SIDs are different as SSO must regenerate
				await this.removeOriginator();
				return newUser;
			}
		}

		// Check that this is a requested and authorized impersonation
		if (allowImpersonate && newUser.state[IMPERSONATION_STATE_KEY]) {
			await this.setOriginator(currentUser);
		}
		// Clear any straggling originator if we're starting a new login
		else {
			await this.removeOriginator();
		}

		return newUser;
	}

	/**
	 * Removes impersonated user from localStorage key and replaces it with Originator user
	 * @returns Originator user
	 */
	public async endImpersonationCallback(): Promise<User> {
		const originator = await this.removeOriginator();
		await this.storeUser(originator);
		return originator;
	}

	/**
	 * Override for the OIDC UserManager storeUser to check for all valid
	 * information prior to storing the User object in Local Storage.  This
	 * is necessary because Impersonation Tokens appear to be lost on navigation
	 * from MKO or HRM back to Platform.
	 */
	public storeUser(user) {
		// Check to make sure that either the User is null (removing the User)
		// of the User is in a state that is worth persisting to Local Storage,
		// which means having a valid access_token.
		if (!user || user.access_token) {
			// Call into the base method
			return super.storeUser(user);
		}
	}
}
