import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-disclaimer',
	templateUrl: './collection-personal-info-notice.component.html',
	styleUrls: ['./collection-personal-info-notice.component.css'],
})
export class CollectionPersonalInfoNoticeComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
