import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'replace',
})
export class ReplacePipe implements PipeTransform {
	transform(value, searchText, replaceText) {
		return value.replaceAll(searchText, replaceText);
	}
}
