<div
	fxLayout="column"
	fxLayoutAlign="start center">
	<div class="spinner">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			version="1"
			width="58px"
			height="58px"
			viewBox="0 0 28 28">
			<g class="qp-circular-loader">
				<path
					class="qp-circular-loader-path"
					fill="none"
					d="M 14,1.5 A 12.5,12.5 0 1 1 1.5,14" />
			</g>
		</svg>
	</div>
	<div class="kpa-spinner-text">Loading</div>
</div>
