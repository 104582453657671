import { Directive, HostBinding, KeyValueDiffers, Optional } from '@angular/core';
import { ButtonDirective } from '@progress/kendo-angular-buttons';
import { KpaMonitorChanges } from '../../shared/kpa-monitor-changes';

@Directive({
	selector: '[kpaPrimaryButton]',
	exportAs: 'kpaPrimaryButton',
})
export class KpaPrimaryButtonDirective extends KpaMonitorChanges<ButtonDirective> {
	@HostBinding('class.kpa-primary')
	get defaultClass(): boolean {
		return true;
	}

	constructor(
		@Optional() private readonly directive: ButtonDirective,
		diff: KeyValueDiffers
	) {
		super(diff, directive, 'look', 'primary');
		if (!directive || !(directive instanceof ButtonDirective))
			throw 'The KpaPrimaryButtonDirective has a sibling dependency of the Kendo ButtonDirective. Please apply this attribute directive to your button element.';
	}

	setBehaviors(): void {
		this.directive.look = 'flat';
		this.directive.primary = true;
		this.directive.fillMode = 'solid';
		this.directive.themeColor = 'info';
		this.directive.setAttribute('style', 'background-color: #1c75bc; border-color: #1c75bc;');
	}
}
