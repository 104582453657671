import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActionsLayout } from '@progress/kendo-angular-dialog';

@Component({
	selector: 'kpa-confirm-discard-changes',
	templateUrl: './confirm-discard-changes.component.html',
	styleUrls: ['./confirm-discard-changes.component.css'],
})
export class ConfirmDiscardChangesComponent implements OnInit {
	actionsLayout: ActionsLayout = 'end';
	constructor() {}

	ngOnInit() {}

	@Output() confirm = new EventEmitter<void>();
	@Output() cancel = new EventEmitter<void>();

	onConfirm() {
		this.confirm.emit();
	}

	onCancel() {
		this.cancel.emit();
	}
}
