<div class="container">
	<div
		fxLayout="column"
		class="innerContainer">
		<div class="flex-item">
			<div class="title">
				Automotive Lift Institute <br />
				Lifting It Right
			</div>
			<div class="subtitle">
				Thank you for your interest in the online Lifting It Right certificate course. <br /><br />
				Please contact KPA to create an account for your company and employees:
			</div>
		</div>

		<div class="center-alignment subtitle">
			<br />
			<strong>(800) 486-0400</strong> or
			<strong
				><a
					class="mailto-link"
					href="mailto:Support@kpa.io"
					>Support&#64;kpa.io</a
				></strong
			>
		</div>

		<div class="center-alignment subtitle">
			<br /><br />
			If you already have a KPA account,<br /><a
				class="mailto-link"
				href="/dashboard"
				>sign in to Vera Suite</a
			>.
		</div>

		<div class="center-alignment aliContainer">
			<br />
			<img
				class="img-ali"
				src="../../../assets/layout/images/automotiveliftinstituteinc-logo.jpg"
				alt="" />
			<img
				class="img-kpa"
				src="../../../assets/layout/images/Small Linework Color Positive Light.png"
				alt="" />
		</div>
	</div>
</div>
