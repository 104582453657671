import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PlatformLayoutComponent } from './shared/components/platform-layout/platform-layout.component';
import { AuthGuardService } from '@app/core/services/auth-guard.service';
import { LandingComponent } from '@app/core/components/landing/landing.component';
import { LandingRoutes } from '@app/core/components/landing/landing-routes';
import { LandingGuardService } from '@app/core/services/landing-guard.service';
import { AuthComponent } from '@app/core/components/auth/auth.component';
import { AuthRoutes } from '@app/core/components/auth/auth-routes';
import { CoreModule } from '@app/core/core.module';
import { ImpersonateGuardService } from '@app/core/services/impersonate-guard.service';
import { SsoLayoutComponent } from './shared/components/sso-layout/sso-layout.component';
import { ContactUsAliComponent } from './home/contact-us-ali/contact-us-ali.component';
import { ErrorComponent } from './core/components/error/error.component';
import { DashboardGuardService } from './core/services/dashboard-guard.service';
import { KpaThemeSampleComponent } from './shared/kendo/sample/sample.component';
import { KpaThemeSampleGuard } from './shared/kendo/sample/sample.guard';
import { KpaComponentLibraryGuardService } from './core/services/kpa-component-library-guard.service';

const routes: Routes = [
	{ path: AuthRoutes.AuthenticateCallback, component: AuthComponent },
	{ path: AuthRoutes.Logout, component: AuthComponent },
	{ path: AuthRoutes.LogoutCallback, component: AuthComponent },
	{ path: AuthRoutes.ImpersonateCallback, component: AuthComponent, canActivate: [ImpersonateGuardService] },
	{ path: AuthRoutes.StartImpersonation + '/:userId', component: AuthComponent, canActivate: [ImpersonateGuardService] },
	{ path: AuthRoutes.EndImpersonation, component: AuthComponent },
	{ path: 'marketing', loadChildren: () => import('../app/marketing/marketing.module').then((m) => m.MarketingModule) },
	{
		// authenticated routes
		path: '',
		canActivate: [AuthGuardService],
		component: PlatformLayoutComponent,
		runGuardsAndResolvers: 'always',

		children: [
			{ path: '', pathMatch: 'full', redirectTo: '/landing' },
			{
				path: LandingRoutes.RedirectRoute,
				canActivate: [LandingGuardService],
				component: LandingComponent,
			},
			{
				path: LandingRoutes.DefaultRoute,
				canActivate: [DashboardGuardService],
				loadChildren: () => import('../app/dashboard/dashboard.module').then((m) => m.DashboardModule),
			},
			{ path: 'dashboard', loadChildren: () => import('../app/dashboard/dashboard.module').then((m) => m.DashboardModule) },
			{ path: 'glba', loadChildren: () => import('../app/glba/glba.module').then((m) => m.GLBAModule) },
			{ path: 'vehicle', loadChildren: () => import('../app/vehicle/vehicle.module').then((m) => m.VehicleModule) },
			{
				path: 'feedbackmanagement',
				loadChildren: () => import('../app/feedback-management/feedback-management.module').then((m) => m.FeedbackManagementModule),
			},
			{ path: 'northstar', loadChildren: () => import('../app/northstar/northstar.module').then((m) => m.NorthstarModule) },
			{ path: 'employee', loadChildren: () => import('../app/employee/employee.module').then((m) => m.EmployeeModule) },
			{ path: 'clients', loadChildren: () => import('../app/clients/clients.module').then((m) => m.ClientsModule) },
			{ path: 'home', loadChildren: () => import('../app/home/home.module').then((m) => m.HomeModule) },
			{ path: 'reporting', loadChildren: () => import('../app/reporting/reporting.module').then((m) => m.ReportingModule) },
			{ path: 'inspections', loadChildren: () => import('../app/inspections/inspections.module').then((m) => m.InspectionsModule) },
			{ path: 'incidents', loadChildren: () => import('../app/incidents/incidents.module').then((m) => m.IncidentsModule) },
			{ path: 'import', loadChildren: () => import('../app/import/import.module').then((m) => m.ImportModule) },
			{ path: 'roles', loadChildren: () => import('../app/roles-permissions/roles.module').then((m) => m.RolesModule) },
			{ path: 'notifications', loadChildren: () => import('../app/notifications/notifications.module').then((m) => m.NotificationsModule) },
			{ path: 'training', loadChildren: () => import('../app/training/training.module').then((m) => m.TrainingModule) },
			{ path: 'tasks', loadChildren: () => import('../app/tasks/tasks.module').then((m) => m.TasksModule) },
			{ path: 'licensespermits', loadChildren: () => import('../app/veratrack/veratrack.module').then((m) => m.VeraTrackModule) },
			{
				path: 'vendormanagement',

				loadChildren: () => import('../app/vendor-management/vendor-management.module').then((m) => m.VendorManagementModule),
			},
			{
				path: 'website-risk-assessment',
				loadChildren: () =>
					import('../app/website-risk-assessment/website-risk-assessment.module').then((m) => m.WebsiteRiskAssessmentModule),
			},
		],
	},
	{
		path: LandingRoutes.MobileRoute,
		loadChildren: () => import('../app/mobile-landing/mobile-landing.module').then((m) => m.MobileLandingModule),
	},
	{ path: 'useraccount', loadChildren: () => import('../app/user-account/user-account.module').then((m) => m.UserAccountModule) },
	{
		/* NOTES:
       1)  This is needed here (as opposed to being declared in the routes that use PlatformLayoutComponent) so
       we don't get the PlatformLayout on this page, too.
       2)  If the location/route of this page is changed, you MUST update MKO's /Commerce/AccountCreate.aspx page as well.
    */
		path: 'home/contactusali',
		component: SsoLayoutComponent,
		children: [{ path: '', component: ContactUsAliComponent }],
	},
	{ path: 'error/:operationId', component: SsoLayoutComponent, children: [{ path: '', component: ErrorComponent }] },
	{ path: 'theme', component: KpaThemeSampleComponent, canActivate: [KpaThemeSampleGuard] },
	{
		path: 'kpa-component-library',
		canActivate: [KpaComponentLibraryGuardService],
		loadChildren: () =>
			import('../app/kpa-component-library/kpa-component-library-viewer.module').then((m) => m.KpaComponentLibraryViewerModule),
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			enableTracing: false,
		}),
		CoreModule,
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
