<div class="collection-of-personal-information card">
	<a
		href="/assets/Notice at Collection of Personal Information.pdf"
		class="standAloneBtn add-button k-button k-primary right"
		>Print Document</a
	>
	<h1>Notice at Collection of Personal Information</h1>

	<p>
		KPA, LLC (“KPA” “we,” “us,” or “our”) respects the privacy of the information our customers entrust to us. We do not and will not sell
		your personal information. For more information regarding KPA’s privacy practices and your rights under the California Consumer Privacy
		Act, view our Privacy Policy <a href="/home/PrivacyPolicy">here</a>.
	</p>

	<div class="table">
		<div class="table-body">
			<div class="table-row">
				<div class="table-header">Categories of personal information we collect from you</div>
				<div class="table-header">Business or commercial purpose(s)</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					Identifiers, such as:<br /><br />

					Name, address, email address, IP address, identification numbers (e.g., social security number, driver’s license number, state
					identification number, military identification number or passport number).
				</div>
				<div class="table-cell">
					To help communicate with you regarding our products and services; to help respond to your requests and inquiries; to facilitate
					use and implementation of our products and services; to further develop and improve our products and services; to advertise and
					market to you; to complete predictive analytics and data mining
				</div>
			</div>

			<div class="table-row">
				<div class="table-cell">
					Characteristics of protected classifications under California or federal law, such as:<br /><br />

					Gender, date of birth.
				</div>
				<div class="table-cell">
					To facilitate use and implementation of our products and services; to further develop and improve our products and services; to
					complete predictive analytics and data mining
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					Internet or other electronic network activity information, such as:<br /><br />

					IP address, browsing history, search history
				</div>
				<div class="table-cell">
					To help communicate with you regarding our products and services; to help respond to your requests and inquiries; to facilitate
					use and implementation of our products and services; to further develop and improve our products and services; to advertise and
					market to you; to complete predictive analytics and data mining
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					Professional or employment related information, such as:<br /><br />

					Information about current and previous occupation(s); prior accident history
				</div>
				<div class="table-cell">
					To facilitate use and implementation of our products and services; to further develop and improve our products and services; to
					complete predictive analytics and data mining
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					Education information, such as:<br /><br />

					Information about place and level of schooling.
				</div>
				<div class="table-cell">
					To facilitate use and implementation of our products and services; to further develop and improve our products and services; to
					complete predictive analytics and data mining
				</div>
			</div>
		</div>
	</div>
</div>
