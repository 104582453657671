import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators, ValidationErrors } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { LogService } from '@app/core/services/log.service';
import { DepartmentDto } from '@app/employee/employee/shared/models/departmentDto';
import { DepartmentService } from '@app/employee/employee/shared/services/department.service';
import { BaseComponent } from '@app/shared/base-component';
import { UserInfoService } from '@app/core/services/user-info.service';
import { SuggestionService } from '../shared/services/suggestion.service';
import { Environment } from '@env/environment';
import { EhsRoles, LegacyRoleMapperService } from '@app/core/services/legacy-role-mapper.service';
import { WindowService } from '@app/shared/window.service';
import { ContactsService } from '../shared/services/contacts.service';
import { ContactDto } from '../shared/models/contactsDto';
import { SecurityService } from '@app/core/services/security.service';
import { UserInfoDto } from '@app/core/models/user-info-dto';

@Component({
	selector: 'app-suggestion',
	templateUrl: './suggestion.component.html',
	styleUrls: ['./suggestion.component.css'],
})
export class SuggestionComponent extends BaseComponent implements OnInit {
	suggestionForm: UntypedFormGroup;
	departments: DepartmentDto[];
	selectedDepartment: number;
	departmentPlaceholder: DepartmentDto;
	saveInitiated: boolean = false;
	saveFailed: boolean = false;
	saveSucceeded: boolean = false;
	isAnonymous: boolean = false;
	public mkoUrl: string;
	public viewUrl: string;
	public isClientUser: boolean = false;
	public isClientAdmin: boolean = false;
	public isClientPowerUser: boolean = false;
	contacts: ContactDto;
	userInfo: UserInfoDto | any;

	constructor(
		private titleService: Title,
		protected readonly log: LogService,
		private departmentService: DepartmentService,
		private userInfoService: UserInfoService,
		private suggestionService: SuggestionService,
		private readonly environment: Environment,
		private legacyRoleMapperService: LegacyRoleMapperService,
		private readonly windowService: WindowService,
		private readonly contactService: ContactsService,
		private readonly securityService: SecurityService
	) {
		super(log);
	}

	ngOnInit() {
		this.mkoUrl = this.environment.preAuthLanding['myKpaOnline'];

		const userInfo = this.userInfoService.getUserInfoFromStorage();
		this.isClientUser = userInfo.ehsRole && EhsRoles.ClientUser === this.legacyRoleMapperService.MapToEhsRole(userInfo.ehsRole.roleName);
		this.isClientAdmin = userInfo.ehsRole && EhsRoles.ClientAdmin === this.legacyRoleMapperService.MapToEhsRole(userInfo.ehsRole.roleName);
		this.isClientPowerUser =
			userInfo.ehsRole && EhsRoles.ClientPowerUser === this.legacyRoleMapperService.MapToEhsRole(userInfo.ehsRole.roleName);

		this.titleService.setTitle('Suggestion');
		this.suggestionForm = new UntypedFormGroup({
			firstName: new UntypedFormControl('', Validators.required),
			lastName: new UntypedFormControl('', Validators.required),
			submitAnonymously: new UntypedFormControl(false),
			accountDepartmentFk: new UntypedFormControl('', Validators.required),
			location: new UntypedFormControl(''),
			title: new UntypedFormControl('', Validators.required),
			suggestionText: new UntypedFormControl('', Validators.required),
			statusFk: new UntypedFormControl('151'),
			isActive: new UntypedFormControl(true),
		});
		this.departmentPlaceholder = new DepartmentDto();
		this.departmentPlaceholder.DepartmentName = 'Department';
		this.loadDepartments();
		this.loadContacts();
		this.checkIsAnonymously();
	}

	loadDepartments() {
		// get servicetoId for logged on user and load HR roles and Permissions
		const userInfo = this.userInfoService.getUserInfoFromStorage();
		this.departmentService.getAllMkoDepartments(userInfo.primaryLocationServiceToId).subscribe((resp) => {
			this.departments = resp;
		});
	}

	loadContacts() {
		const userInfo = this.userInfoService.getUserInfoFromStorage();
		this.contactService.getContacts(userInfo.userId).subscribe((contact: ContactDto) => {
			if (contact.accessListId) {
				this.viewUrl = '/Clients/group/SuggestionList.aspx';
			} else {
				this.viewUrl = '/Clients/facility/SuggestionList.aspx';
			}
		});
	}

	/**
	 * Update form group validators and refresh
	 * @param formGroup
	 * @param formValidator
	 */
	private updateValidatorAndValue(formGroup: UntypedFormGroup, formValidator: ValidatorFn) {
		formGroup.setValidators(formValidator);
		formGroup.updateValueAndValidity();
		formGroup.markAsDirty();
	}

	checkIsAnonymously() {
		this.suggestionForm.get('submitAnonymously').valueChanges.subscribe((bool: boolean) => {
			if (bool) {
				this.suggestionForm.get('firstName').clearValidators();
				this.suggestionForm.get('lastName').clearValidators();
			} else {
				this.suggestionForm.get('firstName').setValidators(Validators.required);
				this.suggestionForm.get('lastName').setValidators(Validators.required);
			}
			this.suggestionForm.get('firstName').updateValueAndValidity();
			this.suggestionForm.get('lastName').updateValueAndValidity();
			this.isAnonymous = bool;
		});
	}

	onSubmit() {
		if (this.suggestionForm.valid) {
			this.saveInitiated = true;
			var suggestionDto = this.suggestionForm.value;
			if (suggestionDto.submitAnonymously) {
				suggestionDto.firstName = '';
				suggestionDto.lastName = '';
			}
			const userInfo = this.userInfoService.getUserInfoFromStorage();
			this.suggestionService.postSuggestion(suggestionDto, userInfo.primaryLocationServiceToId).subscribe(
				(result) => {
					this.validationBinding(result);
					this.saveInitiated = false;
					this.suggestionForm.reset({ onlySelf: true });
					this.suggestionForm.get('statusFk').setValue('151');
					this.suggestionForm.get('isActive').setValue('true');

					if (this.isClientAdmin || this.isClientPowerUser || this.isClientUser) {
						this.contactService.getContacts(userInfo.userId).subscribe((contact: ContactDto) => {
							if (contact.accessListId) {
								this.windowService.jumpTo(this.mkoUrl + '/Clients/group/SuggestionList.aspx');
							} else {
								this.windowService.jumpTo(this.mkoUrl + '/Clients/facility/SuggestionList.aspx');
							}
						});
					}
				},
				(error) => {
					this.validationBinding(false);
					this.saveInitiated = false;
				}
			);
		} else {
			Object.keys(this.suggestionForm.controls).forEach((field) => {
				const control = this.suggestionForm.get(field);
				control.markAsTouched({ onlySelf: true });
				control.markAsDirty();
			});
		}
	}

	validationBinding(isSuccess: boolean) {
		this.saveSucceeded = isSuccess;
		this.saveFailed = !isSuccess;
	}
}
