<kendo-multiselect
	style="width: 300px"
	[data]="data"
	[textField]="textField"
	[valueField]="valueField"
	#multiselect
	[valuePrimitive]="true"
	[value]="valueFilters(filter)"
	(close)="closed()"
	[autoClose]="false"
	(filterChange)="filterChange($event)"
	kendoMultiSelectSummaryTag
	[kendoDropDownFilter]="filterSettings"
	(valueChange)="valueChange($event)">
	<ng-template
		kendoMultiSelectItemTemplate
		let-dataItem>
		<input
			type="checkbox"
			kendoCheckBox
			id="chk_kpa_multisearch_{{dataItem.valueField}}"
			[checked]="isItemSelected(dataItem.textField)" />
		<label
			class="k-checkbox-label"
			for="chk_kpa_multisearch_{{dataItem.valueField}}">
			{{ dataItem.textField }}
		</label>
	</ng-template>
	<ng-template
		kendoMultiSelectGroupTagTemplate
		let-dataItems>
		<ng-container *ngIf="dataItems.length === 1; else groupedDisplay"> {{ dataItems[0].textField }} </ng-container>
		<ng-template #groupedDisplay> {{ dataItems.length }} selected </ng-template>
	</ng-template>
</kendo-multiselect>
