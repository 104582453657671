import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';

@Component({
	selector: 'app-kpa-snackbar-message',
	templateUrl: './kpa-snackbar-message.component.html',
	styleUrls: ['./kpa-snackbar-message.component.css'],
})
export class KpaSnackbarMessageComponent implements OnInit {
	public message;
	constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
		this.message = data.message;
	}

	ngOnInit() {}
}
