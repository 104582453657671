import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	UntypedFormBuilder,
	Validators,
	UntypedFormGroup,
	UntypedFormControl,
	ValidatorFn,
	AbstractControl,
	ValidationErrors,
	UntypedFormArray,
} from '@angular/forms';

import * as OA2 from './open-api-v2-description';
import { LogService } from '@app/core/services/log.service';

export enum HttpMethod {
	Post,
	Put,
}

export enum OpenApiVersion {
	v2,
	v3,
	v301,
}

@Injectable({
	providedIn: 'root',
})
export class OpenApiFormBuilder extends UntypedFormBuilder {
	private static readonly documentCache: Map<string, any> = new Map<string, any>();

	constructor(
		private readonly client: HttpClient,
		private readonly log: LogService
	) {
		super();
	}

	/**
	 * Creates a FormGroup to edit the body of a request that will be submitted to the API described by an OpenAPI document.
	 * @param docUrl The URL for the OpenAPI document describing the API.
	 * @param resourceName The name of the resource being edited.
	 * @param httpMethod The HTTP method being used to submit the request to the API.
	 * @param binding The object to which the form is bound.
	 * @param hasNoIdOnPut Used to match resource name to path for PUT endpoints that do not have an Id parameter
	 * @param docVersion The version of the OpenAPI document.
	 */
	async buildFromApiDescription(
		docUrl: string,
		resourceName: string,
		httpMethod: HttpMethod,
		binding?: any,
		hasNoIdOnPut: boolean = false,
		docVersion: OpenApiVersion = OpenApiVersion.v2
	): Promise<UntypedFormGroup> {
		const builder = await this.createOpenApiFormBuilderAsync(docUrl, docVersion);
		return builder.buildForm(resourceName, httpMethod, binding, hasNoIdOnPut);
	}

	/**
	 * A factory to create a form builder based on the version of the OpenAPI document.
	 * @param documentUrl The URL for the OpenAPI document.
	 * @param docVersion The version of the OpenAPI document.
	 */
	private async createOpenApiFormBuilderAsync(documentUrl: string, docVersion: OpenApiVersion): Promise<IOpenApiFormBuilder> {
		switch (docVersion) {
			case OpenApiVersion.v2:
				var cachedDoc = OpenApiFormBuilder.documentCache.get(documentUrl) as OA2.OpenApiV2Document;
				if (cachedDoc) return new OpenApiV2FormBuilder(cachedDoc);

				let builder: IOpenApiFormBuilder;
				try {
					const doc = await this.client.get<OA2.OpenApiV2Document>(documentUrl).toPromise();
					OpenApiFormBuilder.documentCache.set(documentUrl, doc);
					builder = new OpenApiV2FormBuilder(doc);
				} catch (ex) {
					this.log.logException(ex);
					builder = new NullFormBuilder();
				}

				return builder;

			// TODO: elaborate v3 parsing
			default:
				this.log.logWarning('OpenApiFormBuilderService: Unsupported OpenApiVersion encountered. Returning NullFormBuilder.');
				return new NullFormBuilder();
		}
	}
}

/**
 * An interface for building a form from an OpenAPI document description of a request body object graph.
 */
interface IOpenApiFormBuilder {
	/**
	 * Builds a top level form group that can edit the requested resource.
	 * @param resourceName The name of the resource on which the request is performed.
	 * @param httpMethod The HTTP method to be requested for the resource.
	 * @param binding The initial value of the form.
	 * @param hasNoIdOnPut Used to match resource name to path for PUT endpoints that do not have an Id parameter
	 */
	buildForm(resourceName: string, httpMethod: HttpMethod, binding: any, hasNoIdOnPut: boolean): UntypedFormGroup;
}

/**
 * A form builder implementation intended to safely return a null value in the event of a failure state.
 */
class NullFormBuilder extends UntypedFormBuilder implements IOpenApiFormBuilder {
	/**
	 * Builds a null form group.
	 * @param resourceName The name of the resource on which the request is performed.
	 * @param httpMethod The HTTP method to be requested for the resource.
	 * @param binding The initial value of the form.
	 */
	buildForm(_resourceName: string, _httpMethod: HttpMethod, _binding: any): UntypedFormGroup {
		return null;
	}
}

/**
 * A class for building a form from an OpenAPI v2.0.0 document description of a request body object graph.
 */
class OpenApiV2FormBuilder extends UntypedFormBuilder implements IOpenApiFormBuilder {
	/**
	 * Creates an OA2.OpenApiV2FormBuilder.
	 * @param oaDocument The document describing the API.
	 */
	constructor(private readonly oaDocument: OA2.OpenApiV2Document) {
		super();
	}

	/**
	 * Builds a top level form group that can edit the requested resource.
	 * @param resourceName The name of the resource on which the request is performed.
	 * @param httpMethod The HTTP method to be requested for the resource.
	 * @param binding The initial value of the form.
	 * @param hasNoIdOnPut Used to match resource name to path for PUT endpoints that do not have an Id parameter
	 */
	buildForm(resourceName: string, httpMethod: HttpMethod, binding: any, hasNoIdOnPut: boolean = false): UntypedFormGroup {
		var body = this.getBodyParameter(resourceName, httpMethod, hasNoIdOnPut);

		if (!body) return null;

		// If immediate interpolation of parameter specs required, this must be expanded
		// KPA implementation provides parameter schema via schema property

		var schema = this.getSchemaFromDefinitions(body.schema.$ref);

		if (!schema) return null;

		return this.createFormGroup(schema, binding);
	}

	/**
	 * Locates the body parameter description in the document for a given resource and HTTP method.
	 * @param resourceName The name of the resource on which the request is performed.
	 * @param httpMethod The HTTP method to be requested for the resource.
	 * @param hasNoIdOnPut Used to match resource name to path for PUT endpoints that do not have an Id parameter
	 */
	private getBodyParameter(resourceName: string, httpMethod: HttpMethod, hasNoIdOnPut: boolean = false): OA2.OpenApiV2BodyParameter {
		var methods: OA2.OpenApiV2MethodCollection;
		var method: OA2.OpenApiV2Path;
		var body: OA2.OpenApiV2BodyParameter;

		switch (httpMethod) {
			case HttpMethod.Post:
				methods = this.oaDocument.paths[`/${resourceName}`];
				if (methods) method = methods['post'];
				if (method && method.parameters) body = method.parameters.find((param) => param.in === 'body') as OA2.OpenApiV2BodyParameter;
				break;
			case HttpMethod.Put:
				const pathRegex = new RegExp(`^\\/${resourceName}\\/{[^}]+}$`);
				// Regex is always false for PUT endpoints without url parameters ex: /Employee/UserProfileData/
				if (hasNoIdOnPut) {
					methods = this.oaDocument.paths[`/${resourceName}`];
					if (methods) method = methods['put'];
					if (method && method.parameters) body = method.parameters.find((param) => param.in === 'body') as OA2.OpenApiV2BodyParameter;
					break;
				} else {
					for (var pathName in this.oaDocument.paths) {
						if (pathRegex.test(pathName)) {
							method = this.oaDocument.paths[pathName]['put'];
							if (method && method.parameters) body = method.parameters.find((param) => param.in === 'body') as OA2.OpenApiV2BodyParameter;
							break;
						}
					}
				}
				break;
		}

		return body;
	}

	/**
	 * Searches the definitions of the document for a schema with the given name.
	 * @param loc The location of the definition in the document.
	 */
	private getSchemaFromDefinitions(loc: string): OA2.OpenApiV2Schema {
		const schemaNameRegex = /^#\/definitions\/(.+)$/;
		var schemaNameResult = schemaNameRegex.exec(loc);

		if (!schemaNameResult) return null;

		return this.oaDocument.definitions[schemaNameResult[1]];
	}

	/**
	 * Creates a form group to edit the object graph described by a schema.
	 * @param schema The object graph schema to use in building this segment of the form.
	 */
	private createFormGroup(schema: OA2.OpenApiV2Schema, binding: any): UntypedFormGroup {
		var thisGraph = {};

		for (var propertyName in schema.properties) {
			var property = schema.properties[propertyName];

			if (property.$ref) property = this.getSchemaFromDefinitions(property.$ref);

			var bindingValue = (binding || {})[propertyName];
			var isRequired = false;
			if (schema.required) {
				isRequired = schema.required.indexOf(propertyName) !== -1;
			}
			switch (property.type) {
				case 'object':
					thisGraph[propertyName] = this.createFormGroup(property, bindingValue);
					break;
				case 'array':
					var arrayProperty = property as OA2.OpenApiV2ArraySchema;
					thisGraph[propertyName] = this.createFormArray(arrayProperty, isRequired, bindingValue);
					break;
				default:
					thisGraph[propertyName] = this.createFormControl(property, isRequired, bindingValue);
			}
		}

		return this.group(thisGraph);
	}

	/**
	 * * Creates a form group to edit the array values described by a schema.
	 * @param property The array type definition.
	 * @param isRequired True if the array is required; otherwise false.
	 * @param bindingValue The current value to which to bind the form array.
	 */
	private createFormArray(property: OA2.OpenApiV2ArrayTypeDefinition, isRequired: boolean, bindingValue: any[]): UntypedFormArray {
		var validators: ValidatorFn[] = [];

		if (isRequired && !property.minItems) validators.push(CustomValidators.minArrayLength(1));
		if (property.minItems) validators.push(CustomValidators.minArrayLength(property.minItems));
		if (property.maxItems) validators.push(CustomValidators.maxArrayLength(property.maxItems));
		if (property.uniqueItems) validators.push(CustomValidators.uniqueArrayItems);

		var controls: AbstractControl[] = [],
			createChildControl: (val: any) => AbstractControl;

		var refItems = property.items as OA2.OpenApiV2Items;

		if (refItems.$ref) {
			var itemSchema = this.getSchemaFromDefinitions(refItems.$ref);
			createChildControl = (val) => this.createFormGroup(itemSchema, val);
		} else if (property.items.type === 'array') {
			var arrayItems = property.items as OA2.OpenApiV2ArrayItems;
			createChildControl = (val) => this.createFormArray(arrayItems.items, false, val);
		} else {
			createChildControl = (val) => this.createFormControl(property.items, true, val);
		}

		bindingValue = bindingValue || [];

		for (var i = 0; i < bindingValue.length; i++) controls.push(createChildControl(bindingValue[i]));

		return this.array(controls, validators);
	}

	/**
	 * Creates a form control for the property described by the schema.
	 * @param property The property to be edited by the form control.
	 * @param isRequired True of the property requires a value, otherwise false.
	 */
	private createFormControl(property: OA2.OpenApiV2TypeDefinition, isRequired: boolean, bindingValue: any): UntypedFormControl {
		var validators: ValidatorFn[] = [];

		if (isRequired) validators.push(Validators.required);
		if (property.minLength) validators.push(Validators.minLength(property.minLength));
		if (property.maxLength) validators.push(Validators.maxLength(property.maxLength));
		if (property.minimum) validators.push(CustomValidators.min(property.minimum, property.exclusiveMinimum === true));
		if (property.maximum) validators.push(CustomValidators.max(property.maximum, property.exclusiveMaximum === true));
		if (property.pattern) validators.push(Validators.pattern(property.pattern));

		return this.control(bindingValue, validators);
	}
}

/**
 * A set of validators for various form controls.
 */
class CustomValidators {
	private static isEmptyInputValue(value: any): boolean {
		// we don't check for string here so it also works with arrays
		return value == null || value.length === 0;
	}

	/**
	 * Validates a control value does not exceed a minimum, optionally exclusive of that minimum.
	 * @param min The minimum value that cannot be exceeded.
	 * @param exclusiveMin True if the minimum value is excluded from the range, otherwise false.
	 */
	static min(min: number, exclusiveMin: boolean): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (this.isEmptyInputValue(control.value) || this.isEmptyInputValue(min)) return null; // don't validate empty values to allow optional controls

			const value = parseFloat(control.value);

			// Controls with NaN values after parsing should be treated as not having a
			// minimum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-min

			return !isNaN(value) && ((!exclusiveMin && value < min) || (exclusiveMin && value <= min))
				? { min: { min: min, actual: control.value, exclusive: exclusiveMin } }
				: null;
		};
	}

	/**
	 * Validates a control value does not exceed a maximum, optionally exclusive of that maximum.
	 * @param max The maximum value that cannot be exceeded.
	 * @param exclusiveMax True if the maximum value is excluded from the range, otherwise false.
	 */
	static max(max: number, exclusiveMax: boolean): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (this.isEmptyInputValue(control.value) || this.isEmptyInputValue(max)) return null; // don't validate empty values to allow optional controls

			const value = parseFloat(control.value);

			// Controls with NaN values after parsing should be treated as not having a
			// maximum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-max

			return !isNaN(value) && ((!exclusiveMax && value > max) || (exclusiveMax && value >= max))
				? { max: { max: max, actual: control.value, exclusive: exclusiveMax } }
				: null;
		};
	}

	/**
	 * Returns true if an object is an Array, otherwise false.
	 * @param value The value to test.
	 */
	private static isArrayValue(value: any) {
		return value instanceof Array;
	}

	/**
	 * Validates that an array has a minimum number of elements.
	 * @param min The minimum number of elements the array must possess.
	 */
	static minArrayLength(min: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!this.isArrayValue(control.value)) return null;

			return !control.value.length || control.value.length < min ? { minArrayLength: { min: min, actual: control.value.length } } : null;
		};
	}

	/**
	 * Validates that an array has no more than a maximum number of elements.
	 * @param min The maximum number of elements the array may possess.
	 */
	static maxArrayLength(max: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!this.isArrayValue(control.value)) return null;

			return !control.value.length || control.value.length > max ? { maxArrayLength: { max: max, actual: control.value.length } } : null;
		};
	}

	/**
	 * Validates that an array has unique elements.
	 */
	static uniqueArrayItems(control: AbstractControl): ValidationErrors | null {
		if (!this.isArrayValue(control.value)) return null;

		var values = control.value as any[];

		var deepEqual: (x: any, y: any) => boolean = (x, y) => {
			const keys = Object.keys,
				tx = typeof x,
				ty = typeof y;
			return x && y && tx === 'object' && tx === ty
				? keys(x).length === keys(y).length && keys(x).every((key) => deepEqual(x[key], y[key]))
				: x === y;
		};

		var distinctValues = values.filter((val, i, arr) => i === arr.findIndex((other) => deepEqual(val, other)));

		return distinctValues.length !== values.length ? { uniqueArrayItems: { numDuplicates: values.length - distinctValues.length } } : null;
	}
}
