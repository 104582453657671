<div class="error">
	<h2>Oops! Something went wrong.</h2>
	<p>We weren't able to complete the action you attempted. You can use the button below to return to the previous page and try again.</p>

	<button
		kendoButton
		kpaPrimaryButton
		(click)="location.back()">
		Go Back
	</button>

	<p>
		If the issue persists, please call <span class="no-break">(800) 486-0400</span>. Provide your support team member with the following
		information to help us resolve the issue more quickly:
	</p>

	<h3>Operation ID {{ operationId }}</h3>
</div>
