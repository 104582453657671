import { employeeRelationshipDto } from './employeeRelationshipDto';
import { emailDto } from './emailDto';
import { EmployeeAccessListDto } from './employeeAccessListDto';
import { RoleDto } from '@app/core/models/role-dto';
import { EmployeeAddressDto } from './employeeAddressDto';
import { EmployeePositionDto } from './employee-position-dto';
export class EmployeeDto {
	id: string;

	createdDate: Date;

	updatedDate: Date;

	firstName: string;

	middleName: string;

	lastName: string;

	genderId: string;

	ssn: string;

	birthDate: Date;

	startDate: Date;

	seniorityDate: Date;

	jobTitle: string;

	isHrAdmin: boolean;

	//represents if employee is active or not.
	isActive: boolean;

	//represents if employee can be assigned EHS issues.
	isChildVisibleMKO: boolean;

	//represents if employee can receive notifications.
	emailNotification: boolean;

	phoneNumber: string;

	emails: emailDto[];

	relationships: employeeRelationshipDto[];

	jobCategoryId: string;

	userId: string;

	clientEmployeeId: string;

	clientId: string;

	primaryLocationServiceToId: string;

	accessListId: string;

	/** roles */
	roles: RoleDto[];

	ehsRole?: RoleDto;
	hrmRole?: RoleDto;

	addresses: EmployeeAddressDto[];

	/** access list exceptions for employee */
	accessListExceptions: EmployeeAccessListDto;

	ssoUserId: string;

	ssoUsername: string;

	maritalStatusIdHRD: number;
	extendedDisciplinary: string;
	secondaryPerformance: string;
	termination: string;
	isRegistered: boolean;

	preferredMethodOfCommunication: string;

	integrationStatus: string;

	employeePositions: EmployeePositionDto[];

	mkoDepartmentId: number;

	accessListName: string;

	primaryLocationName: string;

	hiringThingPeopleId: Number;

	hiringThingId: Number;

	newHirePackageId: Number;

	/** Default to empty as this value is populated by UI */
	displayName: string = '';
}

export enum MethodOfCommunication {
	PersonalEmail = 2,
	WorkEmail = 1,
}

// as per enum in API's PreferredMethodOfCommunication
export enum PreferredMethodOfCommunication {
	None = 0,
	CellPhone = 1,
	PersonalEmail = 2,
	WorkEmail = 3,
}
