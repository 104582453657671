import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Environment } from '@env/environment';
import { RequestCacheService } from './request-cache.service';
import { LogService } from './log.service';

/**
 * Intercepts HttpRequests and injects NoCache request header when applicable.
 */
@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {
	constructor(
		private readonly environment: Environment,
		private readonly requestCache: RequestCacheService,
		private readonly log: LogService
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// only handle get
		if (!this.isCachable(req)) {
			this.log.logVerbose('no-cache, check cachable: not cachable');
			return next.handle(req);
		}

		// look in qs for cacheForMils
		const qs: string = req.url.split('?')[1];
		let cacheForMilsFromQs: string;
		// if have qs, then we can look for cacheForMils
		if (qs) {
			const qaParams = this.parseQueryString(qs);
			cacheForMilsFromQs = qaParams['cacheForMils'];
			this.log.logVerbose('no-cache, cacheForMils ' + cacheForMilsFromQs);
		}

		// no cache path
		if (!cacheForMilsFromQs) {
			this.log.logVerbose('no-cache, Request: setting no-cache headers, no-cache path');
			const newHeaders = this.makeCacheHeaders(true, 0);
			const noCacheReq = req.clone({
				setHeaders: newHeaders,
			});
			return next.handle(noCacheReq);
		}

		// check if have cache
		const cachedResponse = this.requestCache.get(req);
		if (cachedResponse) {
			this.log.logVerbose('no-cache, found in cache: ' + req.urlWithParams);
			return of(cachedResponse);
		}

		let cacheForMills = cacheForMilsFromQs ? parseInt(cacheForMilsFromQs, 10) : 0;

		return this.handleRequest(req, cacheForMills, next);
	}

	/**
	 * Method to add cache headers
	 **/
	private makeCacheHeaders(insertNoCache: boolean, expireMilliseconds: number) {
		// Prevent caching in IE, in particular IE11.
		// See: https://support.microsoft.com/en-us/help/234067/how-to-prevent-caching-in-internet-explorer

		// do no caching
		if (insertNoCache && expireMilliseconds === 0) {
			this.log.logVerbose('no-cache, determined to set no-cache');
			return {
				'Cache-Control': 'no-cache, no-store',
				Pragma: 'no-cache',
			};
		}

		// do caching for milliseconds
		if (insertNoCache && expireMilliseconds > 0) {
			const expire = new Date(Date.now() + expireMilliseconds);
			const maxAgeSec = Math.floor(expireMilliseconds / 1000);
			let maxAge = '';
			if (maxAgeSec > 0) {
				maxAge = `, max-age=${maxAgeSec}`;
			}
			// IE 11 with windows 10 CE has issue with private and caching
			// Chrome with what Google says is bad cert, doesn't cache
			// Chrome puts in pragma when cert isn't valid, like self signed certs.
			return {
				'Cache-Control': 'private' + maxAge,
				Expires: expire.toUTCString(),
			};
		}
		return undefined;
	}

	/**
	 *  parses simple query strings
	 */
	public parseQueryString(queryString?: string): any {
		// if the query string is NULL or undefined
		if (!queryString) {
			queryString = window.location.search.substring(1);
		}

		const params = {};

		const queries = queryString.split('&');

		queries.forEach((indexQuery: string) => {
			const indexPair = indexQuery.split('=');

			const queryKey = decodeURIComponent(indexPair[0]);
			const queryValue = decodeURIComponent(indexPair.length > 1 ? indexPair[1] : '');

			params[queryKey] = queryValue;
		});

		return params;
	}

	/** Is this request cachable? */
	private isCachable(req: HttpRequest<any>): boolean {
		// Only GET requests are cachable
		return req.method === 'GET';
		// put in other logic here, like list in environment for known paths not to cache.
	}

	/**
	 * @description Set up headers to not cache, then put response in cache for cacheForMills
	 * @param request
	 * @param cacheForMills
	 * @param next
	 */
	private handleRequest(request: HttpRequest<any>, cacheForMills: number, next: HttpHandler) {
		// only get here if have cacheForMills
		// set headers so don't cache
		const newHeaders = this.makeCacheHeaders(true, 0);
		this.log.logVerbose('no-cache, Request: setting no-cache headers cache path');
		const noCacheReq = request.clone({
			setHeaders: newHeaders,
		});
		return next.handle(noCacheReq).pipe(
			tap((event) => {
				if (event instanceof HttpResponse) {
					if (event.ok) {
						this.log.logVerbose('no-cache, Add to: ' + noCacheReq.urlWithParams);
						this.requestCache.put(noCacheReq, event, cacheForMills);
					}
				}
			})
		);
	}
}
