import { Injectable, isDevMode } from '@angular/core';
import { ApplicationInsights, ITraceTelemetry, IExceptionTelemetry, ICustomProperties } from '@microsoft/applicationinsights-web';

import { Environment } from '@env/environment';

export enum SeverityLevel {
	Verbose = 0,
	Information = 1,
	Warning = 2,
	Error = 3,
	Critical = 4,
}

@Injectable({
	providedIn: 'root',
})
export class LogService {
	/**
	 * Static AppInsights for rest of application to use
	 */
	public static AppInsights: ApplicationInsights;

	constructor(private readonly environment: Environment) {
		// starts up AppInsights one time
		if (!LogService.AppInsights && environment.appInsightsConnectionString) {
			LogService.AppInsights = new ApplicationInsights({
				config: {
					connectionString: environment.appInsightsConnectionString,
					enableAutoRouteTracking: false, // option to log all route changes
				},
			});
			LogService.AppInsights.loadAppInsights();
		}
	}

	log(message: string, severityLevel: SeverityLevel, properties?: { [name: string]: string }, optionalParams?: any[]): void {
		if (severityLevel < this.environment.logLevel) {
			return;
		}

		if (isDevMode()) {
			let logEntry = `[LogService @ ${new Date().toString()}]\n${SeverityLevel[severityLevel]}: ${message}`;

			if (optionalParams) {
				logEntry = `${logEntry}\n${optionalParams.join(', ')}`;
			}

			console.log(logEntry);

			if (properties) {
				console.log(properties);
			}
		}
		const traceTelemetry: ITraceTelemetry = {
			message: message,
			properties: properties,
			severityLevel: severityLevel as number,
		};
		if (LogService.AppInsights) {
			LogService.AppInsights.trackTrace(traceTelemetry);
		}
	}
	/**
	 * @description Primarily used for application development for coarse level logging
	 * @param message
	 * @param properties
	 * @param optionalParams
	 */
	logVerbose(message: string, properties?: { [name: string]: string }, optionalParams?: any[]): void {
		this.log(message, SeverityLevel.Verbose, properties, optionalParams);
	}

	logInformation(message: string, properties?: { [name: string]: string }, optionalParams?: any[]): void {
		this.log(message, SeverityLevel.Information, properties, optionalParams);
	}

	logWarning(message: string, properties?: { [name: string]: string }, optionalParams?: any[]): void {
		this.log(message, SeverityLevel.Warning, properties, optionalParams);
	}

	logError(message: string, properties?: { [name: string]: string }, optionalParams?: any[]): void {
		this.log(message, SeverityLevel.Error, properties, optionalParams);
	}

	logCritical(message: string, properties?: { [name: string]: string }): void {
		this.log(message, SeverityLevel.Critical, properties);
	}

	/**
	 * Logs Exception to AppInsights
	 * @param exception Error to log
	 * @param properties Any properties to pass along
	 * @param measurements screen values or timing to pass along
	 */
	logException(exception: Error, properties?: { [name: string]: string }, measurements?: { [name: string]: number }) {
		if (isDevMode()) {
			const logEntry = `[LogService @ ${new Date().toString()}]\n${SeverityLevel.Critical}: ${exception}`;

			console.log(logEntry);

			if (properties) {
				console.log(properties);
			}
			if (measurements) {
				console.log(measurements);
			}
		}
		if (LogService.AppInsights) {
			const customProperties: ICustomProperties = { ...properties, ...measurements };

			const exceptionTelemetry: IExceptionTelemetry = {
				exception: exception,
				severityLevel: SeverityLevel.Error as number,
			};
			LogService.AppInsights.trackException(exceptionTelemetry, customProperties);
		}
	}

	info(message?: any, ...optionalParams: any[]) {
		this.logInformation(message, null, optionalParams);
	}

	warn(message?: any, ...optionalParams: any[]) {
		this.logWarning(message, null, optionalParams);
	}

	error(message?: any, ...optionalParams: any[]) {
		this.logError(message, null, optionalParams);
	}

	debug(message?: any, ...optionalParams: any[]) {
		this.logVerbose(message, null, optionalParams);
	}
}
