import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConsultantDto } from '../models/ConsultantDto';
import { BaseApiClient } from '@app/core/services/base-api-client.service';
import { LogService } from '@app/core/services/log.service';
import { Environment } from '@env/environment';
import { catchError, tap, map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export interface IConsultantsService {
	url: string;
	serviceToUrl: string;
	getConsultants(serviceToId: string): Observable<ConsultantDto[]>;
	get(consultantId: number): Observable<ConsultantDto>;
	getPhoto(consultantId: number): Observable<any>;
}

@Injectable({
	providedIn: 'root',
})
export class ConsultantsService extends BaseApiClient implements IConsultantsService {
	url: string;
	serviceToUrl: string;
	public baseUrl: string;

	constructor(
		private readonly http: HttpClient,
		private readonly log: LogService,
		environment: Environment,
		private domSanitizer: DomSanitizer
	) {
		super();
		this.baseUrl = environment.services['employees'];
		this.url = this.baseUrl + 'Consultants';
		this.serviceToUrl = this.baseUrl + 'ServiceTos';
	}

	/**
	 * Retrieves list of Consultants for given ServiceTo
	 * @param serviceToId
	 */
	getConsultants(serviceToId: string): Observable<ConsultantDto[]> {
		const url = `${this.serviceToUrl}/${serviceToId}/consultants`;
		return this.http.get<ConsultantDto[]>(url).pipe(
			tap((): void => this.log.logInformation(`Fetch consultants for ServiceTo with id=${serviceToId}`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	/**
	 * Retrieves a Consultant
	 * @param consultantId
	 */
	get(consultantId: number): Observable<ConsultantDto> {
		const url = `${this.url}/${consultantId}`;
		return this.http.get<ConsultantDto>(url).pipe(
			tap((): void => this.log.logInformation(`Fetch consultant with id=${consultantId}`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	/**
	 * Retrieves a consultant's picture
	 * @param consultantId
	 */
	getPhoto(consultantId: number): Observable<any> {
		const url = `${this.url}/${consultantId}/picture`;
		return this.http
			.get(url, { responseType: 'blob' })
			.pipe(
				map((e) => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e)), catchError(this.createErrorResponseHandler(this.log)))
			);
	}
}
