<div
	fxLayout="column"
	class="card kpa-success-card">
	<div
		fxLayout="row"
		fxLayoutAlign="space-between start"
		*ngIf="message"
		id="mainTitleContainer">
		<h1><i class="fal fa-check-circle"></i> {{ message }}</h1>
	</div>
</div>
