import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'phone',
})
export class PhonePipe implements PipeTransform {
	/**
	 * Given a string with numbers it will try to format to a phone number.
	 * @param value
	 * @param args
	 */
	transform(value: any, args?: any): any {
		if (!value) {
			return '';
		}
		value = value.toString().trim().replace(/\D+/g, '');
		let country, city, number;
		switch (value.length) {
			case 10: // +1PPP####### -> C (PPP) ###-####
				country = 1;
				city = value.slice(0, 3);
				number = value.slice(3);
				break;
			case 11: // +CPPP####### -> C (PPP) ###-####
				country = value[0];
				city = value.slice(1, 4);
				number = value.slice(4);
				break;
			case 12: // +CCCPP####### -> CCC (PP) ###-####
				country = value.slice(0, 3);
				city = value.slice(3, 5);
				number = value.slice(5);
				break;
			default:
				return value;
		}
		if (country == 1) {
			country = '';
		}
		number = number.slice(0, 3) + '-' + number.slice(3);
		return (country + ' (' + city + ') ' + number).trim();
	}
}
