import { Directive, KeyValueDiffers } from '@angular/core';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { KpaSelectionDirective } from './kpa-selection.directive';

@Directive({
	selector: '[kpaComboBox]',
	exportAs: 'kpaComboBox',
})
export class KpaComboBoxDirective extends KpaSelectionDirective {
	constructor(dropdown: ComboBoxComponent, diff: KeyValueDiffers) {
		super(dropdown, diff);
	}
}
