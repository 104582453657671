import { RoleDto } from './role-dto';
import { PermissionDto } from './permission-dto';
import { EhsRoleDto } from '@app/core/models/ehsRoleDto';
import { HrRoleDto } from '@app/core/models/hrRoleDto';

export class UserInfoDto {
	/**The userId of the user */
	userId: string;

	/**The employeeId of the user */
	employeeId: string;

	/**The display name of the user */
	employeeDisplayName: string;

	/**The clientId of the user */
	clientId: string;

	/**The primaryLocationServiceToId of the user */
	primaryLocationServiceToId: string;

	roles: RoleDto[];

	permissions: PermissionDto[];

	primaryEmail: string;

	primaryPhone: string;

	accessListId: string;

	ehsRole: EhsRoleDto;

	hrRole: HrRoleDto;

	primaryServiceToKpaId: string;

	clientKpaId: string;

	clientName: string;

	locationName: string;

	userName: string;
}
