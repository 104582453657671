import { Component, Predicate } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { StringConstants } from '@app/shared/string-constants';
import { HttpErrorResponse } from '@angular/common/http';
import { LogService } from '@app/core/services/log.service';

@Component({
	template: 'NO UI',
})
export class BaseComponent {
	constructor(protected log: LogService) {}

	formControls: AbstractControl[] = [];
	screenStatus: string;

	dirtyPredicate: Predicate<AbstractControl>;
	hasErrorsPredicate: Predicate<AbstractControl>;

	get dirty(): boolean {
		return this.formControls.findIndex((ctrl) => this.dirtyPredicate(ctrl)) !== -1;
	}

	get hasErrors(): boolean {
		return this.formControls.findIndex((ctrl) => this.hasErrorsPredicate(ctrl)) !== -1;
	}

	get isSaveDisabled(): boolean {
		return this.isSaving || this.hasErrors || !this.dirty;
	}

	get isSaveDisabledIgnoreErrors(): boolean {
		return this.isSaving || !this.dirty;
	}

	get isSaving(): boolean {
		return this.screenStatus === StringConstants.savingScreenStatus;
	}

	logResult(result: any, message?: string) {
		this.log.logInformation(message != null ? message : 'save result: ' + result);
	}

	//**Predicate that handles 400 errors and throws all others */
	errorResponsePredicate = (err) => {
		// Service populates errors into form controls during 400 responses and should be considered handled
		if (err instanceof HttpErrorResponse && err.status === 400) {
			this.screenStatus = StringConstants.invalidScreenStatus;
			this.processAdditionalErrorHandling();
		}
		// Rethrow and let global handler catch
		else {
			throw err;
		}
	};

	//method that can be overridden in classes that use base component
	processAdditionalErrorHandling() {}
}
